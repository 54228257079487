import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

export interface AssistantDataItem {
  assistantNo: string;
  assistantid: string; // openai 中的助手編號
  vector_store_id: string; // openai 中的 vector_store_id
  title: string;
  content: string;
  imageurl: string;
  memo: string;
  Initialized: InitializedItem; // 對應 Initialized 的結構
}

export interface InitializedItem {
  init_image: string; // 初始化的圖片路徑
  init_word: string; // 初始化時顯示的文字內容
  init_meun: InitMenuItem[]; // 對應初始化選單
  chatavator: string; // 對應聊天室的圖片
}

export interface InitMenuItem {
  title: string; // 選單的標題
  content:string; // 選單的內容
  id: string; // 選單的 ID
  disable: boolean; // 是否禁用;
  functionname: string; // 選單的功能名稱
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataUrl = 'assets/sampledata/sample-data.json'; // JSON 檔案的路徑

  constructor(private http: HttpClient) {}

  // 讀取 JSON 檔案並轉換為 AssistantDataItem 陣列
  getLobbyDataItems(companyname: string): Observable<AssistantDataItem[]> {
    return this.http.get<any>(this.dataUrl).pipe(
      // tap(response => console.log("response", response.SampleData[companyname])), // 在這裡顯示完整的 response
      map((response) =>{
        const lobby = response.SampleData[companyname].lobby;
        return lobby? lobby as AssistantDataItem[] : [];
      })
    );
  }

  getLobbyDataItemByNo(companyname: string,assistantNo:string): Observable<AssistantDataItem | null> {
    return this.http.get<any>(this.dataUrl).pipe(
      // tap(response => console.log("response", response.SampleData[companyname])), // 在這裡顯示完整的 response
      map((response) =>{
        const lobby = response.SampleData[companyname].lobby;
        const assistant = lobby.find((item: any) => item.assistantNo === assistantNo);
        return assistant ? assistant as AssistantDataItem : null;
      })
    );
  }

  // 讀取 JSON 檔案並轉換為 DataItem 陣列
  // getrecommentbuttonlists(companyname: string, assistantNo: string): Observable<RecommentItem[]> {
  //   return this.http.get<any>(this.dataUrl).pipe(
  //     // tap(response => console.log("response", response.SampleData[companyname].lobby)), // 在這裡顯示完整的 response
  //     map((response) => {
  //       const lobby = response.SampleData[companyname].lobby;
  //       const assistant = lobby.find((item: any) => item.assistantNo === assistantNo);
  //       return assistant ? assistant.recommentbuttonlist as RecommentItem[] : [];
  //     })
  //   );
  // }

}
