import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { OpanaiService } from '../../services/openai.service';

interface UploadFile {
  file: File;
  progress: number;
  fileId?: string; // 儲存上傳後的 file_id
}

@Component({
  selector: 'app-fileuploader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.scss']
})

export class FileuploaderComponent implements OnInit {

  @Input() vectorStoreId!: string; // 接收的參數
  @Input() existFileList: string[] = []; // 接收已存在的檔案清單

  files: UploadFile[] = []; // 修正型別
  isUploading: boolean = false;
  isUploadFinished: boolean = false;

  uploadResults: { fileName: string; status: string; message?: string }[] = [];

  constructor(
    private http: HttpClient,
    private openAiApiService: OpanaiService
  ) {}

  ngOnInit(): void {
    if (!this.vectorStoreId) {
      throw new Error('vectorStoreId is required');
    }
  }

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFiles(files);
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    this.addFiles(input.files);
  }

  addFiles(fileList: FileList | null) {
    if (!fileList) return;

    const existingFiles: UploadFile[] = []; // 暫存已存在的檔案
    const newFiles: UploadFile[] = []; // 暫存新的檔案

    // 遍歷檔案清單，分類為已存在或新檔案
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const isFileExists = this.existFileList.includes(file.name);

      if (isFileExists) {
        // 如果檔案已存在，加入已存在的清單
        existingFiles.push({ file, progress: 0 });
      } else {
        // 如果檔案是新檔案，加入新的清單
        newFiles.push({ file, progress: 0 });
      }
    }

    // 如果有已存在的檔案，詢問使用者是否覆蓋
    if (existingFiles.length > 0) {
      const userChoice = confirm(
        `共有 ${existingFiles.length} 個檔案已存在，是否要覆蓋？\n\n` +
        existingFiles.map((f) => f.file.name).join('\n')
      );

      if (userChoice) {
        // 使用者選擇覆蓋，將已存在的檔案加入上傳清單
        this.files.push(...existingFiles);
      } else {
        // 使用者選擇略過，將略過的檔案記錄到結果中
        existingFiles.forEach((f) => {
          this.uploadResults.push({
            fileName: f.file.name,
            status: 'skipped',
            message: '使用者選擇略過',
          });
        });
      }
    }

    // 新檔案直接加入上傳清單
    this.files.push(...newFiles);

    // Debug 訊息：檢查上傳清單
    console.log("待上傳檔案:", this.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.refreshFolderFileCounts();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  uploadFiles() {
    this.isUploading = true; // 開始上傳時隱藏刪除按鈕和下方按鈕

    // 清空之前的上傳結果
    this.uploadResults = [];

    // 上傳每個檔案
    const uploadPromises = this.files.map((uploadFile, index) => {
      return this.uploadFileToOpenAi(uploadFile, index);
    });

    // 當所有上傳完成時，顯示結果視窗
    Promise.all(uploadPromises)
      .then(() => {
        this.isUploadFinished = true;
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        this.isUploadFinished = true;
      });
  }

  //將檔案傳到OpenAI、Azure Blog上
  async uploadFileToOpenAi(uploadFile: UploadFile, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.openAiApiService.uploadFiles([uploadFile.file], this.vectorStoreId).subscribe(
        async (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              uploadFile.progress = Math.round((100 * event.loaded) / event.total);
            }
          } else if (event.type === HttpEventType.Response) {
            this.uploadResults.push({
              fileName: uploadFile.file.name,
              status: 'success',
            });
            await this.refreshFolderFileCounts();
            resolve();
          }
        },
        (error) => {
          this.uploadResults.push({
            fileName: uploadFile.file.name,
            status: 'fail',
            message: error.message,
          });
          reject(error);
        }
      );
    });
  }

  async refreshFolderFileCounts() {
    // Implement the logic to refresh the file counts for each folder
    // This might involve calling a service or updating a shared state
    // For example, you can call a method from a shared service to update the counts
    // await this.sharedService.updateFolderFileCounts();
  }

  clearFiles() {
    this.files = [];
  }
}
