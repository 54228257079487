import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatSDSJson',
  standalone: true  // 標明這是一個 standalone 管道
})
export class FormatJsonPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, ...args: unknown[]): SafeHtml {
    if (!value) {
      return '';
    }

    // 將 JSON 格式化為字符串，加入縮排
    let jsonString = JSON.stringify(value, null, 2);

    // 使用正則表達式將 Key 值匹配並加上點擊事件
    jsonString = jsonString.replace(
      /"([^"]+)"\s*:/g,
      `<span style="cursor: pointer; background-color:#00640061" class="json-key" data-key="$1">$1</span>:`
    );

    // 返回安全的 HTML
    return this.sanitizer.bypassSecurityTrustHtml(jsonString);
  }
}
