import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export interface Category {
  name: string;       // 顯示用
  selected: boolean;  // 是否被勾選
  value?: string;     // 可能還需要保留原始 value
}

@Component({
  selector: 'app-form-multi-checkbox',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="form-section">
      <label>{{ label }}</label>
      <div class="checkbox-container">
        <div *ngFor="let category of categories; let i = index" class="checkbox-item">
          <input
            type="checkbox"
            [checked]="category.selected"
            (change)="toggleCategory(category, getChecked($event))"
            [id]="'checkbox-' + category.name"
            [attr.name]="name ? name + '-' + i : 'work-category-' + i" />
          <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxComponent),
      multi: true,
    },
  ],
  styleUrls: ['total-component.scss']
})
export class MultiCheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '缺失類別';
  @Input() name: string = ''; // 保留 name 屬性

  /**
   * 新增 @Input() options，用於接收 JSON config 裡的選項。
   * 假設每個選項為 { label: string; value: string }
   */
  @Input() set options(value: { label: string; value: string }[] | undefined) {
    if (!value) return;

    // 若外部尚未透過 writeValue 設定 categories，我們就初始化它
    if (!this._externalValueSet) {
      this.categories = value.map(opt => ({
        name: opt.label,
        value: opt.value,
        selected: false
      }));
    }
    this._rawOptions = value;
  }

  // 內部儲存原始 options 以防日後需要
  private _rawOptions: { label: string; value: string }[] = [];
  private _externalValueSet = false;

  categories: Category[] = [];

  onChange: (value: Category[]) => void = () => {};
  onTouched: () => void = () => {};

  // 從事件中取得 checked 屬性
  getChecked(event: Event): boolean {
    const target = event.target as HTMLInputElement;
    return target.checked;
  }

  toggleCategory(category: Category, checked: boolean): void {
    category.selected = checked;
    this.onChange(this.categories);
    this.onTouched();
  }

  // Reactive Form 寫值時會呼叫這裡
  writeValue(value: Category[]): void {
    if (value) {
      // 代表外部有傳入既有的勾選資料
      this._externalValueSet = true;
      this.categories = value;
    } else {
      // 沒有值時，若之前有 _rawOptions 就初始化
      if (this._rawOptions?.length) {
        this.categories = this._rawOptions.map(opt => ({
          name: opt.label,
          value: opt.value,
          selected: false
        }));
      } else {
        this.categories = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // 若需要禁用，可在此實作 disable 邏輯
  }
}
