import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SdsParseResult,ParseResult,PageMode } from '../sds-parser-dialog/models/sds_parser_model' ;
import { filter, finalize, mergeMap, tap } from 'rxjs/operators';
import { defer, iif, of,Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment'; // Import environment
import { getDocument, PDFDocumentProxy, GlobalWorkerOptions } from 'pdfjs-dist';


@Component({
  selector: 'app-sds-parser-dialog',
  templateUrl: './sds-parser-dialog.component.html',
  styleUrls: ['./sds-parser-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SdsParserDialogComponent implements OnInit {

	// 控制 Dialog 顯示狀態（原 parserdialogvisible）
	@Input() visible: boolean = false;
  @Input() filename: string = '';
  @Input() fileurl: string = '';
  @Input() sdsid: string = '';
  @Input() selectedfile: File | null = null;

  // 供父層監聽儲存與取消事件
  @Output() save: EventEmitter<ParseResult> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  // ----------------------------
  // 以下為從原本程式碼搬移的內部屬性與變數
  pageMode = PageMode;

  // 用來儲存 SDS 智能解析結果（原 paresresult）
  paresresult = new ParseResult();

  // SDS 解析進度、內容累積
  parsercontent: any[] = [];
  Indentationcount = 0;

  sdsfilelink: SafeUrl | null = null;
  existsdsfilelink: string = '';
  sdsfilename: string = ''; // 實際顯示的檔案名稱
  parsercontenttemp: string = ''; // 全部解析過程串流文字

  // 依16大項分區塊顯示（預設 0~16）
  parsercontenttemp_0 = '';
  parsercontenttemp_1 = '';
  parsercontenttemp_2 = '';
  parsercontenttemp_3 = '';
  parsercontenttemp_4 = '';
  parsercontenttemp_5 = '';
  parsercontenttemp_6 = '';
  parsercontenttemp_7 = '';
  parsercontenttemp_8 = '';
  parsercontenttemp_9 = '';
  parsercontenttemp_10 = '';
  parsercontenttemp_11 = '';
  parsercontenttemp_12 = '';
  parsercontenttemp_13 = '';
  parsercontenttemp_14 = '';
  parsercontenttemp_15 = '';
  parsercontenttemp_16 = '';

  // 解析結果旗標與執行時間
  Resultbolean: string = ""; // "Y"：成功, "N"：失敗
  Calculatetime: string = "";

  // 用來存放 API 串流回傳的完整 JSON 區塊
  parsencontentjson: string = "";
  // 資料區段狀態：0 = 載入資料中, 1 = complete, 2 = end
  datastage: number = 0;
  loading:boolean = false;
  // 是否可將資料匯入
  canimportdata: boolean = false;

  // 進度狀態陣列，共 17 項
  statusArray: { ID: number; Finished: boolean }[] = [];

  downloadfileobject: any;


  // 用來合併資料的 model
  datamodel: any = {};
  showparseallbutton: boolean = false;

  // 正則表達式，用以解析回傳的資料
  regex: RegExp = /\[S(\d+)\]\s*([\s\S]*?)(?=\[|$)/g;

  // ----------------------------
  // Constructor 及 DI 注入（參考原本程式碼）
  constructor(
    // public sdsFormService: SdsFormService,
    // private content: WSContextService,
    // private notificationService: NotificationService,
    // private loaderService: LoaderService,
    // private confirmService: ConfirmService,
    // private translateService: TranslateService,
    // private utilityService: UtilityService,
    // private dialogService: DialogService,
    // private sanitizer: DomSanitizer
  ) {
    // 設定 PDF.js 的全局 WorkerSrc
    GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.mjs';
  }

  ngOnInit(): void {
		this.init_statusArray();
		// 將父層傳入參數指定到內部顯示用變數
		this.sdsfilename = this.filename;
		this.sdsfilelink = this.fileurl;

		console.log("this.sdsfilename", this.sdsfilename);
		console.log("this.sdsfilelink", this.sdsfilelink);

		// // 檢查 fileurl 是否存在且不是 blob URL (表示來自伺服器)
		// if (this.fileurl && !this.fileurl.toString().startsWith('blob:')) {
		// 	this.loaderService.show();
		// 	this.content.chemicalSdsRepository.getSdsFile(this.fileurl)
		// 		.pipe(
		// 			tap(blob => {
		// 				// 將取得的 blob 轉換為 File
		// 				const file = this.utilityService.dataUrlToFile(blob, this.filename);
		// 				this.selectedfile = file;
		// 				// 啟動解析流程
		// 				this.handleSdsAnalysisAndAutoFillFields(file, this.sdsfilename, this.fileurl);
		// 			}),
		// 			finalize(() => {
		// 				this.loaderService.hide();
		// 			})
		// 		)
		// 		.subscribe({
		// 			error: (error: any)  => {
		// 				console.error('取得 SDS 檔案失敗：', error);
		// 				this.notificationService.error(error.Message);
		// 			}
		// 		});
		// } else if (this.fileurl && this.fileurl.toString().startsWith('blob:')) {
		// 	// 如果 fileurl 是 blob URL，代表檔案是新增的，
		// 	// 父層應該已經取得該 File 物件，直接使用它進行解析即可。
		// 	// 你可以將 selectedfile 從父層的檔案選取邏輯中存下來，
		// 	// 然後直接呼叫 handleSdsAnalysisAndAutoFillFields。
		// 	// 此處示範假設 this.selectedfile 已經存在：
		// 	if (this.selectedfile) {
		// 		this.handleSdsAnalysisAndAutoFillFields(this.selectedfile, this.sdsfilename, this.fileurl);
		// 	} else {
		// 		console.warn("新增檔案但 selectedfile 尚未設置");
		// 	}
		// }
	}


  // 初始化 statusArray，建立 17 個進度項目
  init_statusArray() {

    const ids = [1, 2, 3, 9];
    this.statusArray = ids.map(id => ({
      ID: id,
      Finished: false,
    }));

    // this.statusArray = Array.from({ length: 17 }, (_, i) => ({
    //   ID: i,
    //   Finished: false,
    // }));
  }

  // 使用者按下「儲存」後，關閉 dialog 並執行自動帶入欄位動作
  importparserdata() {
    this.visible = false;
    this.cleardata();
    // this.autoFillFields(this.paresresult);
    this.save.emit(this.paresresult);
  }

  // // 關閉 dialog（取消）
  // closeDialog() {
  //   this.visible = false;
  //   this.cleardata();
  //   this.cancel.emit();
  // }

  // handleDialogClose() {
  //   this.visible = false;
  //   this.cleardata();
  //   this.cancel.emit();
  // }

  // 自動填入欄位，依據解析結果帶入表單（原 autoFillFields）
  // autoFillFields(res: ParseResult) {
  //   const formValue = this.sdsFormService.form.getRawValue();
  //   const parseResult: SdsParseResult = res?.result;
  //   let unNullModel = {} as SdsParseResult;
  //   this.confirmService
  //     .open(
  //       this.translateService.instant('CHEM.SDS.PARSE'),
  //       this.translateService.instant('CHEM.SDS.PARSESUCCESS')
  //     )
  //     .pipe(filter(confirmResult => confirmResult.isConfirmed))
  //     .subscribe(() => {
  //       unNullModel = Object.entries(parseResult).reduce((acc, [key, value]) => {
  //         if (value !== null && value !== undefined) {
  //           acc[key] = value;
  //         }
  //         return acc;
  //       }, {});
  //       if (Array.isArray(unNullModel.ChemicalMaterial) && unNullModel.ChemicalMaterial.length > 0) {
  //         unNullModel.ChemicalMaterial.forEach(item => {
  //           item.Percentage = this.fixpercentage(item.Percentage);
  //           item.HazardPercentage = this.fixpercentage(item.HazardPercentage);
  //         });
  //       } else {
  //         console.log("ChemicalMaterial 為空陣列或不存在。");
  //       }
  //       if (Array.isArray(unNullModel?.ChemicalMaterial)) {
  //         unNullModel['MaterialComposition'] = unNullModel?.ChemicalMaterial.length > 1 ? 'Mixture' : 'Substance';
  //       }
  //       if (unNullModel['PhysicalState'] != null) {
  //         unNullModel['PhysicalState'] = this.getPhysicalState(unNullModel['PhysicalState']);
  //       }
  //       if (unNullModel['MeltingPoint'] != null) {
  //         unNullModel['MeltingPoint'] = this.getnumberpart(unNullModel['MeltingPoint'], 'Min');
  //       }
  //       if (unNullModel['BoilingPoint'] != null) {
  //         const bmax = this.getnumberpart(unNullModel['BoilingPoint'], 'Max');
  //         const bmin = this.getnumberpart(unNullModel['BoilingPoint'], 'Min');
  //         if (bmax == bmin) {
  //           unNullModel['BoilingPointType'] = 'single';
  //           unNullModel['BoilingPoint'] = bmin;
  //         } else {
  //           if (unNullModel['BoilingPoint'].includes('C') && unNullModel['BoilingPoint'].includes('F')) {
  //             unNullModel['BoilingPointType'] = 'single';
  //             unNullModel['BoilingPoint'] = bmin;
  //           } else {
  //             unNullModel['BoilingPointType'] = 'range';
  //             unNullModel['BoilingPoint'] = bmin;
  //             unNullModel['BoilingPointUpper'] = bmax;
  //           }
  //         }
  //       }
  //       if (unNullModel['DecompositionTemp'] != null) {
  //         unNullModel['DecompositionTemp'] = this.getnumberpart(unNullModel['DecompositionTemp'], 'Min');
  //       }
  //       if (unNullModel['AutoIgnitionTemp'] != null) {
  //         unNullModel['AutoIgnitionTemp'] = this.getnumberpart(unNullModel['AutoIgnitionTemp'], 'Min');
  //       }
  //       if (unNullModel['VaporPressure'] != null) {
  //         unNullModel['VaporPressure'] = this.getnumberpart(unNullModel['VaporPressure'], 'Min');
  //       }
  //       if (unNullModel['Density'] != null) {
  //         unNullModel['Density'] = this.getnumberpart(unNullModel['Density'], 'Min');
  //       }
  //       if (this.sdsFormService.isSixteenItem) {
  //         unNullModel.ChemicalMasterDetail = this.model16Parse(unNullModel);
  //       }
  //       let updateModel = {
  //         Model: unNullModel,
  //         Materials: unNullModel.ChemicalMaterial
  //       };
  //       if (this.sdsFormService.isSixteenItem) {
  //         updateModel.Model = { ...unNullModel, ...unNullModel.ChemicalMasterDetail };
  //       }
  //       this.sdsFormService.updateFormValue(updateModel, {
  //         isMarkAsPristine: false,
  //         isInitializing: false
  //       });
  //     });
  // }

  fixpercentage(input: string | null | undefined): string {
    return input??'';
  }

	// model16Parse(unNullModel: any): any {
	// 	// 複製一份 ChemicalMasterDetail
	// 	let chemicalMasterDetail = JSON.parse(JSON.stringify(unNullModel.ChemicalMasterDetail));

	// 	// 取得表單中已填寫的資料
	// 	const formValue = this.sdsFormService.form.getRawValue();

	// 	// 取得有值的欄位
  //   chemicalMasterDetail = Object.entries(chemicalMasterDetail).reduce((acc: { [key: string]: any }, [key, value]) => {
  //     if (value !== null && value !== undefined) {
  //       acc[key] = value;
  //     }
  //     return acc;
  //   }, {});

	// 	// 轉換欄位正確名稱
	// 	chemicalMasterDetail['RelatedLawInfo'] = chemicalMasterDetail['RegulatoryInformation'];
	// 	chemicalMasterDetail['TranspaortName'] = chemicalMasterDetail['UNProperShippingName'];
	// 	chemicalMasterDetail['TransportHazardClassfication'] = chemicalMasterDetail['TransportHazardClass'];
	// 	chemicalMasterDetail['PackageClass'] = chemicalMasterDetail['PackingGroup'];
	// 	chemicalMasterDetail['OceanCorrupt'] = chemicalMasterDetail['MarinePollutant'];
	// 	chemicalMasterDetail['TransportMemo'] = chemicalMasterDetail['SpecialTransportationAndSpecialPrecautions'];
	// 	chemicalMasterDetail['EngineeringControl'] = chemicalMasterDetail['EngineeringControls'];
	// 	chemicalMasterDetail['ExposureRoute'] = chemicalMasterDetail['ToxicExposurePath'];
	// 	chemicalMasterDetail['Symptom'] = chemicalMasterDetail['ToxicSymptom'];

	// 	// 如果有需要排除或保留其他欄位，也可以在這裡處理

	// 	return chemicalMasterDetail;
	// }


  getnumberpart(data: string, type: string): string {
    const numbers = data.match(/\d+(\.\d+)?/g);
    const minValue = numbers ? Math.min(...numbers.map(Number)) : null;
    const maxValue = numbers ? Math.max(...numbers.map(Number)) : null;
    if (type === 'Min') {
      return minValue ? minValue.toString() : '';
    } else if (type === 'Max') {
      return maxValue ? maxValue.toString() : '';
    } else {
      return '';
    }
  }

  getPhysicalState(data: string): string {
    if (data.includes('固') || data.includes('粉')) {
      return 'solid';
    } else if (data.includes('液')) {
      return 'fluid';
    } else if (data.includes('気')) {
      return 'gas';
    }
    return '';
  }

  // 依據傳入的 file（或 fileInfo）啟動 SDS 分析與自動填入欄位流程
  handleSdsAnalysisAndAutoFillFields(file: File, sdsfilename: string, sdsfilelink: SafeUrl, downloadfileobject: any = null) {
    if (!file) return;
    if (downloadfileobject) {
      this.downloadfileobject = downloadfileobject;
      // this.existsdsfilelink = environment.fileUrl + downloadfileobject.value.Url;
    }
    this.sdsfilename = sdsfilename;
    this.sdsfilelink = sdsfilelink;
    const loadindex = [-1]; // -1 表示全部抓取
    this.gersdsdata(file, loadindex);
  }

  // 呼叫 API 取得 SDS 解析資料並串流處理
  gersdsdata(file: File, loadindex: number[]) {

    this.loading = true;

    this.sdsParse(file, loadindex)
      .pipe(finalize(() => this.getdatamodel()))
      .subscribe({
        next: (line: string) => {
          this.appendToStreamOutput(line);
        },
        error: () => {
          // this.confirmService
          //   .open(
          //     this.translateService.instant('CHEM.SDS.PARSE_ERROR_TITLE'),
          //     this.translateService.instant('CHEM.SDS.PARSE_ERROR_TEXT'),
          //     { rejectVisible: false }
          //   )
          //   .subscribe();
        }
      });
  }

  // 重新載入指定進度項目
  reloadSDSdata(targetindex: number[]) {
    alert("Reload items：" + targetindex);
    this.parsencontentjson = "";
    this.canimportdata = false;
    this.datastage = 0;
    this.parsercontenttemp = '';
    this.Calculatetime = '';
    this.Resultbolean = "";
    targetindex.forEach(index => {
      this.toggleUnFinished(index);
      const key = `parsercontenttemp_${index}`;
      if ((this as any).hasOwnProperty(key)) {
        (this as any)[key] = '';
      } else {
        console.warn(`Unexpected key: ${key}`);
      }
    });
    if (this.selectedfile) {
      this.gersdsdata(this.selectedfile, targetindex);
    } else {
      console.warn("Selected file is null");
    }
  }

  // 重新載入所有未完成的項目
  reloadSDSdataNotFinish() {
    const notFinishedIDs = this.statusArray
      .filter(item => !item.Finished)
      .map(item => item.ID);
    this.reloadSDSdata(notFinishedIDs);
  }

  // 將每筆串流回傳的資料處理後累計至對應區塊
  appendToStreamOutput(data: string) {
    if (data.startsWith('[Info]')) {
      this.parsercontenttemp = this.parsercontenttemp + data.replace('[Info]', '') + '<br>';
    } else {
      if (!data.includes('result: {"model"') && this.datastage === 0) {
        this.setsdscontent(data);
      } else {
        const targetString = 'result: {"model"';
        const index = data.indexOf(targetString);
        if (index !== -1) {
          const textBeforeResult = data.substring(0, index);
          const textAfterResult = data.substring(index);
          this.setsdscontent(textBeforeResult);
          this.parsencontentjson = this.parsencontentjson + textAfterResult;
        } else {
          if (data !== 'event: end') {
            this.parsencontentjson = this.parsencontentjson + data;
          }
        }
        this.datastage = 1;
        this.loading = false;
        this.checkiscomplete();
      }
    }
  }

  checkiscomplete() {
    const notFinishedIDs = this.statusArray.filter(item => !item.Finished).map(item => item.ID);
    this.showparseallbutton = notFinishedIDs.length > 0;
  }

  getmergejson(oldData: any, newData: any): any {
    const target = JSON.parse(JSON.stringify(oldData));
    var result = this.deepMerge(target, newData);
    return result;
  }

  deepMerge(target: any, source: any): any {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        const sourceVal = source[key];
        if (
          sourceVal === "" ||
          sourceVal === undefined ||
          sourceVal === null ||
          (Array.isArray(sourceVal) && sourceVal.length === 0)
        ) {
          continue;
        }
        if (typeof sourceVal === "object" && !Array.isArray(sourceVal)) {
          if (!target.hasOwnProperty(key) || typeof target[key] !== "object" || target[key] === null) {
            target[key] = {};
          }
          this.deepMerge(target[key], sourceVal);
        } else {
          target[key] = sourceVal;
        }
      }
    }
    return target;
  }

  // 將串流中每一段資料依照正則表達式分派到對應區塊中
  setsdscontent(data: string) {
    if (data !== '') {
      let match;
      while ((match = this.regex.exec(data)) !== null) {
        const key = `${match[1]}`;
        const value = match[2];
        if (value === "Done") {
          this.toggleFinished(parseInt(key, 10));
        } else {
          switch (key) {
            case '0':
              this.parsercontenttemp_0 = this.parsercontenttemp_0 + this.fommateparsercontent(value);
              break;
            case '1':
              this.parsercontenttemp_1 = this.parsercontenttemp_1 + this.fommateparsercontent(value);
              break;
            case '2':
              this.parsercontenttemp_2 = this.parsercontenttemp_2 + this.fommateparsercontent(value);
              break;
            case '3':
              this.parsercontenttemp_3 = this.parsercontenttemp_3 + this.fommateparsercontent(value);
              break;
            case '4':
              this.parsercontenttemp_4 = this.parsercontenttemp_4 + this.fommateparsercontent(value);
              break;
            case '5':
              this.parsercontenttemp_5 = this.parsercontenttemp_5 + this.fommateparsercontent(value);
              break;
            case '6':
              this.parsercontenttemp_6 = this.parsercontenttemp_6 + this.fommateparsercontent(value);
              break;
            case '7':
              this.parsercontenttemp_7 = this.parsercontenttemp_7 + this.fommateparsercontent(value);
              break;
            case '8':
              this.parsercontenttemp_8 = this.parsercontenttemp_8 + this.fommateparsercontent(value);
              break;
            case '9':
              this.parsercontenttemp_9 = this.parsercontenttemp_9 + this.fommateparsercontent(value);
              break;
            case '10':
              this.parsercontenttemp_10 = this.parsercontenttemp_10 + this.fommateparsercontent(value);
              break;
            case '11':
              this.parsercontenttemp_11 = this.parsercontenttemp_11 + this.fommateparsercontent(value);
              break;
            case '12':
              this.parsercontenttemp_12 = this.parsercontenttemp_12 + this.fommateparsercontent(value);
              break;
            case '13':
              this.parsercontenttemp_13 = this.parsercontenttemp_13 + this.fommateparsercontent(value);
              break;
            case '14':
              this.parsercontenttemp_14 = this.parsercontenttemp_14 + this.fommateparsercontent(value);
              break;
            case '15':
              this.parsercontenttemp_15 = this.parsercontenttemp_15 + this.fommateparsercontent(value);
              break;
            case '16':
              this.parsercontenttemp_16 = this.parsercontenttemp_16 + this.fommateparsercontent(value);
              break;
            default:
              console.warn(`Unexpected key: ${key}, value: ${value}`);
              break;
          }
        }
      }
    }
  }

  toggleFinished(id: number) {
    const item = this.statusArray.find(item => item.ID === id);
    if (item) {
      item.Finished = true;
    }
  }

  toggleUnFinished(id: number) {
    const item = this.statusArray.find(item => item.ID === id);
    if (item) {
      item.Finished = false;
    }
  }

  // 解析串流資料完成後，處理 JSON 並更新狀態
  getdatamodel() {
    var data = this.parsencontentjson.replace('result:', '').replace('event: end', '').trim();
    try {
      const parsedData = JSON.parse(data);
      var model = parsedData.model;
      var newdata = this.getmergejson(this.datamodel, model);
      this.datamodel = newdata;
      this.paresresult.result = this.datamodel;
      this.Calculatetime = `執行結束。總共花費了 ${model.SpendTime} 秒。`;
      this.canimportdata = true;
      this.Resultbolean = "Y";
    } catch (error) {
      this.Resultbolean = "N";
    }
  }

  fommateparsercontent(data: string): string {
    var result = data
      .replace('```', '')
      .replace('json', '')
      .replace('{', '{<br>')
      .replace('}', '<br>}')
      .replace('",', '",<br>')
      .replace('},', '},<br>');
    return result;
  }

  // 清空所有解析用變數，供關閉或重載時重置
  cleardata() {
    this.init_statusArray();
    this.sdsfilename = '';
    this.parsercontenttemp = '';
    this.parsercontenttemp_0 = '';
    this.parsercontenttemp_1 = '';
    this.parsercontenttemp_2 = '';
    this.parsercontenttemp_3 = '';
    this.parsercontenttemp_4 = '';
    this.parsercontenttemp_5 = '';
    this.parsercontenttemp_6 = '';
    this.parsercontenttemp_7 = '';
    this.parsercontenttemp_8 = '';
    this.parsercontenttemp_9 = '';
    this.parsercontenttemp_10 = '';
    this.parsercontenttemp_11 = '';
    this.parsercontenttemp_12 = '';
    this.parsercontenttemp_13 = '';
    this.parsercontenttemp_14 = '';
    this.parsercontenttemp_15 = '';
    this.parsercontenttemp_16 = '';
    this.Resultbolean = "";
    this.Calculatetime = "";
    this.datastage = 0;
    this.loading = false;
    this.parsencontentjson = "";
  }

  onFileSelected(event: any) {
    this.selectedfile = event.target.files[0];
  }

  async Parsetext() {
    if (!this.selectedfile) {
      alert('請先選擇檔案');
      return;
    }

    this.loading = true;

    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;

        // 使用 PDF.js 進行解析
        const pdf: PDFDocumentProxy = await getDocument({ data: arrayBuffer }).promise;

        let extractedText = '';

        // 處理每一頁
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);

          // 取得頁面尺寸並設定合適的縮放比例
          const viewport = page.getViewport({ scale: 2.0 });

          // 創建 canvas
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          if (context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // 渲染頁面到 canvas
            await page.render({
              canvasContext: context,
              viewport: viewport
            }).promise;

            // 提取文字內容
            const textContent = await page.getTextContent();
            const pageText = textContent.items
              .map((item: any) => item.str)
              .join(' ');

            extractedText += `=== 第 ${i} 頁 ===\n${pageText}\n\n`;
          }
        }

        // 將提取的文字顯示在頁面上
        this.parsercontenttemp = extractedText;

        // 更新解析狀態
        this.Resultbolean = "Y";
        this.Calculatetime = `解析完成，總共處理了 ${pdf.numPages} 頁`;
      };

      fileReader.readAsArrayBuffer(this.selectedfile);

    } catch (error) {
      console.error('PDF 解析錯誤:', error);
      this.Resultbolean = "N";
      this.Calculatetime = "解析失敗";
    } finally {
      this.loading = false;
    }
  }

  ParseSDS() {
    if (this.selectedfile) {
      this.handleSdsAnalysisAndAutoFillFields(this.selectedfile, this.filename, "");
    } else {
      console.warn("No file selected");
    }
  }

  // // 建立 SDS 解析 Observable，依據 isEdit、解析模式及解析器類型決定參數
  // createSdsParseObs(file: File, loadindex: number[]) {
  //   const parseMode = 'full';
  //   const isEdit = this.sdsFormService.pageMode === PageMode.EDIT;
  //   const sdsParserType = 'chatgpt'; //this.pluginService.only('sds-parser-chatgpt') ? 'chatgpt' : 'default';
  //   return this.content.chemicalSdsRepository.sdsParse(file, isEdit, parseMode, sdsParserType, loadindex);
  // }

  sdsParse(file: File, loadindex: number[]): Observable<string> {

		// 從 localStorage 中取得 'userProfile' 的資料
		const userProfileJson = localStorage.getItem('userProfile');
		var companyName = 'test';

		// // 確保資料存在並解析 JSON 字串
		// if (userProfileJson) {
		// 	const userProfile = JSON.parse(userProfileJson); // 將 JSON 字串轉換為物件
		// 	companyName = userProfile.companyName; // 取得 companyname 欄位的值
		// }


    const url = `${environment.apiUrl}/api/sdsparsertw`;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('companyid', companyName);
		formData.append('targetlist', loadindex.join(','));   //設定要抓的目標(變成0,1,2,3這樣)


		// 建立一個 Subject 來即時發送每個流段
		const resultSubject = new Subject<string>();

		fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				Accept: 'text/event-stream' // 確保瀏覽器理解這是一個 SSE 流式回應
			}
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

        if (!response.body) {
          throw new Error('Response body is null');
        }

				const reader = response.body.getReader();
				const decoder = new TextDecoder();

				// 讀取流式資料
				function read() {
					reader
						.read()
						.then(({ done, value }) => {
							if (done) {
								resultSubject.complete(); // 完成流時，標記 Observable 為完成
								return;
							}

							const chunk = decoder.decode(value, { stream: true });

							resultSubject.next(chunk);

							// 繼續讀取
							read();
						})
						.catch(error => {
							console.error('Error reading stream:', error);
							resultSubject.error(error);
						});
				}

				read();
			})
			.catch(error => {
				console.error('Fetch error:', error);
				resultSubject.error(error);
			});

		// 回傳 Observable，讓訂閱者可以即時接收流段
		return resultSubject.asObservable();
	}

	onDownloadClick(id: any): void {
    // console.log("downloadfileid", id);
    // this.content.chemicalSdsRepository.postExportLog(id).subscribe({
    //   next: () => { /* 成功處理 */ },
    //   error: (error: any) => {
    //     // this.notificationService.error(error.Message);
    //   }
    // });
  }
}
