import { Component, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'; // Import environment
import { Subject } from 'rxjs'; // Import Subject from rxjs

@Component({
  selector: 'app-sds-regulation-check',
  templateUrl: './sds-regulation-check.component.html',
  styleUrls: ['./sds-regulation-check.component.css']
})
export class SdsRegulationCheckComponent {
  selectedFile: File | null = null;
  apiResponse: string = '';
  regulations: string[] = [];
  isLoading: boolean = false; // Add loading state
  private apiUrl = environment.apiUrl; // Use environment API URL

  constructor(private http: HttpClient, private elRef: ElementRef) {}

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  parseFile() {
    if (this.selectedFile) {
      this.isLoading = true; // Set loading state to true
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('companyid', 'YourCompanyID'); // Add companyid parameter

      const url = `${this.apiUrl}/api/sdsparsertw`; // Construct URL using environment API URL

      this.http.post(url, formData, { responseType: 'text' }).subscribe({
        next: (response) => {
          this.isLoading = false; // Set loading state to false

          console.log('Response:', response);

          // Extract data after 'result:'
          const resultIndex = response.indexOf('result:');
          if (resultIndex !== -1) {
            let resultData = response.substring(resultIndex + 7).trim();
            resultData = resultData.replace('event: end', '').trim(); // Remove 'event: end'
            try {
              const jsonObject = JSON.parse(resultData);
              const formattedJson = JSON.stringify(jsonObject, null, 2); // Format JSON with indentation
              const responseDiv = this.elRef.nativeElement.querySelector('#Response');
              if (responseDiv) {
                responseDiv.innerHTML = `<pre>${formattedJson}</pre>`; // Display formatted JSON in <pre> tag
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        },
        error: (error) => {
          this.isLoading = false; // Set loading state to false
          console.error('Error parsing file:', error);
        }
      });
    }
  }

  checkRegulations() {
    if (this.apiResponse) {
      this.http.post(`${this.apiUrl}/api/sds/check-regulations`, JSON.parse(this.apiResponse)).subscribe({
        next: (response: any) => {
          this.regulations = response.regulations;
        },
        error: (error) => {
          console.error('Error checking regulations:', error);
        }
      });
    }
  }
}
