<div class="log-container">
  <h2>日誌列表 - {{ logType ? logType : '全部' }}</h2>

  <div *ngIf="isLoading" class="loading">
    載入中...
  </div>

  <div *ngIf="error" class="error-message">
    {{ error }}
  </div>

  <div *ngIf="!isLoading && !error">
    <div class="log-summary">
      資料總數: {{ totalLogs }}
    </div>
    <table class="log-table">
      <thead>
        <tr>
          <th>時間</th>
          <th>類型</th>
          <th>使用者</th>
          <th>訊息</th>
          <th>等級</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of logs" [ngClass]="log.level.toLowerCase()">
          <td>{{ log.timestamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>{{ log.logType }}</td>
          <td>{{ log.userId }}</td>
          <td>{{ log.message }}</td>
          <td>{{ log.level }}</td>
        </tr>
        <tr *ngIf="logs.length === 0">
          <td colspan="5" class="no-data">沒有找到符合條件的日誌</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
