import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment'; // ✅ 引入 environment
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private apiUrl = environment.apiUrl; // ✅ 使用 `environment.apiUrl`

  constructor(private http: HttpClient) {}


  //驗證帳密並取得相關權限



  async Loginlog(
    companyId: string,
    logType: 'Login' | 'Operation' | 'Error' ,
    userId: string,
    message: string,
    level: 'INFO' | 'WARNING' | 'ERROR'
  ) {
    try {
      const ipData: any = await firstValueFrom(this.http.get('https://api64.ipify.org?format=json'));
      const ip = ipData?.ip || 'Unknown';

      const browser = this.getBrowserInfo();

      const logEntry = {
        companyId,
        logType,
        userId,
        message,
        details: { ip, browser },
        level,
      };

      const url = `${this.apiUrl}/api/logs/Loginlogs`; // ✅ API URL 會根據環境自動變化
      this.http.post(url, logEntry).subscribe({
        error: (err) => console.error('Failed to send log:', err)
      });
    } catch (error) {
      console.error('Failed to retrieve IP or log:', error);
    }
  }

  async simulate_report_log(
    companyId: string,
    logType: 'simulate-exam',
    userId: string,
    message: string,
    level: 'INFO' | 'WARNING' | 'ERROR'
  ) {
    try {

      const browser = this.getBrowserInfo();

      const logEntry = {
        companyId,
        logType,
        userId,
        message,
        level,
      };

      const url = `${this.apiUrl}/api/logs/Loginlogs`; // ✅ API URL 會根據環境自動變化
      this.http.post(url, logEntry).subscribe({
        error: (err) => console.error('Failed to send log:', err)
      });
    } catch (error) {
      console.error('Failed to retrieve IP or log:', error);
    }
  }

  // 🔹 取得瀏覽器資訊
  private getBrowserInfo(): string {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';

    if (userAgent.includes('Chrome')) browserName = 'Chrome';
    if (userAgent.includes('Firefox')) browserName = 'Firefox';
    if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) browserName = 'Safari';
    if (userAgent.includes('Edge')) browserName = 'Edge';
    if (userAgent.includes('Opera') || userAgent.includes('OPR')) browserName = 'Opera';
    if (userAgent.includes('MSIE') || userAgent.includes('Trident')) browserName = 'Internet Explorer';

    return `${browserName} (${userAgent})`;
  }
}
