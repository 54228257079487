import { Component } from '@angular/core';
import { BlobServiceClient } from "@azure/storage-blob";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-filedownloader',
  standalone: true,
  imports: [],
  templateUrl: './filedownloader.component.html',
  styleUrl: './filedownloader.component.scss'
})
export class FiledownloaderComponent {

  private blobServiceClient: BlobServiceClient;

  constructor() {
    const blobUrl = environment.blobUrl;
    const sasToken = environment.blobsasToken;
    this.blobServiceClient = new BlobServiceClient(`${blobUrl}?${sasToken}`);
  }

  downloadFile() {

    // await this.downloadBlob('quallico', 'myblob');

  }

  async downloadBlob(containerName: string, filename: string): Promise<void> {
    try {
      const containerClient = this.blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(filename);

      // 下載 Blob 的內容
      const downloadBlockBlobResponse = await blobClient.download();
      const blobData = await downloadBlockBlobResponse.blobBody;

      if (blobData) {
        const url = URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // 指定下載檔案的名稱
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading blob:', error);
      throw error;
    }
  }
}
