import { Component, EventEmitter, Output, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatabaseService } from '../../../services/database.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-contractor-construction-application-form',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './contractor-construction-application-form.component.html',
  styleUrls: ['./contractor-construction-application-form.component.scss'],
})
export class ContractorConstructionApplicationFormComponent implements OnInit, AfterViewInit {
  @Output() valueSubmitted = new EventEmitter<any>();
  formfinished = false;

  // 签名板相关
    private signaturePads: { [key: string]: SignaturePad | undefined } = {};

  // 表单数据
  formData = {
    formNumber: '',
    fillDate: '',
    applicationMonth: '',
    applicationDay: '',
    contractorName: '',
    workContent: '',
    workLocation: '',
    scheduledStartDate: '',
    scheduledEndDate: '',
    personsInCharge: '',
    safetyOfficial: '',
    additionalInfo: '',
  };

  // 作業類別選項
  workCategories = [
    { name: '1.用電作業', selected: false },
    { name: '2.吊掛、搬運等作業', selected: false },
    { name: '3.儀器設備', selected: false },
    { name: '4.電器工程', selected: false },
    { name: '5.高空作業', selected: false },
    { name: '6.管線拆卸、安裝、檢修等工程', selected: false },
    { name: '7.塔槽之修理清繕、維護等工程', selected: false },
    { name: '8.木工拆卸、挖掘孔洞、穿牆打孔、室內裝修及新建工程', selected: false },
    { name: '9.有機溶劑', selected: false },
    { name: '10.接觸危害物質或粉塵作業', selected: false },
    { name: '11.狹小空間、局限空間、涵洞等工程', selected: false },
    { name: '12.消防工程', selected: false },
    { name: '13.空調管線工程', selected: false },
    { name: '14.其他', selected: false },
  ];

  months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];

  private ownerSignaturePad: SignaturePad | undefined;
  private safetySignaturePad: SignaturePad | undefined;

  constructor(private databaseService: DatabaseService) {}

  ngOnInit() {}

  ngAfterViewInit() {

    const signatureIds = [
      'ownerSignatureCanvas',
      'safetySignatureCanvas',
    ];

    signatureIds.forEach((id) => {
          const canvas = document.getElementById(id) as HTMLCanvasElement;
          this.signaturePads[id] = new SignaturePad(canvas);
          this.updateCanvasSize(id);
          window.addEventListener('resize', () => this.updateCanvasSize(id)); // 监听窗口大小变化
        });


  }

  updateCanvasSize(signatureId: string) {
    if (this.signaturePads[signatureId]) {
      const canvas = document.getElementById(signatureId) as HTMLCanvasElement;
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;

      canvas.width = width;
      canvas.height = height;
      this.signaturePads[signatureId]?.clear();
    }
  }

  async sendValue() {
    const selectedCategories = this.workCategories.filter(c => c.selected).map(c => c.name);
    const formDataWithSignatures = {
      ...this.formData,
      workCategories: selectedCategories,
      ownerSignature: this.getSignatureData(this.ownerSignaturePad),
      safetySignature: this.getSignatureData(this.safetySignaturePad),
    };

    console.log('Form data with signatures:', formDataWithSignatures);

    // 提交表單的邏輯...

    this.formfinished = true;
  }

  getSignatureData(pad: SignaturePad | undefined) {
    if (pad && !pad.isEmpty()) {
      return pad.toDataURL();
    }
    return null;
  }

  clearSignature(signatureId: string) {
    if (this.signaturePads[signatureId]) {
      this.signaturePads[signatureId]?.clear();
    }
  }

}
