import { Component, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import SignaturePad from 'signature_pad';  // 引入 signature_pad

@Component({
  selector: 'app-safetyand-health-agreement-organization-meeting',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './safetyand-health-agreement-organization-meeting.component.html',
  styleUrl: './safetyand-health-agreement-organization-meeting.component.scss'
})
export class SafetyandHealthAgreementOrganizationMeetingComponent {
  @Output() valueSubmitted = new EventEmitter<any>();

  formfinished = false;

  // Form data
  formData = {
    projectName: '',
    meetingTime: '',
    meetingLocation: '',
    constructionStart: '',
    constructionEnd: '',
    floorArea: '',
    participants: '',
    agreementIssues: '',
    purchaseDepartmentSignature: null as string | null,
    staffPersonSignature: null as string | null,
    ehsbsSignature: null as string | null,
    mainContractorSignature: null as string | null,
    siteManagerSignature: null as string | null,
  };

  // 签名板相关
  private signaturePads: { [key: string]: SignaturePad | undefined } = {};

  // Submit form
  sendValue() {
    const purchaseDepartmentSignature = this.getSignatureData('purchaseDepartmentSignature');
    const staffPersonSignature = this.getSignatureData('staffPersonSignature');
    const ehsbsSignature = this.getSignatureData('ehsbsSignature');
    const mainContractorSignature = this.getSignatureData('mainContractorSignature');
    const siteManagerSignature = this.getSignatureData('siteManagerSignature');

    this.formData.purchaseDepartmentSignature = purchaseDepartmentSignature;
    this.formData.staffPersonSignature = staffPersonSignature;
    this.formData.ehsbsSignature = ehsbsSignature;
    this.formData.mainContractorSignature = mainContractorSignature;
    this.formData.siteManagerSignature = siteManagerSignature;

    // Emit the form data
    this.valueSubmitted.emit(this.formData);
    this.formfinished = true;
  }

  ngAfterViewInit() {
    const signatureIds = [
      'purchaseDepartmentSignature',
      'staffPersonSignature',
      'ehsbsSignature',
      'mainContractorSignature',
      'siteManagerSignature'
    ];

    signatureIds.forEach((id) => {
      const canvas = document.getElementById(id) as HTMLCanvasElement;
      this.signaturePads[id] = new SignaturePad(canvas);
      this.updateCanvasSize(id);
      window.addEventListener('resize', () => this.updateCanvasSize(id)); // 监听窗口大小变化
    });
  }

  ngOnDestroy() {
    const signatureIds = [
      'purchaseDepartmentSignature',
      'staffPersonSignature',
      'ehsbsSignature',
      'mainContractorSignature',
      'siteManagerSignature'
    ];

    signatureIds.forEach((id) => {
      window.removeEventListener('resize', () => this.updateCanvasSize(id)); // 清理事件监听
    });
  }

  updateCanvasSize(signatureId: string) {
    if (this.signaturePads[signatureId]) {
      const canvas = document.getElementById(signatureId) as HTMLCanvasElement;
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;

      canvas.width = width;
      canvas.height = height;
      this.signaturePads[signatureId]?.clear();
    }
  }

  getSignatureData(signatureId: string) {
    if (this.signaturePads[signatureId] && !this.signaturePads[signatureId]?.isEmpty()) {
      return this.signaturePads[signatureId]?.toDataURL();
    }
    return null;
  }

  clearSignature(signatureId: string) {
    if (this.signaturePads[signatureId]) {
      this.signaturePads[signatureId]?.clear();
    }
  }
}
