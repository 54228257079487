import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EChartsOption, BarSeriesOption } from 'echarts';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { YhService } from '../../../services/yh.service';

@Component({
  selector: 'app-yh-allclass',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule, NgxEchartsModule],
  providers: [DecimalPipe],
  templateUrl: './yh-allclass.component.html',
  styleUrls: ['./yh-allclass.component.scss']
})
export class YhAllclassComponent implements OnInit {

  private decimalPipe = inject(DecimalPipe);

  filterForm = new FormGroup({
    startDate: new FormControl('2025-01-01'),
    endDate: new FormControl('2025-12-31')
  });

  selectedYear: number = new Date().getFullYear();
  isLoading = false;
  dataLoaded = false;

  classes: any[] = [];
  chartOption: EChartsOption | null = null;
  selectedYM: string | null = null;
  selectedCState: string | null = null;

  expectedRevenue: number = 0;

  minStudentCount: number = 0;  // 預設為 0，不過濾
  classcount:number=0;
  filteredClasses: any[] = [];
  originalClasses: any[] = [];  // 儲存 API 回傳的原始課程資料

  constructor(private yhService: YhService) {}

  ngOnInit() {
    this.setYearRange(this.selectedYear);
    this.fetchData();
  }

  setYearRange(yearOrEvent: number | Event) {
    let year: number;

    if (typeof yearOrEvent === 'number') {
      year = yearOrEvent;
    } else {
      const target = yearOrEvent.target as HTMLSelectElement;
      year = parseInt(target.value, 10);
    }

    this.selectedYear = year;
    this.filterForm.patchValue({
      startDate: `${year}-01-01`,
      endDate: `${year}-12-31`
    });
  }

  resetData() {
    this.filteredClasses = [];
    this.selectedYM = null;
    this.selectedCState = null;
    this.expectedRevenue = 0;
  }

  fetchData() {
    this.resetData();

    const { startDate, endDate } = this.filterForm.value;
    if (!startDate || !endDate) return;

    this.isLoading = true;
    this.dataLoaded = false;

    this.yhService.getAllClasses(startDate, endDate).subscribe(data => {
      // ✅ 確保 originalClasses 存入 API 回傳的數據
      this.originalClasses = data.map(cls => ({
        ...cls,
        newMoney: this.parseMoney(cls.Money),
        totalRevenue: cls.StudentCount * this.parseMoney(cls.Money)
      }));

      this.classes = [...this.originalClasses]; // ✅ 確保 classes 也同步更新
      this.filteredClasses = [...this.originalClasses]; // ✅ 預設顯示所有資料

      this.prepareChartData();
      this.isLoading = false;
      this.dataLoaded = true;
    }, error => {
      console.error('獲取課程資料失敗', error);
      this.isLoading = false;
    });
  }

  prepareChartData() {
    const groupedData: { [key: string]: { [key: string]: number } } = {};

    this.classes.forEach(cls => {
      const ym = cls.StartYM;
      const state = cls.CState || '未定';

      if (!groupedData[ym]) {
        groupedData[ym] = {};
      }
      if (!groupedData[ym][state]) {
        groupedData[ym][state] = 0;
      }
      groupedData[ym][state]++;
    });

    const labels = Object.keys(groupedData).sort();
    const states = Array.from(new Set(this.classes.map(cls => cls.CState || '未定')));

    const series: BarSeriesOption[] = states.map(state => ({
      name: state,
      type: 'bar',
      stack: 'total',
      emphasis: { focus: 'series' },
      data: labels.map(ym => groupedData[ym][state] || 0)
    }));

    this.chartOption = {
      tooltip: { trigger: 'axis' },
      legend: { data: states },
      xAxis: { type: 'category', data: labels },
      yAxis: { type: 'value' },
      series
    };
  }

  onChartClick(params: any) {
    if (params.dataIndex === undefined || params.dataIndex === null) return;

    let xAxisData: string[] = [];
    const xAxis = this.chartOption?.xAxis as any;

    if (Array.isArray(xAxis)) {
      xAxisData = xAxis[0]?.data as string[] || [];
    } else {
      xAxisData = xAxis?.data as string[] || [];
    }

    this.selectedYM = xAxisData[params.dataIndex] || null;
    this.selectedCState = params.seriesName || null;

    if (this.selectedYM && this.selectedCState) {
      this.filteredClasses = this.classes.filter(
        cls => cls.StartYM === this.selectedYM && cls.CState === this.selectedCState
      );

      this.calculateResult();
    }
  }

  parseMoney(money: string): number {
    if (!money) return 0;

    const earlyBirdMatch = money.match(/早鳥價:\s*([\d,]+)元/);
    if (earlyBirdMatch) {
      return parseInt(earlyBirdMatch[1].replace(/,/g, ''), 10);
    }

    const priceMatch = money.match(/^([\d,]+)/);
    if (priceMatch) {
      return parseInt(priceMatch[0].replace(/,/g, ''), 10);
    }

    return 0;
  }

  calculateResult() {
    this.expectedRevenue = this.filteredClasses.reduce((sum, cls) => {
      return sum + this.calculateCourseRevenue(cls);
    }, 0);


    this.classcount = this.filteredClasses.length;

  }

  calculateCourseRevenue(cls: any): number {
    const price = this.parseMoney(cls.Money);
    return cls.StudentCount * price;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0') ?? '0';
  }


  filterByStudentCount(filter: string) {
    console.log("this.originalClasses", this.originalClasses);

    if (this.originalClasses.length === 0) {
      console.warn("originalClasses 仍然是空陣列，請確認 fetchData() 是否正確執行");
      return;
    }

    let filteredByStudentCount: any[] = [];

    // ✅ 解析 filter 字串，例如 "<=15", ">15", "<=20"
    const match = filter.match(/([<>]=?)(\d+)/);
    if (match) {
      const operator = match[1]; // 取得運算符，如 "<=" 或 ">"
      const value = parseInt(match[2], 10); // 取得數字

      if (operator === "<=") {
        filteredByStudentCount = this.originalClasses.filter(cls => cls.StudentCount <= value);
      } else if (operator === ">=") {
        filteredByStudentCount = this.originalClasses.filter(cls => cls.StudentCount >= value);
      } else if (operator === "<") {
        filteredByStudentCount = this.originalClasses.filter(cls => cls.StudentCount < value);
      } else if (operator === ">") {
        filteredByStudentCount = this.originalClasses.filter(cls => cls.StudentCount > value);
      }
    } else {
      console.warn("無效的篩選條件，請使用 '<=數字' 或 '>=數字' 格式");
      return;
    }

    // ✅ 如果已經有選擇的 YM 和 CState，則進一步篩選
    if (this.selectedYM && this.selectedCState) {
      filteredByStudentCount = filteredByStudentCount.filter(cls =>
        cls.StartYM === this.selectedYM && cls.CState === this.selectedCState
      );
    }

    this.filteredClasses = filteredByStudentCount;

    // ✅ 重新計算預期營業額
    this.calculateResult();
  }


  filterClear() {
    if (this.selectedYM && this.selectedCState) {
      // ✅ 只清除「報名人數 ≤ 15」的篩選，但保留 YM 和 CState
      this.filteredClasses = this.originalClasses.filter(cls =>
        cls.StartYM === this.selectedYM && cls.CState === this.selectedCState
      );
    } else {
      // ✅ 若沒有 YM 和 CState，則回復所有數據
      this.filteredClasses = [...this.originalClasses];
    }

    this.calculateResult();
  }

}
