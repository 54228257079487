<div class="pdf-container">
  <div class="toolbar">

    <!-- <div id="leftpart"> -->
      <input type="text" [(ngModel)]="keyword" placeholder="keyword" />
      <button (click)="searchKeyword()"><i class="fa-solid fa-magnifying-glass"></i></button>

      Pages:
      <select [(ngModel)]="currentPage" (change)="goToPage()">
        <option *ngFor="let page of pageNumbers" [value]="page">
          {{ page }}
        </option>
      </select>
      /{{totalPages}}

    <!-- </div> -->

    <!-- <div id="rightpart"> -->

      <button style="margin-left:5px;" (click)="zoomIn()"><i class="fa-solid fa-plus"></i></button>
      <button style="margin-left:5px;" (click)="zoomOut()"><i class="fa-solid fa-minus"></i></button>
      <button style="margin-left:5px;" (click)="rotate()"><i class="fa-solid fa-arrow-rotate-right"></i></button>
      <button style="margin-left:5px;" (click)="downloadFile()"><i class="fa-solid fa-download"></i></button>

    <!-- </div> -->
  </div>

  <canvas #pdfCanvas></canvas>
  <div #textLayer class="text-layer"></div>
</div>
