import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as echarts from 'echarts'; // 引入 ECharts

@Component({
  selector: 'app-graph-chart',
  standalone: true,
  template: `
    <div class="chart-container">
      <h3>{{ title }}</h3>
      <div #chartContainer style="width: 100%; height: 400px;"></div>
    </div>
  `,
  styleUrls: ['../report-component.scss']
})
export class GraphChartComponent implements AfterViewInit {
  @Input() title: string = '';
  @Input() nodes: any[] = []; // 節點數據
  @Input() links: any[] = []; // 連接數據

  @ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;

  ngAfterViewInit(): void {
    this.generateChart();
  }

  generateChart(): void {
    const chartElement = this.chartContainer.nativeElement;
    const myChart = echarts.init(chartElement);

    const chartOption = {
      title: {
        text: this.title,
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}'
      },
      series: [
        {
          type: 'graph',
          layout: 'force',  // 使用力導向佈局
          symbolSize: 50,
          roam: true,  // 允許縮放和拖拽
          edgeSymbol: ['none', 'arrow'],
          edgeSymbolSize: [4, 10],
          data: this.nodes.map(node => ({
            ...node,
            draggable: true  // 使每個節點可拖拽
          })),
          links: this.links,
          force: {
            repulsion: 500,  // 增加排斥力，使節點更分散
            gravity: 0.1,    // 輕微的引力來避免節點過於集中
            layoutAnimation: true  // 允許佈局動畫
          },
          lineStyle: {
            color: 'source',
            curveness: 0.3
          }
        }
      ]
    };

    myChart.setOption(chartOption);
  }


}
