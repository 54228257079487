import { Component, Input, AfterViewInit, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-form-signature',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section" [attr.name]="name">
      <label>{{ label }}</label>
      <canvas #signatureCanvas class="signature-canvas" id="{{ id }}"></canvas>
      <div class="signature-button-group">
        <!-- 完成簽名按鈕 -->
        <button
          type="button"
          class="complete-signature"
          (click)="completeSignature()"
          [disabled]="isDisabled">
          {{ completeButtonText }}
        </button>
        <!-- 重新簽名按鈕 -->
        <button
          type="button"
          class="clear-signature"
          (click)="clearSignature()">
          {{ clearButtonText }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['total-component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSignatureComponent),
      multi: true
    }
  ]
})
export class FormSignatureComponent implements AfterViewInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() name: string = '';

  // 新增按鈕文字屬性，預設值可以依需求修改
  @Input() completeButtonText: string = 'save';
  @Input() clearButtonText: string = 're-sign';

  @ViewChild('signatureCanvas') signatureCanvasRef!: ElementRef<HTMLCanvasElement>;

  private signaturePad!: SignaturePad;
  private _signature: string | null = null;

  isDisabled: boolean = false;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    this._signature = value;
    // 如果需要根據傳入的值還原簽名，可在此處實作
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    const canvas = this.signatureCanvasRef.nativeElement;
    this.signaturePad = new SignaturePad(canvas);
    this.updateCanvasSize();
    window.addEventListener('resize', () => this.updateCanvasSize());
    // 轉型為 any 以存取 onEnd 屬性，如果可行的話
    (this.signaturePad as any).onEnd = () => this.updateSignature();
  }

  updateCanvasSize() {
    if (this.signaturePad && this.signatureCanvasRef) {
      const canvas = this.signatureCanvasRef.nativeElement;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      // 不要每次 resize 都清除內容，這裡僅清除空白狀態
      if (this.signaturePad.isEmpty()) {
        this.signaturePad.clear();
      }
    }
  }

  completeSignature() {
    // 先更新簽名數值
    this.updateSignature();
    // 禁用畫布互動
    this.signatureCanvasRef.nativeElement.style.pointerEvents = 'none';
    this.isDisabled = true;
    // 加入完成後的樣式，例如背景變成淺灰色
    this.signatureCanvasRef.nativeElement.classList.add('completed');
  }

  clearSignature() {
    if (this.signaturePad) {
      this.signaturePad.clear();
      // 重新啟用畫布互動
      this.signatureCanvasRef.nativeElement.style.pointerEvents = 'auto';
      this.isDisabled = false;
      // 移除完成樣式
      this.signatureCanvasRef.nativeElement.classList.remove('completed');
      // 更新簽名值，變成 null
      this.updateSignature();
    }
  }

  updateSignature() {
    let sigData: string | null = null;
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      sigData = this.signaturePad.toDataURL();
    }
    this._signature = sigData;
    console.log('簽名 data URL:', sigData);
    this.onChange(sigData);
    this.onTouched();
    console.log('簽名已更新：', sigData);
  }
}
