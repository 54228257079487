
<!-- 用row-cols-md-X 控制一列出現幾個卡片 -->
<div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col" *ngFor="let item of AssistantDataItem">
    <a (click)="gotoAI(item.assistantNo)" target="_blank" style="cursor: pointer;" class="text-decoration-none"> <!-- 包裹卡片並設置 href -->
      <div class="card">
        <img [src]="item.imageurl" class="card-img-top" [alt]="item.title">
        <div class="card-body">
          <h5 class="card-title">{{ item.title }}</h5>
          <p class="card-text">{{ item.content }}</p>
        </div>
        <div class="card-footer">
          <small class="text-muted" [innerHTML]="item.memo"></small><br>
        </div>
      </div>
    </a>
  </div>
</div>

