
<div id="chatdiv">
  <span class="close-icon" (click)="closeDiv()" *ngIf="!showintroduction" >×</span>
  <i *ngIf="showintroduction && vector_store_id !== ''" class="fa-solid fa-sliders setup-icon"
    (click)="gotofilecenter()" title="Go to File Center"></i>

  <div id="Introduction" *ngIf="showintroduction">

    <div class="container" style="margin-left: 0px">
      <div class="image-column">
        <img [src]="init_image" width="150px">
      </div>
      <div class="text-column">
        <p [innerHTML]="init_word"></p>
        <div class="menubuttondiv">
          <button class="p-button p-component p-element p-button inline-btn p-button-primary mr-2 my-1"
            style="margin-right: 5px;" *ngFor="let item of init_meun"
            (click)="menubuttonclick(item.title,item.content,item.functionname)" [disabled]="!item.disable">
            {{ item.title }}
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="chat-container">
    <div
      *ngFor="let message of chatMessages; let i = index"
      [ngClass]="message.role"
    >
      <div *ngIf="message.role === 'assistant'" class="assistant-message">
        <div class="icon-column">
          <img
            src="{{ chatavator }}"
            alt="Assistant Icon"
            class="assistant-icon"
          />
        </div>
        <div class="content-column">
          <div [innerHTML]="message.content"></div>
          <ng-container #dynamicComponentContainers></ng-container>
        </div>
      </div>
      <div *ngIf="message.role !== 'assistant'">{{ message.content }}</div>
    </div>
    <div class="loadingdiv typing-effect" *ngIf="showloading">Thinking...</div>
  </div>


  <div id="centerdiv">
    <div class="input-container" *ngIf="showchat">
      <input [(ngModel)]="userMessage" placeholder="Enter your message" (keydown)="onKeyDown($event)" />
      <button id="sendbutton" type="button" class="btn btn-success" (click)="chatwithassitant(userMessage)">
        <i class="bi bi-send">Submit</i>
      </button>
    </div>

  </div>
</div>

