// form-config.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { DatabaseService } from './database.service';


export interface ModuleSetting {
  companyid: string;
  show_nopermission: boolean;
  lobby: AssistantDataItem[];
  id: string;
  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
}

export interface AssistantDataItem {
  assistantNo: string;
  assistantid: string; // openai 中的助手編號
  vector_store_id: string; // openai 中的 vector_store_id
  title: string;
  content: string;
  imageurl: string;
  memo: string;
  enable?: boolean | null;
  tools?: string[] | null; // 工具列
  Initialized: InitializedItem; // 對應 Initialized 的結構
}

export interface InitializedItem {
  init_image: string; // 初始化的圖片路徑
  init_word: string; // 初始化時顯示的文字內容
  init_meun: InitMenuItem[]; // 對應初始化選單
  chatavator: string; // 對應聊天室的圖片
}

export interface InitMenuItem {
  title: string; // 選單的標題
  content:string; // 選單的內容
  id: string; // 選單的 ID
  enable: boolean; // 是否啟用;
  functionname: string; // 選單的功能名稱
}

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private databaseService: DatabaseService) {}

   // 依據 formName 與 companyid 取得對應的表單配置
   get_company_and_module_setting(companyid: string): Observable<ModuleSetting> {
    const filter = { companyid };
    return this.databaseService.getDataByQuery(filter, 1000, "modules").pipe(
      map((response: any) => {
        if (response?.items?.[0]) {
          return response.items[0];
        }
        throw new Error('找不到數據');
      })
    );
  }

  // 透過 assistantNo 取得特定助手資料
  get_assistant_by_no(companyid: string, assistantNo: string): Observable<AssistantDataItem | null> {
    const filter = { companyid };
    return this.databaseService.getDataByQuery(filter, 1000, "modules").pipe(
      map((response: any) => {
        if (!response?.items?.[0]?.lobby) {
          throw new Error('找不到資料');
        }

        // 從 lobby 陣列中找出符合 assistantNo 的項目
        const assistant = response.items[0].lobby.find(
          (item: AssistantDataItem) => item.assistantNo === assistantNo
        );

        return assistant || null;
      })
    );
  }

}
