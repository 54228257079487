import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, from ,Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpanaiService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getThreadId(): Observable<{ threadid: string }> {
    return this.http.get<{ threadid: string }>(`${this.apiUrl}/api/assitant/getthreadid`);
  }

  public chatwithgpts(message: string,imageAttachments:string[] = [],threadid:string="",AImodel = "") {
    return this.http.post<any>(`${this.apiUrl}/api/gpts/chat`, { message,imageAttachments,threadid,AImodel });
  }

  public chatwithassistant(message: string,assitantid:string,threadid:string="",imageAttachments:string[] = []) {

    return this.http.post<any>(`${this.apiUrl}/api/assitant/chat`, { message,assitantid,threadid ,imageAttachments});
  }




  public chatwithassistantWithSteam(
    message: string,
    assitantid: string,
    threadid: string = "",
    companyid:string = "",
    imageAttachments: string[] = []
): Observable<string> {
    const url = `${this.apiUrl}/api/assitant/chatwithsteam`;

    const requestBody = {
        message,
        assitantid,
        threadid,
        companyid,
        imageAttachments: imageAttachments
    };

    const resultSubject = new Subject<string>();

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
            "Accept": "text/event-stream"
        }
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }
        if (!response.body) {
            throw new Error("Response body is empty.");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        function read() {
            reader.read().then(({ done, value }) => {
                if (done) {
                    resultSubject.complete();
                    return;
                }

                const chunk = decoder.decode(value, { stream: true });

                const lines = chunk.split("\n");
                for (const line of lines) {
                    if (line.startsWith("data: ")) {
                        const data = line.substring(6).trim();
                        if (data !== "[DONE]") {
                            resultSubject.next(data);
                        }
                    }
                }

                read();
            }).catch(error => {
                console.error('Error reading stream:', error);
                resultSubject.error(error);
            });
        }

        read();
    })
    .catch(error => {
        console.error('Fetch error:', error);
        resultSubject.error(error);
    });

    return resultSubject.asObservable();
}




  //這個不知道做什麼用的，之後要合併掉
  public chatWithAssistantSSE(userMessage: string, assistantId: string): Observable<string> {
    return new Observable<string>((observer) => {

      let apiurl = `${this.apiUrl}/api/assitant/chatwithassistant?message=${userMessage}&assistantid=${assistantId}`;

      console.log('apiurl:', apiurl);

      const eventSource = new EventSource(apiurl);

      eventSource.onmessage = (event) => {
        const reply = event.data;

        if (reply === '[DONE]') {
          eventSource.close(); // 關閉連接
          observer.complete();  // 完成 Observable
        } else {
          observer.next(reply);  // 發送新訊息給訂閱者
        }
      };

      eventSource.onerror = (error) => {
        observer.error(error);  // 錯誤處理
      };

      // 當 Observable 被取消訂閱時關閉 SSE 連接
      return () => {
        eventSource.close();
      };
    });
  }


  public getfilelist(vector_store_id: string) {
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/getfilelist`, { vector_store_id });
  }

  public deletefile(vector_store_id: string,id:string) {
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/deletefile`, { vector_store_id,id });
  }

  public uploadFiles(files: File[], vector_store_id: string): Observable<any> {
    const formData = new FormData();

    // 將檔案添加到 formData
    files.forEach((file) => {
      // const encodedFileName = encodeURIComponent(file.name);  // 將檔名編碼

      formData.append('files', file,file.name);
    });

    // 添加 vector_store_id 到 formData
    formData.append('vector_store_id', vector_store_id);

    // 發送 POST 請求，返回 Observable，並監控上傳進度
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/upload`, formData, {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      }),
      reportProgress: true, // 設置為 true 以獲取進度事件
      observe: 'events', // 觀察所有事件
    });
  }

}
