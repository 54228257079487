import { Component, EventEmitter, Input, Output, OnInit, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatabaseService } from '../../../services/database.service';
import { AzureBlobService } from '../../../services/azure-blob.service';  // 引入 AzureBlobService

import {TOTAL_COMPONENTS} from '../../items/total-components';

@Component({
  selector: 'app-demoform',
  standalone: true,
  imports: [FormsModule, CommonModule,...TOTAL_COMPONENTS],
  templateUrl: './demoform.component.html',
  styleUrls: ['./demoform.component.scss'],
})
export class DemoformComponent implements OnInit {
  @Output() valueSubmitted = new EventEmitter<any>();

  @Output() chatwithAI = new EventEmitter<string>(); // 用於傳遞參數到母元件

  @Input() Companyname = "Companyname";
  @Input() Username = "Username";

  formfinished = false;

  forminfo = {
    companyid: this.Companyname,
    username: this.Username,
    assistantid: 'assistantid',
    assistantname: 'assistantname',
    componentname: 'DemoformComponent',
    createAt: Math.floor(Date.now() / 1000).toString()
  };

  // 表單資料，photos 陣列會儲存 { fileName, preview, fileUrl }
  formData = {
    suggestionContent: '',
    responsibleUnit: '',
    improvementStatus: '',
    auditor: '',
    reportTime: '',
    auditLocation: '',
    stations:'',
    selectedOption: '',  // 新增 radio 選擇的欄位
    remarks: '',
    dueDate:'',
    ownerSignature: null,
    photos: [] as { fileName: string; preview: string; fileUrl: string }[],
    workCategories: [] as { name: string; selected: boolean }[]
  };



Stations: { label: string; value: any }[] = [
  { label: '台北車站', value: '台北車站' },
  { label: '中正紀念堂站', value: '中正紀念堂站' },
  { label: '信義線市政府站', value: '信義線市政府站' },
  { label: '淡水站', value: '淡水站' },
  { label: '南港車站', value: '南港車站' },
  { label: '士林站', value: '士林站' },
  { label: '松山站', value: '松山站' },
  { label: '科技大樓站', value: '科技大樓站' },
  { label: '北投站', value: '北投站' },
  { label: '忠孝敦化站', value: '忠孝敦化站' }
];

DepartOptions: { label: string; value: any }[] = [
  { label: '車站設備區', value: '車站設備區' },
  { label: '變電所', value: '變電所' },
  { label: '列車檢修廠', value: '列車檢修廠' },
  { label: '信號控制中心', value: '信號控制中心' },
  { label: '維修倉庫', value: '維修倉庫' },
  { label: '乘客服務中心', value: '乘客服務中心' },
  { label: '地下通道', value: '地下通道' },
  { label: '電梯扶梯檢修區', value: '電梯扶梯檢修區' },
  { label: '車站月台', value: '車站月台' },
  { label: '交通指揮室', value: '交通指揮室' }
];


  auditorlist: { label: string; value: any }[] = [
    { label: '王大明', value: '王大明' },
    { label: '李曉華', value: '李曉華' },
    { label: '方大同', value: '方大同' }
  ];

  radioOptions = [
    { value: '符合',   label: '符合',   color: 'green', iconClass: 'fa fa-check' },
    { value: '不符合', label: '不符合', color: 'red',   iconClass: 'fa fa-times' },
    { value: '建議',   label: '建議',   color: 'blue',  iconClass: 'fa fa-comment' },
    { value: '輔導',   label: '輔導',   color: 'blue',  iconClass: 'fa fa-chalkboard-teacher' },
    { value: '讚美',   label: '讚美',   color: 'blue',  iconClass: 'fa fa-thumbs-up' },
  ];

  constructor(
    private databaseService: DatabaseService,
    private azureBlobService: AzureBlobService  // 注入 AzureBlobService
  ) {

  }

  // 在 ngOnInit 中設定提報時間
  ngOnInit(): void {
    const now = new Date();
    // 調整時間：將瀏覽器的時區偏移量扣除，產生符合 datetime-local 格式的本地時間字串
    const localTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
    this.formData.reportTime = localTime;


    this.formData.workCategories = [
      { name: '1.變電所設備維護不當', selected: false },
      { name: '2.乘客電梯和扶手電梯定期檢修不到位', selected: false },
      { name: '3.信號系統不穩定或檢查遺漏', selected: false },
      { name: '4.車站排水系統清理不足，易造成積水', selected: false },
      { name: '5.高壓電纜接頭老化或維護不良', selected: false },
      { name: '6.照明設備不定期檢修，造成燈具損壞', selected: false },
      { name: '7.月台安全防護設施不全或標示不明確', selected: false },
      { name: '8.車輛維修紀錄不完整或疏漏', selected: false },
      { name: '9.急救設備未按規定位置擺放或檢查', selected: false },
      { name: '10.工作場所未設置明確的安全警示標誌', selected: false },
  ];

  }

  // 提交表單
  // sendValue() {
  //   const saveformData = new FormData();

  //   for (let key in this.forminfo) {
  //     if (this.forminfo.hasOwnProperty(key)) {
  //       saveformData.append(key, this.forminfo[key as keyof typeof this.forminfo]);
  //     }
  //   }


  //   const dataToSave = {
  //     ...this.formData,
  //     workCategories: this.formData.workCategories
  //   .filter(item => item.selected)
  //   .map(item => item.name), // 只取名稱
  //     photos: this.formData.photos.map(photo => ({
  //       fileName: photo.fileName,
  //       fileUrl: photo.fileUrl
  //     }))
  //   };

  //   // 轉換報告時間，將使用者輸入的當地時間轉成 UTC 格式
  //   // 假設 formData.reportTime 為 "2023-02-08T15:30"
  //   const localTimeStr = this.formData.reportTime;
  //   const utcTimeInSeconds = Math.floor(new Date(localTimeStr).getTime() / 1000);
  //   dataToSave.reportTime = utcTimeInSeconds.toString(); // 或直接存數字


  //   for (let key in dataToSave) {
  //     // 將 dataToSave 轉型為 {[key: string]: any}，讓 TS 知道可以用字串索引
  //     let value = (dataToSave as { [key: string]: any })[key];
  //     if (typeof value === 'object') {
  //       value = JSON.stringify(value);
  //     }
  //     saveformData.append(key, value);
  //   }


  //   console.log("formData1", saveformData);

  //   this.savetodatabase(saveformData)
  //     .then(response => {
  //       console.log('Response from backend:', response);
  //       this.formfinished = true; // 在 API 成功回傳後才設置
  //     })
  //     .catch(error => {
  //       console.error('Error submitting data:', error);
  //     });
  // }

  sendValue() {
    // 將 workCategories 過濾，只保留選取的項目
    const dataToSave = {
      ...this.forminfo,
      ...this.formData,
      workCategories: this.formData.workCategories
  .filter(item => item.selected)
  .map(item => item.name),
      photos: this.formData.photos.map(photo => ({
        fileName: photo.fileName,
        fileUrl: photo.fileUrl
      }))
    };

    // 將 reportTime 轉換為 UTC 時間戳（秒）
    const localTimeStr = this.formData.reportTime;
    const utcTimeInSeconds = Math.floor(new Date(localTimeStr).getTime() / 1000);
    dataToSave.reportTime = utcTimeInSeconds.toString();

    console.log("dataToSave", dataToSave);

    // 使用 JSON 傳送資料，請確保後端 API 能接受 JSON 格式，
    // 並且在 DatabaseService.sendDataToBackend 方法中設置 Content-Type 為 application/json
    this.databaseService.sendDataToBackend(dataToSave)
      .then(response => {
        console.log('Response from backend:', response);
        this.formfinished = true;
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  }

  // async savetodatabase(saveformData: FormData) {
  //   const data: any = {};
  //   saveformData.forEach((value, key) => {
  //     data[key] = value;
  //   });
  //   console.log(data);

  //   // 呼叫 DatabaseService 將資料送到後端儲存
  //   try {
  //     const response = await this.databaseService.sendDataToBackend(data);
  //     this.formfinished = true;

  //     console.log('Response from backend:', response);
  //   } catch (error) {
  //     console.error('Error submitting data:', error);
  //   }
  // }

   onViewAllData() {
    const message = 'showlistComponent';
    this.chatwithAI.emit(message);
  }

}


