import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DatabaseService } from '../../../services/database.service';
import { LogService } from '../../../services/Logging.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// AI 助手介面
interface Assistant {
  assistantNo: string;
  title: string;
  content: string;
  imageurl: string;
  memo: string;
  enable: boolean;
  assistantid?: string;
  vector_store_id?: string;
  tools?: string[];
  Initialized?: {
    init_image: string;
    init_word: string;
    init_meun: InitMenu[];
    chatavator: string;
  };
}

// 初始選單項目介面
interface InitMenu {
  title: string;
  content: string;
  id: string;
  enable: boolean;
  functionname?: string;
}

// 完整模組配置介面
interface ModuleConfig {
  companyid: string;
  show_nopermission: boolean;
  lobby: Assistant[];
  id?: string;
}

@Component({
  selector: 'app-assistant-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './assistant-management.component.html',
  styleUrl: './assistant-management.component.scss'
})
export class AssistantManagementComponent implements OnInit {
  moduleConfig: ModuleConfig | null = null;
  assistantForm: FormGroup;
  isEditing = false;
  isLoading = false;
  errorMessage = '';
  selectedAssistant: Assistant | null = null;

  constructor(
    private databaseService: DatabaseService,
    private logService: LogService,
    private fb: FormBuilder
  ) {
    this.assistantForm = this.createAssistantForm();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadModuleConfig();
  }

  // 載入模組配置
  loadModuleConfig(): void {
    const companyid = environment.companyname;
    const filter = { companyid };

    this.databaseService.getDataByQuery(filter, 1, "modules").subscribe({
      next: (response: any) => {
        if (!response?.items || response.items.length === 0) {
          this.errorMessage = '沒有找到助手配置資料';
          this.isLoading = false;
          return;
        }

        this.moduleConfig = response.items[0];
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error loading module config:', error);
        this.errorMessage = '載入助手配置失敗';
        this.isLoading = false;
      }
    });
  }

  // 創建助手表單
  createAssistantForm(assistant?: Assistant): FormGroup {
    return this.fb.group({
      assistantNo: [assistant?.assistantNo || '', Validators.required],
      title: [assistant?.title || '', Validators.required],
      content: [assistant?.content || '', Validators.required],
      imageurl: [assistant?.imageurl || '', Validators.required],
      memo: [assistant?.memo || ''],
      enable: [assistant?.enable !== undefined ? assistant.enable : true],
      assistantid: [assistant?.assistantid || ''],
      vector_store_id: [assistant?.vector_store_id || ''],
      tools: [assistant?.tools || ['fileupload']],
      Initialized: this.fb.group({
        init_image: [assistant?.Initialized?.init_image || ''],
        init_word: [assistant?.Initialized?.init_word || ''],
        chatavator: [assistant?.Initialized?.chatavator || ''],
        init_meun: this.fb.array(
          assistant?.Initialized?.init_meun ?
            assistant.Initialized.init_meun.map(menu => this.createMenuGroup(menu)) :
            [this.createMenuGroup()]
        )
      })
    });
  }

  // 創建選單項目表單組
  createMenuGroup(menu?: InitMenu): FormGroup {
    return this.fb.group({
      title: [menu?.title || '', Validators.required],
      content: [menu?.content || '', Validators.required],
      id: [menu?.id || this.getRandomId()],
      enable: [menu?.enable !== undefined ? menu.enable : true],
      functionname: [menu?.functionname || '']
    });
  }

  // 隨機生成ID (用於新選單項目)
  getRandomId(): string {
    return Math.floor(Math.random() * 1000).toString();
  }

  // 獲取選單項目 FormArray
  get menuArray(): FormArray {
    return this.assistantForm.get('Initialized')?.get('init_meun') as FormArray;
  }

  // 添加新選單項目
  addMenuItem(): void {
    this.menuArray.push(this.createMenuGroup());
  }

  // 移除選單項目
  removeMenuItem(index: number): void {
    this.menuArray.removeAt(index);
  }

  // 編輯助手
  editAssistant(assistant: Assistant): void {
    this.isEditing = true;
    this.selectedAssistant = assistant;
    this.assistantForm = this.createAssistantForm(assistant);
  }

  // 新增助手
  addAssistant(): void {
    this.isEditing = true;
    this.selectedAssistant = null;
    this.assistantForm = this.createAssistantForm();
  }

  // 取消編輯
  cancelEdit(): void {
    this.isEditing = false;
    this.selectedAssistant = null;
  }

  // 提交助手表單
  submitAssistantForm(): void {
    if (this.assistantForm.invalid) {
      this.markFormGroupTouched(this.assistantForm);
      return;
    }

    const assistantData = this.assistantForm.value as Assistant;

    if (!this.moduleConfig) {
      this.errorMessage = '模組配置不存在';
      return;
    }

    this.isLoading = true;

    if (this.selectedAssistant) {
      // 更新現有助手
      const index = this.moduleConfig.lobby.findIndex(a => a.assistantNo === this.selectedAssistant?.assistantNo);
      if (index !== -1) {
        this.moduleConfig.lobby[index] = assistantData;
      }
    } else {
      // 創建新助手
      if (!this.moduleConfig.lobby) {
        this.moduleConfig.lobby = [];
      }
      this.moduleConfig.lobby.push(assistantData);
    }

    // 更新模組配置
    this.updateModuleConfig();
  }

  // 更新模組配置
  updateModuleConfig(): void {
    if (!this.moduleConfig) return;

    // this.databaseService.updateData(this.moduleConfig, 'modules').subscribe({
    //   next: (response) => {
    //     const companyid = environment.companyname;
    //     this.logService.Loginlog(
    //       companyid,
    //       'AssistantManagement',
    //       'Admin',
    //       `${this.selectedAssistant ? 'Updated' : 'Created'} assistant: ${this.assistantForm.value.title}`,
    //       'INFO'
    //     );

    //     alert(`助手${this.selectedAssistant ? '更新' : '創建'}成功`);
    //     this.isLoading = false;
    //     this.isEditing = false;
    //     this.selectedAssistant = null;
    //   },
    //   error: (error) => {
    //     console.error('Error updating module config:', error);
    //     alert(`助手${this.selectedAssistant ? '更新' : '創建'}失敗`);
    //     this.isLoading = false;
    //   }
    // });
  }

  // 刪除助手
  deleteAssistant(assistant: Assistant): void {
    if (!confirm(`確定要刪除助手 "${assistant.title}" 嗎？`)) {
      return;
    }

    if (!this.moduleConfig) {
      this.errorMessage = '模組配置不存在';
      return;
    }

    this.isLoading = true;

    // 從陣列中移除助手
    this.moduleConfig.lobby = this.moduleConfig.lobby.filter(a => a.assistantNo !== assistant.assistantNo);

    // 更新模組配置
    // this.databaseService.updateData(this.moduleConfig, 'modules').subscribe({
    //   next: () => {
    //     const companyid = environment.companyname;
    //     this.logService.Loginlog(
    //       companyid,
    //       'AssistantManagement',
    //       'Admin',
    //       `Deleted assistant: ${assistant.title}`,
    //       'INFO'
    //     );

    //     alert('助手刪除成功');
    //     this.isLoading = false;
    //   },
    //   error: (error) => {
    //     console.error('Error updating module config:', error);
    //     alert('助手刪除失敗');
    //     this.isLoading = false;
    //   }
    // });
  }

  // 將表單所有欄位標記為已觸碰，顯示驗證錯誤
  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(c => {
          if (c instanceof FormGroup) {
            this.markFormGroupTouched(c);
          }
        });
      }
    });
  }
}
