<div class="component-container">

  <div *ngIf="preview && !formfinished" style="margin-bottom: 10px;">
    <h2 style="color: darkred;">Preview Model</h2>

      <button type="button" class="small-button" (click)="toggleJsonEditor()">
        Edit Config JSON
      </button>

      <div *ngIf="showJsonEditor" class="config-json-editor">
        <textarea [(ngModel)]="configJSON" rows="10" cols="50" style="width: 100%;"></textarea>
        <br />
        <button type="button" class="small-button" (click)="reloadForm()">Reload Form</button>
      </div>

  </div>


  <div *ngIf="!formfinished">
    <!-- 只有在 formGroup 初始化完成後才渲染表單 -->
    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">

      <div *ngIf="preview; else viewMode">
        <div class="form-section">
          <label>Form Name</label>
          <input type="text" [(ngModel)]="formName" [ngModelOptions]="{standalone: true}" class="form-input" />
        </div>
      </div>
      <ng-template #viewMode>
        <h3>{{ formName }}</h3>
      </ng-template>



      <ng-container *ngFor="let field of config">
        <div class="form-field" [ngSwitch]="field.type">
          <!-- 時間元件 -->
          <app-form-datetime *ngSwitchCase="'datetime'" [label]="field.label" [id]="field.id" [name]="field.name"
            formControlName="{{ field.name }}">
          </app-form-datetime>

          <!-- 下拉選單元件 -->
          <app-form-select *ngSwitchCase="'select'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" [options]="field.options || []" formControlName="{{ field.name }}">
          </app-form-select>

          <!-- 單選按鈕元件 -->
          <app-form-radiobutton *ngSwitchCase="'radiobutton'" [label]="field.label" [id]="field.id" [name]="field.name"
            [options]="field.options || []" formControlName="{{ field.name }}">
          </app-form-radiobutton>

          <!-- 文字輸入元件 -->
          <app-form-text-input *ngSwitchCase="'text'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}">
          </app-form-text-input>

          <!-- 數字輸入元件 -->
          <app-form-number-input *ngSwitchCase="'number'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}">
          </app-form-number-input>

          <!-- 文字區塊元件 -->
          <app-form-textarea *ngSwitchCase="'textarea'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}">
          </app-form-textarea>

          <!-- GPS 定位元件 -->
          <app-form-location *ngSwitchCase="'location'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}">
          </app-form-location>

          <!-- 照片上傳元件 -->
          <app-form-photo-upload *ngSwitchCase="'photo-upload'" [label]="field.label" [id]="field.id"
            [name]="field.name" formControlName="{{ field.name }}">
          </app-form-photo-upload>

          <!-- 日期選擇元件 -->
          <app-form-date *ngSwitchCase="'date'" [label]="field.label" [id]="field.id" [name]="field.name"
            placeholder="{{ field.placeholder }}" formControlName="{{ field.name }}">
          </app-form-date>

          <!-- 多選核取元件 -->
          <app-form-multi-checkbox *ngSwitchCase="'multi-checkbox'" [label]="field.label" [id]="field.id"
            [name]="field.name" [options]="field.options" formControlName="{{ field.name }}">
          </app-form-multi-checkbox>

          <!-- 簽名元件 -->
          <app-form-signature *ngSwitchCase="'signature'" [label]="field.label" [id]="field.id" [name]="field.name"
            formControlName="{{ field.name }}">
          </app-form-signature>
        </div>
      </ng-container>
      <button *ngIf="!preview" type="submit">Submit</button>

      <button *ngIf="preview" type="button" (click)="save()">Save To Database</button>
    </form>


  </div>

  <div *ngIf="formfinished">
    Form completed!
    <button style="margin-left:5px" class="small-button" (click)="fillnewform()">Fill in data.</button>
    <button style="margin-left:5px" class="small-button" (click)="onViewAllData()">View all data.</button>
  </div>
</div>
