<div class="component-container">
  <h2>安全衛生協議組織會議</h2>
  <div *ngIf="formfinished">Form submission completed!</div>
  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">
      <div class="form-section">
        <label for="project-name">一、工程(分項)名稱</label>
        <input
          type="text"
          id="project-name"
          [(ngModel)]="formData.projectName"
          name="project-name"
          placeholder="Please enter project name"
        />
      </div>

      <div class="form-section">
        <label for="meeting-time">二、開會時間</label>
        <input
          type="datetime-local"
          id="meeting-time"
          [(ngModel)]="formData.meetingTime"
          name="meeting-time"
        />
      </div>

      <div class="form-section">
        <label for="meeting-location">三、開會地點</label>
        <input
          type="text"
          id="meeting-location"
          [(ngModel)]="formData.meetingLocation"
          name="meeting-location"
          placeholder="Please enter meeting location"
        />
      </div>

      <div class="form-section">
        <label for="construction-duration">四、預計施工期間：(起 - 迄)</label>
        <div class="date-range">
          <input
            type="date"
            id="construction-start"
            [(ngModel)]="formData.constructionStart"
            name="construction-start"
          />
          <input
            type="date"
            id="construction-end"
            [(ngModel)]="formData.constructionEnd"
            name="construction-end"
          />
        </div>
      </div>

      <div class="form-section">
        <label for="floor-area">五、施工樓層／區域</label>
        <input
          type="text"
          id="floor-area"
          [(ngModel)]="formData.floorArea"
          name="floor-area"
          placeholder="Please enter construction floor/area"
        />
      </div>

      <div class="form-section">
        <label for="participants">六、參與者</label>
        <input
          type="text"
          id="participants"
          [(ngModel)]="formData.participants"
          name="participants"
          placeholder="Please list participant names"
        />
      </div>

      <div class="form-section">
        <label for="agreement-issues">七、協議事項</label>
        <textarea
          id="agreement-issues"
          [(ngModel)]="formData.agreementIssues"
          name="agreement-issues"
          placeholder="Please describe the agreement issues"
        ></textarea>
      </div>

      <div>
        <h3>與會人員簽名</h3>
      </div>

      <!-- Signature Fields -->
      <div class="form-section">
        <label for="purchase-department">請購部門</label>
        <canvas id="purchaseDepartmentSignature" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('purchaseDepartmentSignature')">Clear Signature</button>
      </div>

      <div class="form-section">
        <label for="staff-person">承辦人員</label>
        <canvas id="staffPersonSignature" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('staffPersonSignature')">Clear Signature</button>
      </div>

      <div class="form-section">
        <label for="ehsbs">EHSBS</label>
        <canvas id="ehsbsSignature" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('ehsbsSignature')">Clear Signature</button>
      </div>

      <div class="form-section">
        <label for="main-contractor">主承攬商</label>
        <canvas id="mainContractorSignature" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('mainContractorSignature')">Clear Signature</button>
      </div>

      <div class="form-section">
        <label for="site-manager">現場管理人員及其他與會人員(含再承攬商)</label>
        <canvas id="siteManagerSignature" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('siteManagerSignature')">Clear Signature</button>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
