



  <span>SDS自動解析與列管判定小工具</span>

  <div>
    <input type="file" (change)="onFileSelected($event)" />
    <button (click)="Parsetext()" >將檔案拆解成文字</button>

    <button (click)="ParseSDS()" [disabled]="!selectedfile || loading" >開始解析</button>
  </div>

  <div class="ws-dialog-body">
    <div id="stream-output">
      <div id="sdscontentdiv">
        <div *ngIf="sdsfilename">
          <a [href]="sdsfilelink" target="_blank">{{ sdsfilename }}</a> 自動解析開始<br>
        </div>
        <div>
          解析項目:
          <span *ngFor="let item of statusArray"
                [ngClass]="{
                  'text-black': !item.Finished,
                  'text-green': item.Finished,
                  'cursor-pointer': datastage !== 0,
                  'disabled-span': datastage === 0
                }"
                (click)="datastage !== 0 ? reloadSDSdata([item.ID]) : null"
                class="targetindex">
            {{ item.ID }}
          </span>
          <!-- <button *ngIf="showparseallbutton"
                  style="margin-left: 10px;"
                  (click)="reloadSDSdataNotFinish()"
                  [disabled]="datastage === 0">
            重新取得所有未執行成功的項目
          </button> -->
        </div>
      <div class="typing-effect" *ngIf="loading">loading...</div>
        <div [innerHTML]="parsercontenttemp"></div>
        <div>{{ Calculatetime }}</div>
        <div *ngIf="Resultbolean==='Y'">
          <span style="color:green">資料解析成功。</span>
        </div>
        <div *ngIf="Resultbolean==='N'">
          <span style="color:red">資料解析失敗。</span>
        </div>
        <div [innerHTML]="parsercontenttemp_0"></div>
        <div [innerHTML]="parsercontenttemp_1"></div>
        <div [innerHTML]="parsercontenttemp_2"></div>
        <div [innerHTML]="parsercontenttemp_3"></div>
        <div [innerHTML]="parsercontenttemp_4"></div>
        <div [innerHTML]="parsercontenttemp_5"></div>
        <div [innerHTML]="parsercontenttemp_6"></div>
        <div [innerHTML]="parsercontenttemp_7"></div>
        <div [innerHTML]="parsercontenttemp_8"></div>
        <div [innerHTML]="parsercontenttemp_9"></div>
        <div [innerHTML]="parsercontenttemp_10"></div>
        <div [innerHTML]="parsercontenttemp_11"></div>
        <div [innerHTML]="parsercontenttemp_12"></div>
        <div [innerHTML]="parsercontenttemp_13"></div>
        <div [innerHTML]="parsercontenttemp_14"></div>
        <div [innerHTML]="parsercontenttemp_15"></div>
        <div [innerHTML]="parsercontenttemp_16"></div>
      </div>
    </div>
  </div>


  <button disabled="true">
    執行列管法規自動判斷
  </button>

  <!-- <button type="button" class="inline-btn"
  (click)="importparserdata()"
  [disabled]="!canimportdata || Resultbolean !== 'Y'">
存檔
</button>
<button type="button" class="p-button-outlined inline-btn"
  (click)="closeDialog()">
取消
</button> -->
