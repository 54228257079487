
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // 檢查使用者是否已登入
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    // 檢查是否需要特定模組權限
    const requiredModule = next.data['requiredModule'] as string;
    if (requiredModule && !this.authService.hasModuleAccess(requiredModule)) {
      this.router.navigate(['/unauthorized']);
      return false;
    }

    return true;
  }
}
