<!-- <div class="row">

<div class="col-6">
  <h3>原始檔案: {{filename}}</h3>
  <ngx-extended-pdf-viewer height="800px" [src]="pdfSrc" zoom="100%"></ngx-extended-pdf-viewer>
</div>

<div class="col-6">
  <h3 >智能解析結果</h3>

  <div  style="height: 800px; border: 1px solid black ; overflow: scroll;">
  <pre>{{ samplejson | json }}</pre>
  </div>
</div>

</div> -->

<!-- <div style="height: 300px;border: 1px solid black ;resize: vertical; overflow: auto;">
  <div *ngFor="let sectionKey of getJsonKeys(samplejson)">
    <h5>{{ sectionKey }}</h5>
    <div *ngFor="let key of getJsonKeys(samplejson[sectionKey])">
      <strong (click)="onKeyClick(key)" style="cursor: pointer;">
        {{ key }}:{{ samplejson[sectionKey][key] }}
      </strong>
      <ng-container
        *ngIf="samplejson[sectionKey][key] ; else showValue">
        <div *ngFor="let subKey of getJsonKeys(samplejson[sectionKey][key])">
          {{ subKey }}: {{ samplejson[sectionKey][key][subKey] }}
        </div>
      </ng-container>
      <ng-template #showValue>{{ samplejson[sectionKey][key] }}</ng-template>
    </div>
  </div>
</div> -->


<h3 >智能解析結果 for  {{filename}}</h3>
  <pre [innerHTML]="samplejson | formatSDSJson" style="height: 300px;border: 1px solid black ;resize: vertical; overflow: auto;" ></pre>
  <!-- <pre [innerHTML]="samplejson | json" style="height: 300px;border: 1px solid black ;resize: vertical; overflow: auto;"></pre> -->

<div class="search-container mt-3">
  <!-- 左邊區域 -->
  <div class="left-section">
    <input [(ngModel)]="searchTerm" placeholder="輸入搜尋關鍵字" />
    <button (click)="search()">搜尋</button>
    <button (click)="resetHighlights()">重置</button>
  </div>

  <!-- 右邊區域 -->
  <div class="right-section">
    <span *ngIf="totalMatches !== undefined">共找到 {{ totalMatches }} 個結果，目前在第 {{ currentMatchNumber }} 個</span>
    <button *ngIf="totalMatches !== undefined" (click)="findPrevious()" [disabled]="!totalMatches || totalMatches === 0">上一個</button>
    <button *ngIf="totalMatches !== undefined" (click)="findNext()" [disabled]="!totalMatches || totalMatches === 0">下一個</button>
  </div>
</div>

<div class="mt-3">
<ngx-extended-pdf-viewer [textLayer]="true"
(updateFindMatchesCount)="updateFindMatchesCount($event)"
(updateFindState)="updateFindState($event)"
height="600px" [src]="pdfSrc" zoom="100%"></ngx-extended-pdf-viewer>
</div>
