import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatabaseService } from '../../../services/database.service';
import { LogService } from '../../../services/Logging.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// 用戶介面
interface User {
  id?: string;
  username: string;
  password: string;
  email: string;
  fullName: string;
  department: string;
  position: string;
  enabled: boolean;
  createdAt?: Date;
  lastLogin?: Date;
  permissions: Permission[];
}

// 權限介面
interface Permission {
  assistantNo: string;  // AI 助手的唯一識別碼
  permission: string;   // 權限角色: 'Admin', 'Editor', 'Viewer' 等
}

// AI 助手介面
interface Assistant {
  assistantNo: string;
  title: string;
  description?: string;
  icon?: string;
}

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
export class UserManagementComponent implements OnInit {
  users: User[] = [];
  assistants: Assistant[] = [];
  userForm: FormGroup;
  isEditing = false;
  isLoading = false;
  errorMessage = '';
  selectedUser: User | null = null;
  permissionOptions = ['Admin', 'Editor', 'Viewer', 'Limited'];

  constructor(
    private databaseService: DatabaseService,
    private logService: LogService,
    private fb: FormBuilder
  ) {
    this.userForm = this.createUserForm();
  }

  ngOnInit(): void {
    this.isLoading = true;

    // 同時加載用戶和助手資料
    forkJoin({
      users: this.loadUsers(),
      assistants: this.loadAssistants()
    }).subscribe({
      next: (data) => {
        this.users = data.users;
        this.assistants = data.assistants;
        this.isLoading = false;
      },
      error: (error) => {
        this.errorMessage = '載入用戶資料失敗';
        this.isLoading = false;
        console.error('Error loading data:', error);
      }
    });
  }

  // 創建用戶表單
  createUserForm(user?: User): FormGroup {
    return this.fb.group({
      id: [user?.id || ''],
      username: [user?.username || '', [Validators.required, Validators.minLength(3)]],
      password: [user?.password || '', user ? [] : [Validators.required, Validators.minLength(6)]],
      email: [user?.email || '', [Validators.required, Validators.email]],
      fullName: [user?.fullName || '', Validators.required],
      department: [user?.department || ''],
      position: [user?.position || ''],
      enabled: [user?.enabled !== undefined ? user.enabled : true],
      permissions: this.fb.array(
        user?.permissions ?
          user.permissions.map(p => this.createPermissionGroup(p)) :
          []
      )
    });
  }

  // 創建權限表單組
  createPermissionGroup(permission?: Permission): FormGroup {
    return this.fb.group({
      assistantNo: [permission?.assistantNo || '', Validators.required],
      permission: [permission?.permission || 'Viewer', Validators.required]
    });
  }

  // 獲取權限 FormArray
  get permissionsArray(): FormArray {
    return this.userForm.get('permissions') as FormArray;
  }

  // 添加新權限
  addPermission(): void {
    this.permissionsArray.push(this.createPermissionGroup());
  }

  // 移除權限
  removePermission(index: number): void {
    this.permissionsArray.removeAt(index);
  }

  // 載入用戶資料
  loadUsers(): Observable<User[]> {
    const companyid = environment.companyname;
    const filter = { companyid };

    return this.databaseService.getDataByQuery(filter, 1000, "users").pipe(
      map((response: any) => {
        if (!response?.items) {
          return [];
        }
        return response.items.map((item: any) => ({
          id: item.id,
          username: item.username,
          password: item.password || '',
          email: item.email || '',
          fullName: item.fullName || '',
          department: item.department || '',
          position: item.position || '',
          enabled: item.enabled !== undefined ? item.enabled : true,
          createdAt: item.createdAt ? new Date(item.createdAt) : undefined,
          lastLogin: item.lastLogin ? new Date(item.lastLogin) : undefined,
          permissions: item.permissions || []
        }));
      }),
      catchError(error => {
        console.error('Error loading users:', error);
        return of([]);
      })
    );
  }

  // 載入 AI 助手資料
  loadAssistants(): Observable<Assistant[]> {
    const companyid = environment.companyname;
    const filter = { companyid };

    return this.databaseService.getDataByQuery(filter, 1000, "modules").pipe(
      map((response: any) => {
        if (!response?.items?.[0]?.lobby) {
          return [];
        }

        return response.items[0].lobby.map((item: any) => ({
          assistantNo: item.assistantNo,
          title: item.title,
          description: item.description || '',
          icon: item.icon || ''
        }));
      }),
      catchError(error => {
        console.error('Error loading assistants:', error);
        return of([]);
      })
    );
  }

  // 編輯用戶
  editUser(user: User): void {
    this.isEditing = true;
    this.selectedUser = user;
    this.userForm = this.createUserForm(user);
  }

  // 取消編輯
  cancelEdit(): void {
    this.isEditing = false;
    this.selectedUser = null;
    this.userForm = this.createUserForm();
  }

  // 提交用戶表單
  submitUserForm(): void {
    if (this.userForm.invalid) {
      this.markFormGroupTouched(this.userForm);
      return;
    }

    const userData = this.userForm.value as User;

    this.isLoading = true;
    if (this.isEditing && userData.id) {
      // 更新現有用戶
      this.updateUser(userData);
    } else {
      // 創建新用戶
      this.createUser(userData);
    }
  }

  // 創建新用戶
  createUser(userData: User): void {
    const companyid = environment.companyname;
    userData.createdAt = new Date();

    const newUser = {
      ...userData,
      companyid
    };

    // this.databaseService.addData(newUser, 'users').subscribe({
    //   next: (response) => {
    //     this.logService.Loginlog(
    //       companyid,
    //       'UserManagement',
    //       'Admin',
    //       `Created new user: ${userData.username}`,
    //       'INFO'
    //     );

    //     alert('用戶創建成功');
    //     this.loadUsers().subscribe(users => {
    //       this.users = users;
    //       this.isLoading = false;
    //       this.cancelEdit();
    //     });
    //   },
    //   error: (error) => {
    //     console.error('Error creating user:', error);
    //     alert('用戶創建失敗');
    //     this.isLoading = false;
    //   }
    // });
  }

  // 更新用戶
  updateUser(userData: User): void {
    const companyid = environment.companyname;

    // 如果密碼欄位為空，表示不需要更改密碼
    if (!userData.password) {
      const currentUser = this.users.find(u => u.id === userData.id);
      if (currentUser) {
        userData.password = currentUser.password;
      }
    }

    const updatedUser = {
      ...userData,
      companyid
    };

    // this.databaseService.updateData(updatedUser, 'users').subscribe({
    //   next: (response) => {
    //     this.logService.Loginlog(
    //       companyid,
    //       'UserManagement',
    //       'Admin',
    //       `Updated user: ${userData.username}`,
    //       'INFO'
    //     );

    //     alert('用戶更新成功');
    //     this.loadUsers().subscribe(users => {
    //       this.users = users;
    //       this.isLoading = false;
    //       this.cancelEdit();
    //     });
    //   },
    //   error: (error) => {
    //     console.error('Error updating user:', error);
    //     alert('用戶更新失敗');
    //     this.isLoading = false;
    //   }
    // });
  }

  // 刪除用戶
  deleteUser(user: User): void {
    if (!confirm(`確定要刪除用戶 "${user.username}" 嗎？`)) {
      return;
    }

    const companyid = environment.companyname;

    this.isLoading = true;
    // this.databaseService.deleteData(user.id!, 'users').subscribe({
    //   next: () => {
    //     this.logService.Loginlog(
    //       companyid,
    //       'UserManagement',
    //       'Admin',
    //       `Deleted user: ${user.username}`,
    //       'INFO'
    //     );

    //     alert('用戶刪除成功');
    //     this.users = this.users.filter(u => u.id !== user.id);
    //     this.isLoading = false;
    //   },
    //   error: (error) => {
    //     console.error('Error deleting user:', error);
    //     alert('用戶刪除失敗');
    //     this.isLoading = false;
    //   }
    // });
  }

  // 獲取助手名稱
  getAssistantName(assistantNo: string): string {
    const assistant = this.assistants.find(a => a.assistantNo === assistantNo);
    return assistant ? assistant.title : assistantNo;
  }

  // 將表單所有欄位標記為已觸碰，顯示驗證錯誤
  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(c => {
          if (c instanceof FormGroup) {
            this.markFormGroupTouched(c);
          }
        });
      }
    });
  }
}
