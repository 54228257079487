import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl; // API 端點

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  // 檢查使用者是否已登入
  isLoggedIn(): boolean {
    const token = localStorage.getItem('authToken');
    return !!token; // 若 token 存在，返回 true
  }

  // 取得使用者資訊
  getUserInfo() {
    return {
      username: localStorage.getItem('username') || '',
      companyid: localStorage.getItem('companyid') || '',
      modules: JSON.parse(localStorage.getItem('modules') || '[]')
    };
  }

  // 檢查是否有指定模組的權限
  hasModuleAccess(moduleId: string): boolean {
    if (!this.isLoggedIn()) return false;

    const modulesStr = localStorage.getItem('modules');
    if (!modulesStr) return false;

    try {
      const modules = JSON.parse(modulesStr);

      // 檢查格式，若是新格式(物件陣列)
      if (modules.length > 0 && typeof modules[0] === 'object') {
        // 檢查是否有 ALL 權限或指定模組權限
        return modules.some((m: any) =>
          m.assistantNo === 'ALL' || m.assistantNo === moduleId
        );
      }
      // 舊格式(字串陣列)
      else {
        return modules.includes('ALL') || modules.includes(moduleId);
      }
    } catch (e) {
      console.error('Error parsing modules from localStorage:', e);
      return false;
    }
  }

  // 重新導向到登入頁
  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  login(credentials: { username: string; password: string }): Observable<any> {

    const test_account = environment.test_account;

    if (
      credentials.username === test_account &&  credentials.password === test_account
    ) {
      // 模擬成功的回應
      return of({ success: true, token: 'fake-jwt-token' });
    } else {
      // 模擬失敗的回應
      return throwError(() => new Error('Invalid username or password'));
    }
  }

  getJwtToken(username: string): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(
      `${this.apiUrl}/api/auth/token`,
      { username },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
    );
  }

  // 🔹 儲存 Token 到 localStorage
  storeToken(token: string,companyid: string, username: string, modules: string[]) : void{
    localStorage.setItem('authToken', token);
    localStorage.setItem('companyid', companyid);
    localStorage.setItem('username', username);
    localStorage.setItem('modules', JSON.stringify(modules));
  }

  // 🔹 清除 Token (登出)
  clearToken() {
    localStorage.clear();
  }

}
