import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms'; // 新增這行
import { DatabaseService } from '../../../services/database.service';

export interface Photo {
  fileName: string;
  fileUrl: string;
}

@Component({
  selector: 'app-showlist',
  standalone: true,
  imports: [CommonModule, FormsModule,DragDropModule],
  templateUrl: './showlist.component.html',
  styleUrls: ['./showlist.component.scss']
})


export class ShowlistComponent implements OnInit {

  @Input() formName: string = '';

  displayedColumns: string[] = [];
  data: any[] = [];
  companyid='';

  // 搜尋字串
  searchQuery: string = '';
  // 多選搜尋欄位，預設空陣列（代表沒特定選擇，就搜尋全部欄位）
  searchColumns: string[] = [];

  constructor(private databaseService: DatabaseService) {}

  ngOnInit(): void {
    const filter: any = {};
    const pageSize = 100;
    const continuationToken = '';

    this.companyid = localStorage.getItem('companyid') ?? '';
    if (!this.companyid) return;

    filter.companyid = this.companyid;

    if (this.formName) {
      filter.componentname = this.formName;
    }

    this.databaseService.getDataByQuery(filter, pageSize, "form-container")
      .subscribe({
        next: (result) => {
          this.data = result.items;
          console.log('Received data:', this.data);
          // 取得所有欄位（排除系統欄位）
          this.displayedColumns = this.getColumns();
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
        complete: () => {
          console.log('Data fetching complete');
        }
      });
  }

  // 取得資料中所有的欄位
  getColumns(): string[] {
    if (this.data && this.data.length > 0) {
      const allKeys = new Set<string>();
      this.data.forEach(item => {
        Object.keys(item).forEach(key => {
           if (!['id', '_rid', '_self', '_etag', '_attachments', '_ts', 'companyid', 'assistantid', 'assistantname', 'componentname'].includes(key.toLowerCase())) {
            allKeys.add(key);
           }
        });
      });
      return Array.from(allKeys);
    }
    return [];
  }

  // 根據 searchQuery 與 searchColumns 過濾資料
  get filteredData(): any[] {
    if (!this.searchQuery.trim()) {
      return this.data;
    }
    const lowerQuery = this.searchQuery.toLowerCase();

    // 如果有選擇特定搜尋欄位，則只搜尋那些欄位
    if (this.searchColumns.length > 0) {
      return this.data.filter(item =>
        this.searchColumns.some(col =>
          String(item[col] || '').toLowerCase().includes(lowerQuery)
        )
      );
    } else {
      // 沒有選擇特定搜尋欄位，就搜尋所有欄位
      return this.data.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(lowerQuery)
        )
      );
    }
  }

  getSelectedOptions(options: { name: string; value: string; selected: boolean }[]): string {
    return options.filter(option => option.selected)
                  .map(option => option.value)
                  .join('; ');
  }

  // 切換搜尋欄位的選取狀態
  toggleSearchColumn(col: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.searchColumns.includes(col)) {
        this.searchColumns.push(col);
      }
    } else {
      this.searchColumns = this.searchColumns.filter(c => c !== col);
    }
  }

  // 拖拉事件：更新 displayedColumns 的順序
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  convertUnixToLocal(timestamp: string): Date {
    return new Date(Number(timestamp) * 1000);
  }


  parsePhotos(photos: Photo[] | null): Photo[] {
    return photos ?? [];
  }

  getGoogleMapsUrl(location: string): string {
    const parts = location.split(',');
    if (parts.length === 2) {
      const lat = parts[0].trim();
      const lng = parts[1].trim();
      return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    }
    return '#';
  }


  onToggleSearchColumn(col: string, event: Event): void {
    const target = event.target as HTMLInputElement;
    this.toggleSearchColumn(col, target.checked);
  }

  editItem(item: any) {
    console.log('Edit item:', item);
  }

  deleteItem(item: any) {
    console.log('Delete item:', item);
  }

  isObject(val: any): boolean {
    return val !== null && typeof val === 'object' && !Array.isArray(val);
  }

}
