import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TOTAL_COMPONENTS } from '../../items/total-components';

import { DatabaseService } from '../../../services/database.service';
import { FormConfigService } from '../../../services/form-config.service';

// 定義欄位配置的介面
export interface FormFieldConfig {
  type: 'datetime' | 'select' | 'radiobutton' | 'textarea' | 'location' | 'photo-upload' | 'date' | 'signature';
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  options?: any[];
}

@Component({
  selector: 'app-dynamic-form',
  standalone: true,
  // 請根據你的專案補上各通用元件的 import
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...TOTAL_COMPONENTS
  ],
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() formName: string = '';
  @Input() assistantid: string = '';
  @Input() assistantname: string = '';

  @Input() config: FormFieldConfig[] = [];
  @Input() preview: boolean = false;

  @Output() Showlist = new EventEmitter<string>();
  @Output() fillform = new EventEmitter<string>();

  // 從登入資訊取得
  Companyname = "";
  Username = "";
  forminfo: any = {};

  formGroup!: FormGroup;
  formfinished = false;

  // 新增變數，用於編輯 config 的 JSON 字串
  configJSON: string = '';

  showJsonEditor: boolean = false;

  constructor(
    private fb: FormBuilder,
    private databaseService: DatabaseService,
    private formConfigService: FormConfigService
  ) {}

  ngOnInit(): void {
    this.Companyname = localStorage.getItem('companyid') || 'Unknown Company';
    this.Username = localStorage.getItem('username') || 'Unknown User';
    this.assistantid = localStorage.getItem('assistantid') || 'Unknown User';

    this.forminfo = {
      companyid: this.Companyname,
      username: this.Username,
      assistantid: this.assistantid,
      assistantname: this.assistantname,
      componentname: this.formName,
      createAt: {
        type: 'datetime',
        value: Math.floor(Date.now() / 1000)
      }
    };

    if (this.config && this.preview == true) {
      this.initializeForm();
    } else {
      // 透過 formName 與 companyid 取得表單配置
      this.formConfigService.getFormConfig(this.formName, this.forminfo.companyid)
      .subscribe(config => {
        this.config = config;
        this.initializeForm();
      });
    }
  }

  // 根據 config 建立 Reactive Form 並更新 configJSON
  initializeForm(): void {
    const group: { [key: string]: any } = {};
    this.config.forEach(field => {
      group[field.name] = [''];
    });
    this.formGroup = this.fb.group(group);
    // 將 config 轉為 JSON 格式（縮排2個空格），供 textarea 編輯
    this.configJSON = JSON.stringify(this.config, null, 2);
  }

  toggleJsonEditor(): void {
    this.showJsonEditor = !this.showJsonEditor;
  }

  // 解析 configJSON 並重新建立表單
  reloadForm(): void {
    try {
      const newConfig = JSON.parse(this.configJSON);
      this.config = newConfig;
      this.initializeForm();
    } catch (error) {
      console.error('Invalid JSON config:', error);
      alert('Invalid JSON format. Please check your config.');
    }
  }



  onSubmit(): void {
    // 取得所有欄位的值
    const rawData = this.formGroup.value;

    // 根據 config 產生一個 mapping，每個欄位以 { type, value } 儲存
    const fieldsData: { [key: string]: { type: string; value: any } } = {};
    this.config.forEach(field => {
      fieldsData[field.name] = {
        type: field.type,
        value: rawData[field.name]
      };
    });

    // 將 forminfo 與 fieldsData 合併，展開 fieldsData 為頂層屬性
    const dataToSave = {
      ...this.forminfo,
      ...fieldsData
    };

    console.log('送出資料：', dataToSave);

    // 呼叫 API 傳送資料到後端
    this.databaseService.sendDataToBackend(dataToSave, "form-container")
      .then(response => {
        console.log('Response from backend:', response);
        this.formfinished = true;
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  }

  onViewAllData() {
    this.Showlist.emit(this.formName);
  }

  fillnewform(){
    this.fillform.emit(this.formName);
  }

  save() {
    // 建立最終要存的物件
    const dataToSave = {
      companyid: this.Companyname,
      formName: this.formName,
      assistantid: this.assistantid,
      config: this.config.map(field => ({ ...field }))
    };

    console.log('Saving data:', dataToSave);

    // 呼叫 API 保存資料
    this.databaseService.sendDataToBackend(dataToSave, "form-config")
      .then(response => {
        console.log('Response from backend:', response);
        this.formfinished = true;
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  }
}
