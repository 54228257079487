<div class="sds-regulation-container">
  <div class="upload-section">
    <input type="file" (change)="onFileSelected($event)" accept=".pdf">
    <button [disabled]="!selectedFile || isLoading" (click)="parseFile()">
      解析檔案
    </button>
    <!-- <span  class="loading-spinner"></span> -->
  </div>

  <div class="response-section">
    <textarea readonly [value]="apiResponse" rows="10" placeholder="API 回應將顯示在此處..."></textarea>
  </div>

  <div class="regulation-section">
    <button [disabled]="!apiResponse" (click)="checkRegulations()">進行法規判斷</button>
    <div class="regulations-list" *ngIf="regulations.length > 0">
      <h3>適用法規：</h3>
      <ul>
        <li *ngFor="let regulation of regulations">{{ regulation }}</li>
      </ul>
    </div>
  </div>

  <div id="S1"></div>
  <div id="S2"></div>
  <div id="S3"></div>
  <div id="S4"></div>
  <div id="S5"></div>
  <div id="S6"></div>
  <div id="S7"></div>
  <div id="S8"></div>
  <div id="S9"></div>
  <div id="S10"></div>
  <div id="S11"></div>
  <div id="S12"></div>
  <div id="S13"></div>
  <div id="S14"></div>
  <div id="S15"></div>
  <div id="S16"></div>

  <div id="Response"></div>


</div>

