import { Component, OnInit } from '@angular/core';
import * as QRCode from 'qrcode';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-rqcode',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rqcode.component.html',
  styleUrl: './rqcode.component.scss'
})
export class RqcodeComponent {

  qrCodeUrl: string = 'https://quallico.ai/urlredirect?code=auditdemo';
  qrCodeImage: string = '';

  ngOnInit() {
    this.generateQRCode();
  }

  async generateQRCode() {
    try {
      this.qrCodeImage = await QRCode.toDataURL(this.qrCodeUrl);
    } catch (err) {
      console.error('QR Code 產生失敗:', err);
    }
  }
}
