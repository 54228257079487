<div>
  <h2>課程篩選</h2>
  <form [formGroup]="filterForm" (ngSubmit)="fetchData()">
    <label>開始日期:
      <input type="date" formControlName="startDate">
    </label>
    <label>結束日期:
      <input type="date" formControlName="endDate">
    </label>

    <!-- ✅ 新增年份選單 -->
    <label>快速選擇年份:
      <select (change)="setYearRange($event)">
        <option *ngFor="let year of [2025, 2024, 2023, 2022]" [value]="year">{{ year }}</option>
      </select>
    </label>

    <button type="submit" [disabled]="isLoading">
      <span *ngIf="isLoading">加載中...</span>
      <span *ngIf="!isLoading">查詢</span>
    </button>
  </form>

  <!-- ✅ 只有當資料載入完成後，才顯示 -->
  <div *ngIf="dataLoaded">
    <h2>課程統計</h2>
    <div echarts [options]="chartOption" (chartClick)="onChartClick($event)" style="width: 100%; height: 400px;"></div>
  </div>

  <div *ngIf="selectedYM && selectedCState">

  <h3 >
    詳細課程 - {{ selectedYM }} ({{ selectedCState }}) | 課程堂數:{{classcount}} |   預期營業額: {{ formatNumber(expectedRevenue) }} 元
  </h3>

  <div class="button-div">
  <button *ngIf="filteredClasses.length > 0" (click)="filterByStudentCount('<=15')">≤ 15</button>
  <button *ngIf="filteredClasses.length > 0" (click)="filterByStudentCount('>15')">> 15</button>
  <button (click)="filterClear()">Clear</button>
   </div>

  <table *ngIf="filteredClasses.length > 0">
    <thead>
      <tr>
        <th>#</th>
        <th>課程編號</th>
        <th>類別</th>
        <th>課程名稱</th>
        <th>開始日期</th>
        <th>結束日期</th>
        <th>狀態</th>
        <th>報名人數</th>
        <th>原始費用</th>
        <th>解析後的課程費用</th>
        <th>預期收益</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cls of filteredClasses; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ cls.CNo }}</td>
        <td>{{ cls.Type }}</td>
        <td>{{ cls.CName }}</td>
        <td>{{ cls.SDate }}</td>
        <td>{{ cls.EDate }}</td>
        <td>{{ cls.CState }}</td>
        <td>{{ cls.StudentCount }}</td>
        <td>{{ cls.Money }}</td>
        <td>{{ cls.newMoney | number:'1.0-0' }} 元</td>
        <td>{{ cls.totalRevenue | number:'1.0-0' }} 元</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
