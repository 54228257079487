<div class="component-container">
  <div class="header">
    <h1>電腦測驗 - 線上模擬系統</h1>
    <h3>【非正式考試，僅供練習使用】</h3>
    <div class="exam-info">
      <div class="exam-info-left">
        <div>學科：{{ classname }}</div>
        <div>考生姓名：TPIC</div>
        <div>考生編號：113N99900400000</div>
      </div>
      <div class="exam-info-right">
        <div class="clock">倒數時間：{{ remainingTime }}</div>
        <div>
          已答題數：
          <span [ngStyle]="{'color': answeredCount > 0 ? 'blue' : 'black'}">
            {{ answeredCount }}
          </span>
          題 (共 {{ questions.length }} 題)
        </div>

      </div>
    </div>
  </div>
  <div>
    <div class="question-navigation">
      <button (click)="prevQuestion()" [disabled]="currentQuestionIndex === 0">上一題</button>
      <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questions.length - 1">下一題</button>
      <div class="jump-to">
        <label for="jump">跳到題目：</label>
        <select id="jump" [(ngModel)]="jumpToIndex" (change)="jumpToQuestion()">
          <option *ngFor="let question of questions; let i = index" [value]="i">
            第 {{ i + 1 }} 題
          </option>
        </select>
      </div>
    </div>
    <div class="question">
      <h2>第 {{ currentQuestionIndex + 1}} 題</h2>
      <p>{{ questions[currentQuestionIndex].text }}</p>
      <ul>
        <li *ngFor="let option of questions[currentQuestionIndex].options; let i = index"
        [ngClass]="{
          'correct-answer': showResult && questions[currentQuestionIndex].correctAnswerIndex.includes(i),
          'wrong-answer': showResult && questions[currentQuestionIndex].selected === option && !questions[currentQuestionIndex].correctAnswerIndex.includes(i)
        }"
        >
          <input
            type="radio"
            id="option-{{ currentQuestionIndex }}-{{ i }}"
            name="option-{{ currentQuestionIndex }}"
            [value]="option"
            (change)="selectAnswer(option)"
            [checked]="questions[currentQuestionIndex].selected === option"
          />
          <label for="option-{{ currentQuestionIndex }}-{{ i }}">
            {{ option }}
          </label>
        </li>
      </ul>
    </div>

    <div class="button-container">
    <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questions.length - 1">下一題</button>
    <button (click)="submitAnswers()">提交答案</button>
    </div>
     <!-- <div class="submit-section"> </div> -->

  </div>

  <div class="result" *ngIf="showResult">
    <h3>答題結果</h3>
    <ul>
      <li *ngFor="let question of questions; let i = index">
        <a  (click)="goToQuestion(i)">
          第 {{ i + 1 }} 題
        </a>
        -
        <span [ngStyle]="{ color: question.isCorrect ? 'green' : 'red' }">
          {{ question.isCorrect ? '正確' : '錯誤' }}
        </span>

        <a *ngIf="!question.isCorrect" (click)="goToExplan(i)"> 【進階說明】 </a>

      </li>
    </ul>
  </div>

</div>
