import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { LoginService } from '../../services/login/login.service';
import { LogService } from '../../services/Logging.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, RouterModule], // 導入必要的模組
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  login_title = '';
  logoUrl = '';
  copyright = '';
  version = '';
  clientId = '';
  companyname = '';

  isSubmitting = false;

  // Login 頁面的文字顯示
  title_text = environment.login_page_setting.title_text || 'Login';
  id_text = environment.login_page_setting.id_text || 'ID';
  pw_text = environment.login_page_setting.pw_text || 'Password';
  login_text = environment.login_page_setting.login_text || 'Login';
  forget_text =
    environment.login_page_setting.forget_text || 'Forgot Password?';

  constructor(
    private fb: FormBuilder,
    private router: Router, // 注入 Router
    private loginService: LoginService, // 使用自定義的 LoginService
    private LogService: LogService,
    private authService: AuthService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    // 載入設定檔
    this.logoUrl = environment.Login_logoUrl;
    this.copyright = environment.copyright;
    this.login_title = environment.Login_title;
    this.version = environment.version;
    this.clientId = environment.clientId;
    this.companyname = environment.companyname;
  }

  async onSubmit() {
    if (this.loginForm.valid) {
      this.isSubmitting = true; // 按鈕進入 disabled 狀態
      const loginData = this.loginForm.value;

      try {
        const result = await this.loginService.login(this.clientId, loginData);


        if (result.success && result.username && result.modules) {

          this.authService.storeToken(
            "JWTToken_Mock",
            this.companyname,
            result.username,
            result.modules
          );

          // ✅ 記錄登入日誌
          this.LogService.Loginlog(
            this.clientId,
            'Login',
            result.username,
            'User logged in successfully',
            'INFO'
          );

          // ✅ 導向主頁
          this.router.navigate(['/lobby']);


          // // 2️⃣ 帳密驗證成功，請求 JWT Token
          // this.authService.getJwtToken(result.username).subscribe({
          //   next: (tokenData) => {
          //     if (!tokenData || !tokenData.accessToken) {
          //       throw new Error('Invalid JWT Token response');
          //     }

          //     // 3️⃣ 儲存 Token
          //     this.authService.storeToken(
          //       tokenData.accessToken,
          //       this.companyname,
          //       result.username,
          //       result.modules
          //     );

          //     // ✅ 記錄登入日誌
          //     this.LogService.Loginlog(
          //       this.clientId,
          //       'Login',
          //       result.username,
          //       'User logged in successfully',
          //       'INFO'
          //     );

          //     // ✅ 導向主頁
          //     this.router.navigate(['/lobby']);
          //   },
          //   error: (error) => {
          //     console.error('Failed to retrieve JWT Token', error);
          //     alert('Failed to retrieve JWT Token');
          //   }
          // });

        } else {
          this.LogService.Loginlog(
            this.clientId,
            'Login',
            loginData.username,
            'Invalid username or password',
            'WARNING'
          );

          alert('Invalid username or password');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        if (error instanceof Error) {
          errorMessage = error.message;
        }

        this.LogService.Loginlog(
          this.clientId,
          'Error',
          'unknown',
          'Login failed due to server error',
          'ERROR'
        );

        console.error('Login failed:', error);
        alert('An error occurred during login.');
      } finally {
        this.isSubmitting = false; // 無論成功或失敗，恢復按鈕可用
      }
    }
  }
}
