import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,  // 標明這是一個 standalone 組件
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, RouterModule]  // 導入必要的模組
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  login_title = "";
  logoUrl = "";
  copyright = "";
  version = "";


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router  // 注入 Router
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    // 載入設定檔
      this.logoUrl = environment.Login_logoUrl;
      this.copyright = environment.copyright;
      this.login_title = environment.Login_title
      this.version = environment.version;

  }

  onSubmit() {
    if (this.loginForm.valid) {
      const loginData = this.loginForm.value;
      this.authService.login(loginData).subscribe(
        response => {
          // console.log('Login successful', response);
          // 儲存 Token 到 localStorage
          localStorage.setItem('authToken', response.token);
          // 導向 Assistants 頁面
          this.router.navigate(['/lobby']);
        },
        error => {
          alert("Invalid username or password");
          console.error('Login failed', error);
        }
      );
    }
  }
}
