import { Component, Input, OnInit } from '@angular/core';
import { BarChartComponent } from '../reports/bar-chart/BarChart.component';
import { CommonModule } from '@angular/common';
import { TOTAL_COMPONENTS } from '../reports/report-total-components';

export interface ChartConfig {
  type: 'bar' | 'pie' | 'graph' | 'map'; // Add 'map' type
  title: string;
  data?: any[]; // Make data optional
  labels?: any[]; // Make labels optional
  options: {};
  nodes?: { id: string; name: string; symbolSize: number }[]; // Define nodes for graph type
  links?: { source: string; target: string; value: number }[]; // Define links for graph type
  mapType?: string; // Add mapType for displaying different maps
  mapData?: any[]; // Add mapData for displaying regions on the map
  lat?: number; // Latitude for map center
  lng?: number; // Longitude for map center
  zoom?: number; // Zoom level for the map
}

@Component({
  selector: 'app-dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.scss'],
  standalone: true,
  imports: [CommonModule, ...TOTAL_COMPONENTS]
})
export class DynamicReportComponent implements OnInit {
  @Input() reportConfig: ChartConfig[] = [];  // 接收的報告配置

  constructor() {}

  ngOnInit(): void {
    this.reportConfig = [
      {
        type: 'bar',
        title: 'Sales Data',
        data: [
          [10, 20, 30, 40, 50], // Data for series 1
          [5, 15, 25, 35, 45]   // Data for series 2
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
        options: {}
      },
      {
        type: 'pie',
        title: 'Revenue Distribution',
        data: [45, 25, 15, 10, 5],
        labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
        options: {}
      },
      {
        type: 'graph',  // Show graph chart
        title: 'Social Network Graph',
        nodes: [
          { id: '1', name: 'Alice', symbolSize: 40 },
          { id: '2', name: 'Bob', symbolSize: 50 },
          { id: '3', name: 'Charlie', symbolSize: 60 },
          { id: '4', name: 'David', symbolSize: 30 },
          { id: '5', name: 'Eva', symbolSize: 70 },
          { id: '6', name: 'Frank', symbolSize: 55 },
          { id: '7', name: 'Grace', symbolSize: 65 },
          { id: '8', name: 'Hannah', symbolSize: 45 },
          { id: '9', name: 'Ivy', symbolSize: 50 },
          { id: '10', name: 'Jack', symbolSize: 40 }
        ],
        links: [
          { source: '1', target: '2', value: 100 },
          { source: '1', target: '3', value: 80 },
          { source: '2', target: '4', value: 50 },
          { source: '2', target: '5', value: 30 },
          { source: '3', target: '6', value: 90 },
          { source: '4', target: '7', value: 40 },
          { source: '5', target: '8', value: 60 },
          { source: '6', target: '9', value: 20 },
          { source: '7', target: '10', value: 30 },
          { source: '8', target: '1', value: 70 },
          { source: '9', target: '3', value: 85 },
          { source: '10', target: '4', value: 45 },
          { source: '1', target: '6', value: 65 },
          { source: '2', target: '7', value: 75 },
          { source: '5', target: '9', value: 50 },
          { source: '4', target: '8', value: 55 },
          { source: '3', target: '7', value: 40 }
        ],
        options: {
          layout: 'force',
          roam: true,  // Allow zoom and drag
          edgeSymbol: ['none', 'arrow'],  // Arrowheads on links
          edgeSymbolSize: [4, 10],
          lineStyle: {
            color: 'source',
            curveness: 0.3
          }
        }
      },
      {
        type: 'map', // 顯示地圖
        title: 'Japan Map',
        mapType: 'Japan',  // 可選項，如果是 undefined 則默認為 Japan
        mapData: [
          { name: 'Tokyo', lat: 35.6762, lng: 139.6503, value: 500 },
          { name: 'Osaka', lat: 34.6937, lng: 135.5023, value: 300 }
        ],
        zoom: 5,  // 默認縮放
        options: {}
      },
    ];
  }
}
