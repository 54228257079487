import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, AssistantDataItem } from '../../services/data.service';
import { environment } from '../../environments/environment';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-lobby',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './lobby.component.html',
  styleUrl: './lobby.component.scss',
})
export class LobbyComponent implements OnInit {
  AssistantDataItem: AssistantDataItem[] = [];

  constructor(
    private dataService: DataService,
    private router: Router, // 注入 Router
    private renderer: Renderer2
  ) {}

  companyname = environment.name;

  ngOnInit() {
    // 使用 DataService 取得資料
    this.dataService.getLobbyDataItems(this.companyname).subscribe((items) => {
      this.AssistantDataItem = items;
    });

    console.log('lobby component loaded');

    const script = this.renderer.createElement('script');
    script.src =  '../../components/widget/chat-widget.js';
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.body, script);

    console.log('chat-widget loaded');

  }

  gotoAI(assistantid: string) {
    //另開分頁到AI助手
    this.router.navigate(['/assistant'], {
      queryParams: { id: assistantid },
    });
  }
}
