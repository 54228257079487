<div class="component-container">
  <h3>新增稽核缺失</h3>

  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">


      <app-form-datetime label="稽核日期" id="report-time" name="report-time" [(ngModel)]="formData.reportTime">
      </app-form-datetime>


      <app-form-select label="稽核人員名稱" id="auditor" name="auditor" placeholder="請輸入稽核人員名稱" [options]="auditorlist"
        [(ngModel)]="formData.auditor">
      </app-form-select>



      <app-form-select label="稽核車站" id="Stations" name="Stations" placeholder="稽核車站" [options]="Stations"
        [(ngModel)]="formData.stations">
      </app-form-select>

      <app-form-select label="稽核項目" id="responsible-unit" name="responsible-unit" placeholder="稽核項目"
        [options]="DepartOptions" [(ngModel)]="formData.responsibleUnit">
      </app-form-select>


      <!-- <app-form-radiobutton name="item-options" [options]="radioOptions" [(ngModel)]="formData.selectedOption"
        (clearRemarks)="formData.remarks = ''"></app-form-radiobutton> -->

        <app-form-radiobutton
  label="請選擇符合度"
  [options]="radioOptions"
  [(ngModel)]="formData.selectedOption"
  (clearRemarks)="formData.remarks = ''"
  [ngModelOptions]="{standalone: true}"
></app-form-radiobutton>


      <app-form-multi-checkbox label="缺失類別" [(ngModel)]="formData.workCategories" [ngModelOptions]="{standalone: true}">
      </app-form-multi-checkbox>

      <app-form-textarea label="缺失描述" [(ngModel)]="formData.suggestionContent" id="suggestion-content"
        name="suggestion-content" placeholder="請填寫缺失事項">
      </app-form-textarea>




      <app-form-location label="稽核地點 (GPS 定位)" [(ngModel)]="formData.auditLocation" id="audit-location"
        name="audit-location" placeholder="請輸入稽核地點">
      </app-form-location>


      <app-form-photo-upload [(ngModel)]="formData.photos" label="缺失照片 (可上傳多張，並可刪除)" id="before-photo"
        name="before-photo">
      </app-form-photo-upload>

      <app-form-textarea label="建議改善方式" [(ngModel)]="formData.improvementStatus" id="improvement-status"
        name="improvement-status" placeholder="請填寫建議改善方式">
      </app-form-textarea>



      <app-form-date label="限期改善" [(ngModel)]="formData.dueDate" id="dueDate" name="dueDate" placeholder="請選擇限期改善日期">
      </app-form-date>

      <app-form-signature label="現場負責人簽名" id="ownerSignatureCanvas" [(ngModel)]="formData.ownerSignature">
      </app-form-signature>

      <button type="submit">提交</button>
    </form>

  </div>

  <div *ngIf="formfinished">
    表單填寫完成!
    <button style="margin-left:5px" class="small-button" (click)="onViewAllData()">檢視所有資料</button>
  </div>
</div>
