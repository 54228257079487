import { AuthenticationStrategy, AuthenticationResult } from './authentication-strategy.interface';
import { environment } from '../../environments/environment';

interface Module {
  moduleId: string[];
  moduleName: string;
  assistantNo: string;
}

//設定課程對應的地方

const modules: Module[] = [
  {
    "moduleId": ["CA"],
    "moduleName": "營造業甲種職業安全衛生業務主管",
    "assistantNo": "3e9b4d5c-8f2a-4f0a-b9d6-1e7b9a3c2d1f"
  },
  {
    "moduleId": ["CB"],
    "moduleName": "營造業乙種職業安全衛生業務主管",
    "assistantNo": "5a7c8e9f-1b2d-4c3e-8f0a-6b7d8c9e1f2a"
  },
  {
    "moduleId": ["CV"],
    "moduleName": "營造業丙種職業安全衛生業務主管",
    "assistantNo": "8f1b2c3d-4e5f-6a7b-8c9d-0e1f2a3b4c5d"
  },
  {
    "moduleId": ["A"],
    "moduleName": "甲種職業安全衛生業務主管",
    "assistantNo": "1d2c3b4a-5e6f-7a8b-9c0d-1e2f3a4b5c6d"
  },
  {
    "moduleId": ["AJ"],
    "moduleName": "乙種職業安全衛生業務主管",
    "assistantNo": "9a8b7c6d-5e4f-3a2b-1c0d-9e8f7a6b5c4d"
  },
  {
    "moduleId": ["V"],
    "moduleName": "丙種職業安全衛生業務主管",
    "assistantNo": "a1b2c3d4-e5f6-7a8b-9c0d-1e2f3a4b5c6d"
  },
  {
    "moduleId": ["C"],
    "moduleName": "職業安全衛生管理員",
    "assistantNo": "f0e1d2c3-b4a5-6f7e-8d9c-0b1a2c3d4e5f"
  },
  {
    "moduleId": ["B", "BA"],
    "moduleName": "職業安全管理師",
    "assistantNo": "2a3b4c5d-6e7f-8a9b-0c1d-2e3f4a5b6c7d"
  },
  {
    "moduleId": ["AD", "AD130"],
    "moduleName": "職業衛生管理師",
    "assistantNo": "7d6c5b4a-3f2e-1d0c-9b8a-7f6e5d4c3b2a"
  },
  {
    "moduleId": ["J"],
    "moduleName": "粉塵作業主管",
    "assistantNo": "4e5f6a7b-8c9d-0e1f-2a3b-4c5d6e7f8a9b"
  },
  {
    "moduleId": ["H"],
    "moduleName": "缺氧作業主管",
    "assistantNo": "b1c2d3e4-f5a6-7b8c-9d0e-1f2a3b4c5d6e"
  },
  {
    "moduleId": ["AS"],
    "moduleName": "露天開挖作業主管",
    "assistantNo": "c3d4e5f6-7a8b-9c0d-1e2f-3a4b5c6d7e8f"
  },
  {
    "moduleId": ["AK"],
    "moduleName": "模板支撐作業主管",
    "assistantNo": "d4e5f6a7-b8c9-0d1e-2f3a-4b5c6d7e8f90"
  },
  {
    "moduleId": ["Q"],
    "moduleName": "堆高機操作人員",
    "assistantNo": "e5f6a7b8-c9d0-1e2f-3a4b-5c6d7e8f9012"
  },
  {
    "moduleId": ["W"],
    "moduleName": "鋼構組配作業主管",
    "assistantNo": "f6a7b8c9-d0e1-2f3a-4b5c-6d7e8f901234"
  },
  {
    "moduleId": ["F"],
    "moduleName": "有機溶劑作業主管",
    "assistantNo": "a7b8c9d0-e1f2-3a4b-5c6d-7e8f90123456"
  },
  {
    "moduleId": ["AT"],
    "moduleName": "屋頂作業主管",
    "assistantNo": "b8c9d0e1-f2a3-4b5c-6d7e-8f9012345678"
  },
  {
    "moduleId": ["AF"],
    "moduleName": "擋土支撐作業主管",
    "assistantNo": "c9d0e1f2-a3b4-5c6d-7e8f-90123456789a"
  },
  {
    "moduleId": ["AG"],
    "moduleName": "施工架組配作業主管",
    "assistantNo": "d0e1f2a3-b4c5-6d7e-8f90-123456789abc"
  },
  {
    "moduleId": ["I"],
    "moduleName": "特定化學物質作業主管",
    "assistantNo": "e1f2a3b4-c5d6-7e8f-9012-3456789abcde"
  },
  {
    "moduleId": ["AH"],
    "moduleName": "隧道等挖掘作業主管",
    "assistantNo": ""
  },
  {
    "moduleId": ["AI"],
    "moduleName": "隧道等襯砌作業主管",
    "assistantNo": ""
  },
  {
    "moduleId": ["AM"],
    "moduleName": "高壓氣體製造安全主任",
    "assistantNo": ""
  },
  {
    "moduleId": ["AN"],
    "moduleName": "高壓氣體製造安全作業主管",
    "assistantNo": "a3b4c5d6-e7f8-9012-3456-789abcdef012"
  },
  {
    "moduleId": ["AO"],
    "moduleName": "高壓氣體供應及消費作業主管",
    "assistantNo": "f2a3b4c5-d6e7-8f90-1234-56789abcdeff"
  },
  {
    "moduleId": ["G"],
    "moduleName": "鉛作業主管",
    "assistantNo": ""
  },
  {
    "moduleId": ["VA"],
    "moduleName": "丁種職業安全衛生業務主管",
    "assistantNo": ""
  }
]
;


export class TpicAuthenticationStrategy implements AuthenticationStrategy {


  private accounts = [
    { username: 'tpic', password: 'tpic8888', modules: ['ALL'] },
    // 可以在這裡繼續增加更多帳號、密碼及模組
  ];

  async authenticate(credentials: { username: string; password: string }): Promise<AuthenticationResult> {


    // 檢查帳號和密碼是否匹配
    const account = this.accounts.find(acc => acc.username === credentials.username && acc.password === credentials.password);

    if (account) {
      // 如果找到匹配的帳號和密碼，回傳成功並帶有對應的模組
      return {
        success: true,
        username: credentials.username,
        modules: account.modules,
      };
    } else {
      try {
        // 呼叫 Azure Function 驗證
        const response = await fetch(
          `https://yuhsinefunctions.azurewebsites.net/api/Login?code=7TfeFpRT3foa0sGTD3J/l0Q0Cnwy//3J4JDPXWmfRaaaJ7xa6PV9FA==&id=${credentials.username}&pw=${credentials.password}`
        );
        const data = await response.json();

        // 若回傳空陣列，代表帳號密碼不存在
        if (!Array.isArray(data) || data.length === 0) {
          return {
            success: false,
          };
        }

        // 若回傳的有值
        // 取第一筆的 Name 當 username
        const username = data[0].Name;

        // console.log(data);

        // 將所有筆資料的 MNo 收集成陣列，可視需要去重 (new Set) 或過濾 null
        const modulesMNo = data.map((item: any) => item.MNo).filter((mNo: string) => mNo);

        // console.log("modulesMNo",modulesMNo);

        // const moduleNames = modulesMNo.map(mNo => {
        //   // 在 modules 陣列中找出與 mNo 相符的 moduleId
        //   const module = modules.find(item => item.moduleId === mNo);
        //   return module ? module.moduleName : null;  // 若找不到對應的 moduleId, 回傳 null
        // }).filter(name => name !== null);  // 過濾掉 null

        const moduleids = Array.from(
          new Set(
            modulesMNo.map(mNo => {
              // `some()` 檢查 moduleId 陣列中是否包含 mNo
              const module = modules.find(item => item.moduleId.includes(mNo));
              return module ? module.assistantNo : null;
            }).filter(name => name !== null) // 過濾掉 null
          )
        );


        // 這裡你可以修改為從模組名稱來設定正確的權限
        const accessibleModules = moduleids.length ? moduleids : [""];

        // console.log("accessibleModules",accessibleModules);

        return {
          success: true,
          username,
          modules: accessibleModules,
        };
      } catch (error) {
        console.error('Login API error:', error);
        return {
          success: false,
        };
      }
    }
  }

}
