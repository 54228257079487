
<div class="container min-vh-100 chat-main-container" style="border: 1px solid lightgray">
  <!-- Content here -->
  <div class="row">
    <div class="col-1">



    </div>
    <div class="col-10">
      <div class="logo-container">
        <!-- <img class="q-logo" [src]="logoUrl" [alt]="name" /> -->
      </div>


      <div>
        <!-- <button routerLink="/assitant">Assistants</button>
        <button routerLink="/filebank">Filebank</button> -->

    </div>

      <router-outlet></router-outlet>

    </div>

    <div class="col-1">



    </div>

  </div>
</div>

