import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-url-redirect',
  standalone: true,
  imports: [],
  templateUrl: './url-redirect.component.html',
  styleUrl: './url-redirect.component.scss'
})
export class UrlRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  // https://quallico.ai/urlredirect?code=auditdemo
  // http://localhost:4200/assistant?id=4&chattarget=assistant&message=demoformcomponent
  redirectMapping = [
    { code: "123", targetUrl: "assistant?id=6&chattarget=assistant&message=承攬商施工申請單" },
    { code: "auditdemo", targetUrl: "assistant?id=8&chattarget=assistant&message=demoformcomponent" },
    // 可以加入更多的映射項
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];

      // console.log(code);

      if (code) {
        // 定義映射表

        // 查找目標 URL
        const target = this.redirectMapping.find(item => item.code === code);

        if (target) {
          // 找到對應的 targetUrl，進行重定向
          window.location.href = target.targetUrl;
        } else {
          // 找不到對應的 ID，可以給予錯誤提示或其他處理
          console.error('Mapping not found for the given ID');
          this.router.navigate(['/login']); // 重定向到登入頁面
        }
      } else {
        // 沒有 ID 參數的情況
        console.error('Missing ID parameter');
        this.router.navigate(['/login']); // 重定向到登入頁面
      }
    });
  }

}
