import { Injectable } from '@angular/core';
import { AuthenticationStrategyFactory } from './authentication-strategy-factory';
import { AuthenticationResult } from './authentication-strategy.interface';
import { DatabaseService } from '../database.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private databaseService: DatabaseService) {}

  async login(clientId: string, credentials: { username: string; password: string }): Promise<AuthenticationResult> {
    const strategy = AuthenticationStrategyFactory.getStrategy(clientId, this.databaseService);
    return await strategy.authenticate(credentials);
  }
}
