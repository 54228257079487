import { SafeHtml } from '@angular/platform-browser';
import { DemoformComponent } from '../conponents/form/demoform/demoform.component';
import { Demoform2Component } from '../conponents/form/demoform2/demoform2.component';
import { NonConformanceComponent } from '../conponents/form/non-conformances/non-conformances.component';
import { ActionsComponent } from '../conponents/form/actions/actions.component';

import { SimulateExamComponent } from '../conponents/exam/simulate-exam/simulate-exam.component';

import { ShowlistComponent } from '../conponents/list/showlist/showlist.component';

import { ForkliftChecklistBydayComponent } from '../conponents/checklist/forklift-checklist-byday/forklift-checklist-byday.component';

import { FiledownloaderComponent } from '../conponents/tools/filedownloader/filedownloader.component';
import { PdfHelperComponent } from '../conponents/tools/pdf-helper/pdf-helper.component';
import { RqcodeComponent } from '../conponents/tools/rqcode/rqcode.component';

import { SafetyandHealthAgreementOrganizationMeetingComponent } from './ContractorManagement/safetyand-health-agreement-organization-meeting/safetyand-health-agreement-organization-meeting.component';
import { ContractorConstructionApplicationFormComponent } from './ContractorManagement/contractor-construction-application-form/contractor-construction-application-form.component';
import { FireWorkPermissionComponent } from './ContractorManagement/fire-work-permission/fire-work-permission.component';
import { CorrectiveActionFormComponent } from './ContractorManagement/corrective-action-form/corrective-action-form.component';
import { ContractorAnnualPerformanceComponent } from './ContractorManagement/contractor-annual-performance/contractor-annual-performance.component';
import { HazardNotificationandInspectionRecordComponent } from './ContractorManagement/hazard-notificationand-inspection-record/hazard-notificationand-inspection-record.component';

import { LineChartComponent } from '../conponents/chart/line-chart/line-chart.component';
import { CalendarPieComponent } from '../conponents/chart/calendar-pie/calendar-pie.component';

import { PdfViewerComponent } from '../conponents/tools/pdf-viewer/pdf-viewer.component';

import { YhAllclassComponent } from './yh/yh-allclass/yh-allclass.component';
import { YhAllclassTrendComponent } from './yh/yh-allclass-trend/yh-allclass-trend.component';

import { DynamicFormComponent } from './dynamic-form/dynamic-form/dynamic-form.component';
import { DynamicReportComponent } from './dynamic-report/dynamic-report/dynamic-report.component';

import { FormListTableComponent } from './form-list/form-list-table/form-list-table.component';
import { AzureBlobExplorerComponent } from './azure-blob-explorer/azure-blob-explorer.component';
import { RagTestComponent } from './rag-test/rag-test.component';

import { FilebankComponent } from '../pages/filebank/filebank.component';
import { SdsParserDialogComponent } from '../conponents/sds-parser-dialog/sds-parser-dialog.component';
import { SdsRegulationCheckComponent } from './sds-regulation-check/sds-regulation-check.component';

import { LoglistComponent } from '../conponents/log/loglist/loglist.component';
import { UserManagementComponent } from '../conponents/admin/user-management/user-management.component';
import { AssistantManagementComponent } from '../conponents/admin/assistant-management/assistant-management.component';


export const COMPONENT_MAP: { [key: string]: any } = {
  demoformcomponent: DemoformComponent,
  demoform2component: Demoform2Component,
  filedownloader: FiledownloaderComponent,
  nonConformanceComponent: NonConformanceComponent,
  showlistComponent: ShowlistComponent,
  lineChartComponent: LineChartComponent,
  forkliftChecklistBydayComponent: ForkliftChecklistBydayComponent,
  pdfHelperComponent: PdfHelperComponent,
  actionsComponent: ActionsComponent,
  SafetyandHealthAgreementOrganizationMeetingComponent:
    SafetyandHealthAgreementOrganizationMeetingComponent,
  ContractorConstructionApplicationFormComponent:
    ContractorConstructionApplicationFormComponent,
  FireWorkPermissionComponent: FireWorkPermissionComponent,
  CorrectiveActionFormComponent: CorrectiveActionFormComponent,
  ContractorAnnualPerformanceComponent: ContractorAnnualPerformanceComponent,
  HazardNotificationandInspectionRecordComponent:
    HazardNotificationandInspectionRecordComponent,
  CalendarPieComponent: CalendarPieComponent,
  PdfViewerComponent: PdfViewerComponent,
  RqcodeComponent: RqcodeComponent,

  simulateExamComponent: SimulateExamComponent,

  YhAllclassComponent: YhAllclassComponent,
  YhAllclassTrendComponent: YhAllclassTrendComponent,
  DynamicFormComponent: DynamicFormComponent,
  DynamicReportComponent: DynamicReportComponent,
  FormListTableComponent: FormListTableComponent,
  AzureBlobExplorerComponent: AzureBlobExplorerComponent,
  RagTestComponent: RagTestComponent,
  FilebankComponent: FilebankComponent,
  SdsRegulationCheckComponent: SdsRegulationCheckComponent,
  SdsParserDialogComponent: SdsParserDialogComponent,
  loglistComponent: LoglistComponent,
  userManagementComponent: UserManagementComponent,
  assistantManagementComponent: AssistantManagementComponent,
};
