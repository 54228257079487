<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="fetchData()">
    <label>開始日期:
      <input type="date" formControlName="startDate">
    </label>
    <label>結束日期:
      <input type="date" formControlName="endDate">
    </label>

    <label>快速選擇年份:
      <select (change)="setYearRange($event)">
        <option *ngFor="let year of [2025, 2024, 2023, 2022]" [value]="year">{{ year }}</option>
      </select>
    </label>

    <button type="submit" [disabled]="isLoading">
      <span *ngIf="isLoading">加載中...</span>
      <span *ngIf="!isLoading">查詢</span>
    </button>
  </form>
</div>

<div *ngIf="dataLoaded" class="table-container">
  <h2>課程開課趨勢</h2>
  <table>
    <thead>
      <tr>
        <th class="course-name-header" (click)="selectCourse(null)">課程名稱</th>
        <th>總數</th>
        <th>開課數</th>
        <th>開課率</th>
        <th *ngFor="let month of months" (click)="selectMonth(month)">
          {{ month }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mName of courseNames">
        <td class="course-name" (click)="selectCourse(mName)">
          {{ mName }}
        </td>
        <td>{{ courseStats[mName].totalCount || 0 }}</td>
        <td>{{ courseStats[mName].confirmedCount || 0 }}</td>
        <td>{{ courseStats[mName].openRate || 0 }}%</td>
        <td *ngFor="let month of months"
            [ngClass]="getCellClass(mName, month)"
            [matTooltip]="getTooltip(mName, month)"
            matTooltipPosition="above">
          {{ formattedData[mName][month]?.count || '' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
