import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import axios from 'axios';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-rag-test',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './rag-test.component.html',
  styleUrls: ['./rag-test.component.scss']
})
export class RagTestComponent {
  // 使用者輸入的問題與最終的回答
  question: string = '';
  answer: string = '';

  vectorSearchdata: any[] = [];

  // 前端僅需要 indexName，其他 Azure Cognitive Search 設定已移到後端
  private indexName = "vector-1740713708865"; // 從前端傳入後端的索引名稱

  // Azure OpenAI 相關設定（直接從前端呼叫 Embedding 與 Chat API）
  private openAiEndpoint = "https://quallico-opneai.openai.azure.com";
  private openAiApiKey = "9FT4acRtdkVVRxPOH0kh3mjG5hdJ59dlzPq0GDIFZAuovvZY5LzQJQQJ99BBACYeBjFXJ3w3AAABACOGEwKF";
  private embeddingModelName = "text-embedding-ada-002"; // 部署 Embedding 模型的名稱
  private chatModelName = "gpt-4o-mini"; // 部署 Chat 模型的名稱

  constructor() {}

  /**
   * 呼叫 Azure OpenAI Embedding API 將文字轉成向量
   */
  async getEmbedding(text: string): Promise<number[]> {

    console.log("getEmbedding:",text)

    const url = `${this.openAiEndpoint}/openai/deployments/${this.embeddingModelName}/embeddings?api-version=2023-03-15-preview`;
    try {
      const response = await axios.post(url, { input: text }, {
        headers: {
          "Content-Type": "application/json",
          "api-key": this.openAiApiKey
        }
      });


      var result = response.data.data[0].embedding;

      console.log(result);

      return result
    } catch (error) {
      console.error("Error in getEmbedding", error);
      throw error;
    }
  }

  /**
   * 呼叫後端 API 進行向量搜尋
   * 前端傳送向量、k 與 indexName，後端會呼叫 Azure Cognitive Search
   */
  async vectorSearch(queryEmbedding: number[], k: number = 3): Promise<any[]> {
    // 使用 environment.apiUrl 作為基底 URL
    const url = `${environment.apiUrl}/api/azaisearch`;
    try {
      const response = await axios.post(url, {
        vector: queryEmbedding,
        k: k,
        indexName: this.indexName
      });
      // 假設後端回傳的結果中，相關文件存放在 response.data.value
      this.vectorSearchdata = response.data.value;
      return response.data.value;
    } catch (error) {
      console.error("Error in vectorSearch", error);
      throw error;
    }
  }

  /**
   * 呼叫 Azure OpenAI Chat API 取得生成的回答
   */
  async getChatCompletion(prompt: string): Promise<string> {
    const url = `${this.openAiEndpoint}/openai/deployments/${this.chatModelName}/chat/completions?api-version=2023-03-15-preview`;
    try {
      const response = await axios.post(url, {
        messages: [
          { role: "system", content: "You are an AI assistant that provides helpful answers." },
          { role: "user", content: prompt }
        ]
      }, {
        headers: {
          "Content-Type": "application/json",
          "api-key": this.openAiApiKey
        }
      });
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error("Error in getChatCompletion", error);
      throw error;
    }
  }

  /**
   * 整合 RAG 流程：
   * 1. 將使用者問題做 Embedding
   * 2. 呼叫後端向量搜尋 API 取得相關文件
   * 3. 將相關文件內容組成 prompt 並送給 Chat API 產生回答
   */
  async askQuestion() {
    try {
      // 1. 將使用者問題做 Embedding
      const queryEmbedding = await this.getEmbedding(this.question);

      // 2. 呼叫後端 API 進行向量搜尋，取得最相關的文件
      const docs = await this.vectorSearch(queryEmbedding, 10);
      console.log("docs", docs);

      // 3. 組合搜尋結果的文件內容作為 context
      // 假設文件標題存放在 "title" 欄位，內容存放在 "chunk" 欄位
      const context = docs.map((doc, index) =>
        `### Document ${index + 1}: ${doc.title}\n${doc.chunk}`
      ).join("\n\n");

      // 4. 建立 prompt，指示 Chat API 使用 context 回答，並在回答末尾列出文件引用清單
      const prompt = `
  I have the following context from my knowledge base:

  ${context}

  Please answer the following question using the above context.
  In your answer, refer to the documents by their number (e.g., Document 1, Document 2, etc.).
  After your answer, include a reference list that shows each document number and its corresponding file name.
  If you cannot find an answer in the context, please say "I don't know."
  Question: "${this.question}"
      `;
      console.log("prompt", prompt);

      // 5. 呼叫 Chat API 產生回答
      const chatAnswer = await this.getChatCompletion(prompt);
      this.answer = chatAnswer;
    } catch (error) {
      console.error("Error in askQuestion", error);
      this.answer = "An error occurred while processing your question.";
    }
  }

}
