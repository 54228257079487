<h3>{{formName}} List</h3>

<!-- <div style="margin-bottom: 10px;">
  <input
    type="text"
    placeholder="Search..."
    [(ngModel)]="searchQuery"
    style="width: 60%; padding: 8px; font-size: 1rem; margin-right: 10px;">
</div> -->

<!-- 表格 -->

<table class="table">
  <thead>
    <tr >
      <th *ngFor="let column of displayedColumns">
        {{ column | titlecase }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of filteredData">
      <td *ngFor="let column of displayedColumns">
        <ng-container *ngIf="item[column] && item[column].value !== undefined; else directDisplay">
          <ng-container [ngSwitch]="item[column].type">

            <!-- <ng-container *ngSwitchCase="'datetime'">
              {{ convertUnixToLocal(item[column].value) | date:'yyyyMMdd HH:mm:ss' }}
            </ng-container> -->

            <ng-container *ngSwitchCase="'location'">
              <div class="center-align location">
              <a [href]="getGoogleMapsUrl(item[column].value)" target="_blank" title="Open Google Map">
                <i class="fa-solid fa-location-dot"></i>
              </a>
            </div>
            </ng-container>

            <ng-container *ngSwitchCase="'photo-upload'">
              <div *ngFor="let photo of parsePhotos(item[column].value)" class="thumbnail">
                <a [href]="photo.fileUrl" target="_blank" rel="noopener noreferrer">
                  <img [src]="photo.fileUrl" [alt]="photo.fileName" style="width:50px; height:50px; margin-right:4px; cursor:pointer;">
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'signature'">
              <div class="signature-container">
                <img [src]="item[column].value" alt="Signature" class="signature-thumbnail">
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'multi-checkbox'">
              {{ getSelectedOptions(item[column].value) }}
            </ng-container>

            <ng-container *ngSwitchCase="'datetime'">
              {{ convertUnixToLocal(item[column].value) | date:'yyyy-MM-dd HH:mm:ss' }}
            </ng-container>


            <!-- 其他 type 可在此加入對應處理邏輯 -->

            <!-- 預設情況下，直接顯示 value -->
            <ng-container *ngSwitchDefault>
              {{ item[column].value }}
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #directDisplay>
          {{ item[column] }}
        </ng-template>
      </td>

    </tr>
  </tbody>
</table>
