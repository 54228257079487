import { Component, ChangeDetectorRef, OnInit,ViewChild,ViewContainerRef,ComponentRef,ViewChildren, QueryList, viewChild,inject ,ChangeDetectionStrategy} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OpanaiService } from '../../services/openai.service';
import { MarkdownViewerComponent } from '../../shared/markdown-viewer/markdown-viewer.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService, AssistantDataItem ,InitializedItem,InitMenuItem} from '../../services/data.service';
import { Router, RouterModule } from '@angular/router';

import { environment } from '../../environments/environment';

import { Renderer2, ElementRef, AfterViewInit } from '@angular/core';

import { COMPONENT_MAP } from "../../conponents/component-map"; //元件的對應表



@Component({
  selector: 'app-assistant',
  standalone: true,
  imports: [CommonModule, FormsModule, MarkdownViewerComponent],
  templateUrl: './assistant.component.html',
  styleUrl: './assistant.component.scss',
})
export class AssistantComponent implements OnInit {
  showintroduction = true;
  showchat = true;
  showloading = false;
  showrecommentlist = false;

  AssistantDataItem: AssistantDataItem | null = null;
  Recommendbuttonlist: { title: string; content: string }[] = [];

  modelname = '';
  assistantNo = ''; //AI助手的編號
  assistantid = ''; //AI助手的編號(Key)
  companyname = ''; //公司名稱
  vector_store_id = '';

  init_image = '';
  init_word = '';
  init_meun: InitMenuItem[] = [];
  chatavator = '';
  selectedIcon: string | null = null;
  slogan = '';

  threadid = '';

  //動態載入Component用
  @ViewChildren('dynamicComponentContainers', { read: ViewContainerRef })
  dynamicComponentContainers!: QueryList<ViewContainerRef>;

  componentRefs: ComponentRef<any>[] = [];
  cdr = inject(ChangeDetectorRef);

  userMessage!: string;
  assistantReply!: string;

  chatMessages: {
    role: string;
    content: SafeHtml | null;
    component: string | null;
  }[] = [];

  constructor(
    private openAiApiService: OpanaiService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef,
    private dataService: DataService,
    private router: Router // 注入 Router
  ) {}

  ngOnInit() {
    this.companyname = environment.name;
    this.assistantNo = this.router.routerState.snapshot.root.queryParams['id'];

    if (this.assistantNo == '') {
      //導回到lobby頁面
      this.router.navigate(['/lobby']);
    }

    //取得assistant資料
    //取得recommentbuttonlist
    // 使用 DataService 取得資料
    this.dataService
      .getLobbyDataItemByNo(this.companyname, this.assistantNo)
      .subscribe((items) => {
        this.AssistantDataItem = items;

        this.modelname = this.AssistantDataItem?.title || '';
        this.assistantid = this.AssistantDataItem?.assistantid || '';

        if (this.assistantid == '') {
          alert('error: assistantid is empty. Redirect to lobby page');
          //導回到lobby頁面
          this.router.navigate(['/lobby']);
        } else {
          this.assistantid = this.AssistantDataItem?.assistantid || '';
          this.vector_store_id = this.AssistantDataItem?.vector_store_id || '';
          this.init_image =
            this.AssistantDataItem?.Initialized?.init_image || '';
          this.init_word = this.AssistantDataItem?.Initialized?.init_word || '';
          this.init_meun = this.AssistantDataItem?.Initialized?.init_meun || [];
          this.chatavator =
            this.AssistantDataItem?.Initialized?.chatavator || '';
        }
      });
  }

  menubuttonclick(title: string, content: string, functionname: string = '') {
    //content有值才顯示在對話視窗
    if (content) {
      this.showintroduction = false;

      //跟AI對話
      this.chatwithassitant(title, content, functionname);
    } else {
      console.log('找不到對應的內容');
    }
  }

  chatwithassitant(title: string, message?: string, component?: string) {
    console.log('chatwithassitant');
    console.log('message:', message);
    //顯示在畫面上的是title
    //送到Chatgpt的是content

    const userComponent = component || '';

    if (title != '') {
      this.chatMessages.push({
        role: 'user',
        content: title,
        component: userComponent,
      });
      this.userMessage = '';
    } else {
      return;
    }

    if (!message) {
      message = title;
    }

    var userMessage = message;

    console.log('userMessage:', userMessage);
    // console.log('userMessage:', userMessage);
    // console.log('assitantid:', this.assitantid);

    console.log('threadid:', this.threadid);

    if (userMessage.indexOf('Demo-') != -1) {
      this.showloading = true;

      setTimeout(() => {
        switch (userMessage) {
          case 'Demo-新增稽核缺失':
            this.addNewConponentMessage('demoformcomponent');
            break;
          case 'Demo-Add Audit Result':
            this.addNewConponentMessage('demoformcomponent');
            break;
          case 'Demo-Download File':
            this.addNewConponentMessage('filedownloader');
            break;
          case 'Demo-查看稽核缺失分析圖表':
            this.addNewConponentMessage('demoform2component');
            break;
          case 'Demo-simulateExamComponent':
            this.addNewConponentMessage('simulateExamComponent');
            break;
          default:
            alert('找不到對應的元件');
        }

        this.showloading = false;
      }, 1000); // 1 秒後執行
    } else {
      this.showloading = true;

      this.openAiApiService
        .chatwithassistant(userMessage, this.assistantid, this.threadid)
        .subscribe((response) => {
          console.log('response:', response);

          this.assistantReply = response.reply;
          this.threadid = response.threadid;

          console.log('assistant reply:', this.assistantReply);

          var result = this.contentfransfer(this.assistantReply);

          console.log('result:', result);

          this.chatMessages.push({
            role: 'assistant',
            content: this.getSanitizedContent(result),
            component: '',
          });

          // 通知 Angular 檢測變更並更新 DOM
          this.cdr.detectChanges();

          // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
          setTimeout(() => {
            this.setchatlink();
          }, 0);

          this.showloading = false;
        });
    }
  }

  chatwith4omini() {
    // 載入設定檔
    const userMessage = this.userMessage;
    this.chatMessages.push({
      role: 'user',
      content: userMessage,
      component: '',
    });
    this.openAiApiService
      .chatwithgpts(this.userMessage)
      .subscribe((response) => {
        this.assistantReply = response.reply;
        this.chatMessages.push({
          role: 'AI-4omini',
          content: this.assistantReply,
          component: '',
        });
        this.userMessage = '';
      });
  }

  getSanitizedContent(content: string): SafeHtml {
    content = content.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  //針對最後一個產生的assistant對話，新增裡面的Click事件

  setchatlink() {
    const chatContainer = document.querySelector('.chat-container'); // 找到 .chat-container 元素
    if (chatContainer) {
      const assistantDivs = chatContainer.querySelectorAll('.assistant'); // 查詢 chatContainer 裡面的 .assistant 元素
      console.log('assistantDivs:', assistantDivs);

      // 如果存在 .assistant 元素，則取最後一個元素
      const lastAssistantDiv = assistantDivs[assistantDivs.length - 1];

      if (lastAssistantDiv) {
        // 查詢最後一個 .assistant 裡面的所有 .canclick 元素
        const canclickElements = lastAssistantDiv.querySelectorAll('.canclick');
        console.log('canclickElements:', canclickElements);

        // 可以在這裡綁定點擊事件
        canclickElements.forEach((canclick) => {
          canclick.classList.add('canclick');

          this.renderer.listen(canclick, 'click', () => {
            const value = canclick.getAttribute('data-value') || '';
            this.setInputValue(value);
          });
        });
      }
    }
  }

  loadComponents(componentKey : string): void {

    console.log('componentKey:', componentKey);

    //資料變更後重新渲染元件
    const containers = this.dynamicComponentContainers.toArray();
    const lastContainerIndex = containers.length - 1;
    //找到最後一個容器
    const container = containers[lastContainerIndex];

    const componentClass = COMPONENT_MAP[componentKey ];

    if (!container) {
      console.error(`找不到對應的容器: index ${lastContainerIndex}`);
      return;
    }

    const componentRef: ComponentRef<any> = container.createComponent(componentClass);

    //針對不同component,傳入不同的資料
     // 向子元件傳遞數據
  if (componentKey === 'simulateExamComponent') {

    //取得課程名稱
    componentRef.instance.classname = '模擬測試 - 屋頂作業主管';

    //取得題目
    componentRef.instance.questions = [
      {
        "text": "屋頂作業需設置適當安全防護措施，斜度大於幾度時必須設置護欄？",
        "options": [
          "30度",
          "34度",
          "40度",
          "45度"
        ],
        "correctAnswerIndex": [1],
        "selected": null
      },
      {
        "text": "新近人員從事屋頂作業應上安全衛生教育訓練幾小時？",
        "options": [
          "3小時",
          "6小時",
          "12小時",
          "18小時"
        ],
        "correctAnswerIndex": [1],
        "selected": null
      },
      {
        "text": "屋頂作業主管的職責不包括下列哪一項？",
        "options": [
          "指揮勞工作業",
          "檢查材料",
          "監督個人防護具使用",
          "不需確認安全設施的有效性"
        ],
        "correctAnswerIndex": [3],
        "selected": null
      },
      {
        "text": "屋頂作業時，安全帶或安全母索的錨錠至少應能承受多少公斤的拉力？",
        "options": [
          "1000公斤",
          "1500公斤",
          "2000公斤",
          "2300公斤"
        ],
        "correctAnswerIndex": [3],
        "selected": null
      },
      {
        "text": "屋頂上設置安全網的高度不得超過多少公尺？",
        "options": [
          "3公尺",
          "4公尺",
          "5公尺",
          "7公尺"
        ],
        "correctAnswerIndex": [3],
        "selected": null
      },
      {
        "text": "進行屋頂作業的時候，使用移動式施工架作業安全注意事項，以下哪一項是錯誤的？",
        "options": [
          "架上人員必須固定",
          "持續檢查施工架",
          "允許架上人員在不穩定情況下作業",
          "隨時保持防墜措施"
        ],
        "correctAnswerIndex": [2],
        "selected": null
      },
      {
        "text": "屋頂上作業需使用的安全防墜設施中，以下哪一種可以保護所有操作人員？",
        "options": [
          "安全帶",
          "施工架",
          "安全母索",
          "安全網"
        ],
        "correctAnswerIndex": [3],
        "selected": null
      },
      {
        "text": "國內法規中，要求屋頂作業主管每幾年必須接受安全衛生教育訓練？",
        "options": [
          "1年",
          "2年",
          "3年",
          "4年"
        ],
        "correctAnswerIndex":[2],
        "selected": null
      },
      {
        "text": "在斜度大於34度或滑溜屋頂作業時，工作臺的寬度應為何？",
        "options": [
          "30公分",
          "40公分",
          "50公分",
          "60公分"
        ],
        "correctAnswerIndex": [0],
        "selected": null
      }
    ]

     componentRef.instance.totalminutes = 20;

      // 動態監聽 @Output 事件
    componentRef.instance.explanationGenerated.subscribe((content: string) => {
      this.chatwithassitant(content);
    });

     // 設定子元件的事件傳遞
     componentRef.instance.valueSubmitted.subscribe((data: any) => {
      this.onValueSubmitted(data, lastContainerIndex);
    });

   }


   this.componentRefs.push(componentRef); // 儲存子元件的引用

  }


  ngOnDestroy(): void {
    // 清除組件參考，避免記憶體洩漏
    for (const componentRef of this.componentRefs) {
      if (componentRef) {
        componentRef.destroy();
      }
    }
  }

  // chatwithassistantSSE() {
  //   const userMessage = this.userMessage;
  //   this.chatMessages.push({ role: 'user', content: userMessage });

  //   // 使用 SSE 來接收回應
  //   this.openAiApiService
  //     .chatWithAssistantSSE(userMessage, this.assitantid)
  //     .subscribe({
  //       next: (response) => {
  //         // 檢查是否已有助手的回應在進行，如果有，則更新該回應
  //         if (
  //           this.chatMessages.length > 0 &&
  //           this.chatMessages[this.chatMessages.length - 1].role === 'assistant'
  //         ) {
  //           // 更新助手的回應內容，逐步追加流式內容
  //           this.chatMessages[this.chatMessages.length - 1].content += response;
  //         } else {
  //           // 如果助手還沒有回應，新增一條訊息，並開始流式顯示
  //           this.chatMessages.push({ role: 'assistant', content: response });
  //         }
  //         this.cdr.detectChanges(); // 手動觸發變更檢測
  //       },
  //       error: (error) => {
  //         console.error('Error:', error);
  //       },
  //       complete: () => {
  //         console.log('Conversation completed');
  //       },
  //     });

  //   this.userMessage = '';
  // }

  contentfransfer(content: string): string {
    // 替換以數字開頭的選項行，加上 `<a>` 標籤
    content = content.replace(/^\(\d+\)\s.*$/gm, (match) => {
      return `<a class='canclick' data-value='${match}'>${match}</a>`;
    });

    // 匹配並替換被【】包含的所有文字，去除【】符號
    content = content.replace(/【([^【】]+)】/g, (match, p1) => {
      return `<a class='canclick' data-value='${p1}'>${p1}</a>`;
    });

    return content;
  }

  setInputValue(value: string) {
    this.userMessage = value;
  }

  // 捕捉按鍵事件
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.userMessage) {
        this.chatwithassitant(this.userMessage);
      }
    }
  }

  closeDiv() {
    this.showintroduction = true;
    this.showchat = false;
    this.showrecommentlist = false;
    this.showloading = false;

    this.chatMessages = [];

    //清空threadid
    this.threadid = '';
  }

  onIconClick(icon: string) {
    this.selectedIcon = icon; // 設定當前選取的圖示

    if (icon == 'sad') {
      this.slogan = '(明日は明日の風が吹く)';
    } else {
      this.slogan = '';
    }
  }

  //導頁到檔案中心
  gotofilecenter() {
    this.router.navigate(['/filebank'], {
      queryParams: { vector_store_id: this.vector_store_id },
    });
  }

  /**
   * 動態加載元件並插入到 chatMessages
   */
  addNewConponentMessage(componentKey: string) {
    const componentClass = COMPONENT_MAP[componentKey];

    console.log('componentClass:', componentClass);

    if (!componentClass) {
      console.error(`找不到對應的元件: ${componentKey}`);
      return;
    }

    this.chatMessages.push({
      role: 'assistant',
      content: '',
      component: componentKey,
    });

    console.log('chatMessages:', this.chatMessages);

    // 通知 Angular 檢測變更並更新 DOM
    this.cdr.detectChanges();

    // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
    setTimeout(() => {
      this.loadComponents(componentKey);
    }, 0);
  }

  onValueSubmitted(data: any, index: number) {
    console.log('來自子元件的資料:', data);
    console.log('該元件在 chatMessages 的索引:', index);
    // 可以將資料寫入資料庫或進一步處理
  }
}
