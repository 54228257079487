<div  class="component-container">
  <h3>新增稽核缺失</h3>
  <div *ngIf="formfinished">表單填寫完成!</div>
  <div *ngIf="!formfinished">
  <form (ngSubmit)="sendValue()">
    <div class="form-section">
      <label for="suggestion-content">內容描述</label>
      <textarea
        id="suggestion-content"
        [(ngModel)]="formData.suggestionContent"
        name="suggestion-content"
        placeholder="請填寫缺失事項"
      ></textarea>
    </div>

    <div class="form-section">
      <label for="responsible-unit">單位名稱</label>
      <select
        id="responsible-unit"
        name="responsible-unit"
        [(ngModel)]="formData.responsibleUnit"
        class="form-input"
      >
        <option value="" disabled selected>請選擇單位名稱</option>
        <option *ngFor="let Dep of DepartOptions" [value]="Dep">{{ Dep }}</option>
      </select>
    </div>

    <div class="form-section">
      <label for="improvement-status">建議改善方式</label>
      <textarea
        id="improvement-status"
        [(ngModel)]="formData.improvementStatus"
        name="improvement-status"
        placeholder="請填寫建議改善方式"
      ></textarea>
    </div>

    <div class="form-section">
      <label for="auditor">稽核人名稱</label>
      <input
        type="text"
        id="auditor"
        [(ngModel)]="formData.auditor"
        name="auditor"
        placeholder="請輸入稽核人名稱"
      />
    </div>

    <div class="form-section">
      <label for="before-photo">缺失照片 (可上傳多張，並可刪除)</label>
      <input
        type="file"
        id="before-photo"
        name="before-photo"
        (change)="handlePhotoUpload($event)"
        accept="image/*"
        multiple
      />
      <div class="photo-preview">
        <div *ngFor="let photo of formData.photos; let i = index" class="photo-item">
          <img [src]="photo.preview" alt="Preview" />
          <button type="button" (click)="removePhoto(i)">X</button>
        </div>
      </div>
    </div>

    <div class="form-section">
      <label for="report-time">提報時間</label>
      <input
        type="datetime-local"
        id="report-time"
        [(ngModel)]="formData.reportTime"
        name="report-time"
      />
    </div>

    <div class="form-section">
      <label for="audit-location" class="inline-label">稽核地點 (GPS 定位) <button type="button" class="small-button" (click)="getCurrentLocation()">使用當前位置</button></label>
      <input
        type="text"
        id="audit-location"
        [(ngModel)]="formData.auditLocation"
        name="audit-location"
        placeholder="請輸入稽核地點"
      />

    </div>

    <button type="submit">提交</button>
  </form>
</div>
</div>
