<div class="component-container">
  <div *ngFor="let config of reportConfig">
    <div [ngSwitch]="config.type">
      <!-- 根據 config.type 顯示不同的元件 -->

      <app-bar-chart *ngSwitchCase="'bar'"
                     [title]="config.title"
                     [data]="config.data ??[]"
                     [labels]="config.labels??[]">
      </app-bar-chart>

      <app-pie-chart *ngIf="config.type === 'pie'"
                   [title]="config.title"
                   [data]="config.data??[]"
                   [labels]="config.labels??[]">
    </app-pie-chart>

    <app-graph-chart *ngSwitchCase="'graph'"
    [title]="config.title"
    [nodes]="config.nodes ?? []"
    [links]="config.links ?? []">
</app-graph-chart>
<!--
<app-map *ngSwitchCase="'map'"
         [title]="config.title"
         [mapType]="config.mapType || 'Japan'"
         [lat]="config.lat ?? 35.6762"
         [lng]="config.lng ?? 139.6503"
         [mapData]="config.mapData"
         [zoom]="config.zoom ?? 5">
</app-map> -->

    </div>
  </div>
</div>
