export interface SdsParseResult {
  "errormsg"?: any;
  "CN"?: any;
  "ChemicalId"?: any;
  "GroupKey"?: any;
  "DBVersion"?: any;
  "SDSNumber"?: any;
  "PartNumber"?: any;
  "ItemCategory"?: any;
  "ItemSpec"?: any;
  "ItemSpecUnit"?: any;
  "SAPCode"?: any;
  "ItemName"?: any;
  "ChemicalZhName"?: any;
  "ChemicalEnName"?: any;
  "ChemicalOtherName"?: any;
  "UsageLimition"?: any;
  "PhysicalState"?: any;
  "BoilingPoint"?: any;
  "Remark"?: any;
  "SupplierCompanyName"?: any;
  "SupplierAddress"?: any;
  "SupplierPhone"?: any;
  "State"?: any;
  "EmergencyContactName"?: any;
  "EmergencyContactPhone"?: any;
  "EmergencyContactFax"?: any;
  "HazardValues"?: any;
  "HazardValues_Detail"?: any;
  "HazardImages"?: any;
  "HazardAlertMessage"?: any;
  "HazardPreventiveMeasures"?: any;
  "OtherHazard"?: any;
  "MaterialComposition"?: any;
  "WarningText"?: any;
  "BoilingPointType"?: any;
  "BoilingPointUpper"?: any;
  "Completeness"?: any;
  "HasFlashPoint"?: any;
  "FlashPoint"?: any;
  "FlashPointMethod"?: any;
  "MeltingPoint"?: any;
  "Color"?: any;
  "Smell"?: any;
  "PH"?: any;
  "Olfa"?: any;
  "Flam"?: any;
  "LEL"?: any;
  "URL"?: any;
  "Resolve"?: any;
  "Vepden"?: any;
  "Naturetemp"?: any;
  "Solubility"?: any;
  "Vappre"?: any;
  "Volatile"?: any;
  "Density"?: any;
  "Kow"?: any;
  "FirstAid"?: any;
  "FireFighting"?: any;
  "AccidentalRelease"?: any;
  "HandlingStorage"?: any;
  "EngineerControl"?: any;
  "RespiratoryProtection"?: any;
  "HandProtection"?: any;
  "EyeProtection"?: any;
  "SkinProtection"?: any;
  "HygieneMeasures"?: any;
  "EcologicalInformation"?: any;
  "Exposure"?: any;
  "Symptom"?: any;
  "Poison"?: any;
  "LD"?: any;
  "LC"?: any;
  "IARC"?: any;
  "ACHIH"?: any;
  "EcologicalData"?: any;
  "DisposalConsiderations"?: any;
  "Un"?: any;
  "UnNumber"?: any;
  "TransCategory"?: any;
  "PackageCategory"?: any;
  "OceanPollution"?: any;
  "TransRemark"?: any;
  "TransportName"?: any;
  "RegulatoryInfomation"?: any;
  "OtherInformation"?: any;
  "RemarkDate"?: any;
  "CreateTime"?: any;
  "CreateUser"?: any;
  "UpdateTime"?: any;
  "UpdateUser"?: any;
  "Draft"?: any;
  "ApproveStatus"?: any;
  "ApproveTime"?: any;
  "Packaging"?: any;
  "RescueMethod"?: any;
  "ChemicalMasterDetail"?: {
      "Inhalation"?: any;
      "SkinContact"?: any;
      "EyeContact"?: any;
      "Ingestion"?: any;
      "MajorDiseaseAndHazardousEffect"?: any;
      "ProtectionOfFirstAidPerson"?: any;
      "NotesToDoctor"?: any;
      "SuitableExtinguishingMedia"?: any;
      "SpecialHazardsWhenExtinguishing"?: any;
      "SpecialExtinguishingProcedure"?: any;
      "SpecialProtectiveEquipmentForFireFighters"?: any;
      "PersonalProtection"?: any;
      "EnvironmentalProtection"?: any;
      "MethodsForCleaningUp"?: any;
      "Handling"?: any;
      "Storage"?: any;
      "EngineeringControls"?: any;
      "RespiratoryProtection"?: any;
      "HandProtection"?: any;
      "EyeProtection"?: any;
      "SkinAndBodyProtection"?: any;
      "MineSafetyAndHealthAdministrationMeasures"?: any;
      "Color"?: any;
      "Smell"?: any;
      "OdorThresholdValue"?: any;
      "FusionPoint"?: any;
      "FlashPointTestingMethod"?: any;
      "Flammability"?: any;
      "ExplosiveLimitMin"?: any;
      "ExplosiveLimitMax"?: any;
      "DecompositionTemperature"?: any;
      "VaporDensity"?: any;
      "AutoignitionTemperature"?: any;
      "Solubility"?: any;
      "RateOfVolatilization"?: any;
      "Kow"?: any;
      "temp_VaporPressure"?: any;
      "temp_PH"?: any;
      "temp_PhysicalState"?: any;
      "local_BoilingPoint"?: any;
      "temp_FlashPoint"?: any;
      "temp_Density"?: any;
      "Stability"?: any;
      "PossibilityOfHazardousReaction"?: any;
      "ConditionsToAvoid"?: any;
      "IncompatibleMaterials"?: any;
      "HazardousDecompositionProducts"?: any;
      "ToxicExposurePath"?: any;
      "ToxicSymptom"?: any;
      "AcuteToxicity"?: any;
      "LD50"?: any;
      "LC50"?: any;
      "LC50Unit"?: any;
      "ChronicToxicity"?: any;
      "EcoToxicity"?: any;
      "PersistenceAndDegradability"?: any;
      "BioaccumulativePotential"?: any;
      "MobilityInSoil"?: any;
      "OtherAdverseEffects"?: any;
      "DisposalConsiderations"?: any;
      "UNNo"?: any;
      "UNProperShippingName"?: any;
      "TransportHazardClass"?: any;
      "PackingGroup"?: any;
      "MarinePollutant"?: any;
      "SpecialTransportationAndSpecialPrecautions"?: any;
      "RegulatoryInformation"?: any;
      "ReferenceFiles"?: any;
      "CreateUnitName"?: any;
      "CreateUnitAddress"?: any;
      "CreateUnitPhoneNumber"?: any;
      "CreaterTitle"?: any;
      "CreateName"?: any;
      "CreateDate"?: any;
      "HasLethalDose"?: any;
      "HasLethalConcentration"?: null;
      "Packaging"?: any;
      "RescueMethod"?: any;
  },
  "ChemicalMaterial"?:
  {
      "CN"?: any;
      "ChemicalId"?: any;
      "MaterialId"?: any;
      "ChineseName"?: any;
      "EnglishName"?: any;
      "CasNo"?: any;
      "PercentageMin"?: any;
      "PercentageMax"?: any;
      "ChemicalFormula"?: any;
      "ChemicalFormulaValue"?: any;
      "ControlCategory"?: any;
      "CustomControlCategory"?: any;
      "TWA"?: any;
      "STEL"?: any;
      "CEILING"?: any;
      "BEIs"?: any;
      "TWAUnit"?: any;
      "STELCoefficient"?: any;
      "STELUnit"?: any;
      "CEILINGUnit"?: any;
      "Discriminator"?: any;
      "SimilarName"?: any;
      "Percentage"?: any;
      "HazardPercentage"?: any;
      "DataType"?: any;
      "UpperBound"?: any;
      "LowerBound"?: any;
  }[]
  ,
  "VaporPressure"?: any
}

// src/app/access/models/chemical-sds/parse-result.model.ts
export class ParseResult {
	isSuccess: boolean;
	hashKey: string;
	result: any;
	content: string;

  constructor() {
    this.isSuccess = false; // 默認值
    this.hashKey = ''; // 默認值
    this.result = null; // 默認值
    this.content = ''; // 默認值
  }
}


export enum PageMode {
  /** 新增 */
  CREATE = 'create',

  /** 編輯 */
  EDIT = 'edit',

  /** 檢視 */
  VIEW = 'view',
}
