import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as echarts from 'echarts'; // 引入 ECharts

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  template: `
    <div class="chart-container">
      <h3>{{ title }}</h3>
      <div #chartContainer style="width: 100%; height: 400px;"></div>
    </div>
  `,
  styleUrls: ['../report-component.scss']
})
export class BarChartComponent implements AfterViewInit {
  @Input() title: string = '';
  @Input() data: any[] = [];
  @Input() labels: string[] = [];

  @ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;

  ngAfterViewInit(): void {
    this.generateChart();
  }

  generateChart(): void {
    const chartElement = this.chartContainer.nativeElement;
    const myChart = echarts.init(chartElement);

    // 根據 data 的長度來決定是否使用堆疊圖
    const isStacked = this.data.length > 1; // 如果有多個數據系列，則使用堆疊圖

    const series = isStacked
      ? this.data.map((dataSet, index) => ({
          name: `Series ${index + 1}`,
          type: 'bar',
          stack: 'stackedBar',  // 設置 stack 屬性，所有 series 的 stack 名稱必須相同
          data: dataSet
        }))
      : this.data.map((dataSet, index) => ({
          name: `Series ${index + 1}`,
          type: 'bar',
          data: dataSet
        }));

    const chartOption = {
      title: {
        text: this.title
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
      },
      legend: {
        data: this.labels
      },
      xAxis: {
        type: 'category',
        data: this.labels
      },
      yAxis: {
        type: 'value'
      },
      series: series
    };

    myChart.setOption(chartOption);
  }
}
