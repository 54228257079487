import { Component } from '@angular/core';

@Component({
  selector: 'app-demoform2',
  standalone: true,
  imports: [],
  templateUrl: './demoform2.component.html',
  styleUrl: './demoform2.component.scss'
})
export class Demoform2Component {

}
