<div class="form-list-table">
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Create At</th>
        <th>Total</th>
        <th>Tools</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let form of data">
        <td>{{ form.formName }}</td>
        <td>{{ form._ts * 1000 | date:'yyyy/MM/dd' }}</td>
        <td>{{ form.filledCount }}</td>
        <td class="tools">
          <button (click)="viewFormList(form.formName)">List</button>
          <button (click)="createNewRecord(form.formName)">Create</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
