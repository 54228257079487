import { DemoformComponent } from '../conponents/form/demoform/demoform.component';
import { Demoform2Component } from '../conponents/form/demoform2/demoform2.component';
import { FiledownloaderComponent } from '../conponents/tools/filedownloader/filedownloader.component';
import { SimulateExamComponent } from '../conponents/exam/simulate-exam/simulate-exam.component';


export const COMPONENT_MAP: { [key: string]: any } = {
  demoformcomponent: DemoformComponent,
  demoform2component: Demoform2Component,
  filedownloader: FiledownloaderComponent,
  simulateExamComponent: SimulateExamComponent,
};
