import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-form-date',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label for="{{ id }}">{{ label }}</label>
      <input
        type="date"
        [id]="id"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        [name]="name"
        [placeholder]="placeholder"
      />
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDateComponent),
      multi: true
    }
  ],
  styleUrls: ['total-component.scss']
})
export class FormDateComponent implements ControlValueAccessor {
  @Input() label: string = '';  // 標籤
  @Input() id: string = '';     // id 用於對應 label
  @Input() name: string = '';   // name 用於表單提交
  @Input() placeholder: string = ''; // 佔位符

  value: string = '';  // 綁定的值

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  // 用於 ControlValueAccessor 實作
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // 如有需要，可以處理 disabled 狀態
  }
}
