import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-markdown-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss']
})
export class MarkdownViewerComponent implements OnChanges {
  @Input() markdownContent: string = ''; // 從父組件接收的 Markdown 內容
  htmlContent: SafeHtml = ''; // 解析後的 HTML

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.renderMarkdown();
  }

  // 確保處理返回值為 string 或 Promise<string>
  async renderMarkdown(): Promise<void> {
    // 使用 marked 轉換 Markdown 到 HTML
    const rawHtml = await Promise.resolve(marked(this.markdownContent));

    // 將 HTML 標記為信任
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
  }
}
