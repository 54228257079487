<div class="container-fluid vh-100">
  <div class="row h-100">
    <div class="col-md-6 d-flex align-items-center justify-content-center login-left">
      <div class="text-center">
        <div class="login-tittle" [innerHTML]="login_title"></div>
        <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" class="mb-4" style="max-width: 400px;">
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-center login-right ">
      <div class="login-form w-75">
        <h2 class="mb-4 text-dark">Log In</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="email" class="form-label">Your Email</label>
            <input type="email" class="form-control" id="username" formControlName="username" placeholder="your email">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" formControlName="password" id="password" placeholder="password">
          </div>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Log In</button>
            <a  class="text-secondary">Forgot Password?</a>
          </div>
        </form>
        <p class="text-center text-muted mt-5 ">Copyright © {{copyright}} <span *ngIf="version"> | Version:{{version}}</span> </p>
      </div>
    </div>
  </div>
</div>
