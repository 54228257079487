// form-config.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { FormFieldConfig } from '../conponents/dynamic-form/dynamic-form/dynamic-form.component' ;
import { DatabaseService } from './database.service';


export interface StoredFormConfig {
  formName: string;
  companyid: string;
  assistantid: string;
  config: FormFieldConfig[];
}

@Injectable({
  providedIn: 'root'
})
export class FormConfigService {
  // private configUrl = 'assets/sampledata/form-configs.json'; // JSON檔的路徑

  constructor(private databaseService: DatabaseService) {}


   // 依據 formName 與 companyid 取得對應的表單配置
   getFormConfig(formName: string, companyid: string): Observable<FormFieldConfig[]> {

    // 構造過濾條件 => 這個寫法跟下面那個是一樣的，好酷
    const filter = { formName, companyid };

    // const filter: any = {};
    // filter.formName = formName;
    // filter.companyid = companyid;

    // 呼叫 DatabaseService 的 getDataByQuery 方法，這裡假設每次只取 10 筆資料
    return this.databaseService.getDataByQuery(filter, 1000,"form-config").pipe(
      map((response: any) => {

        // response.items 會是一個 StoredFormConfig 陣列
        const configs: StoredFormConfig[] = response.items;
        const found = configs.find(cfg => cfg.formName === formName && cfg.companyid === companyid);
        return found ? found.config : [];
      })
    );
  }


}
