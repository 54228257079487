/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from '../src/app/environments/environment';

// import { LayoutCenterComponent } from '../src/app/layouts/layout-center/layout-center.component';

function initializeApp(): void {

  console.log('initializeApp');

  // 設定標題
  document.title = environment.title;

  // 設定 favicon
  const link: HTMLLinkElement = document.querySelector(`link[rel*='icon']`) || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = environment.favicon;
  document.head.appendChild(link);
}

initializeApp();

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
