import { Injectable } from '@angular/core';
import { AuthenticationStrategy, AuthenticationResult } from './authentication-strategy.interface';
import { DatabaseService } from '../database.service';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

interface UserAccount {
  username: string;
  password: string;
  modules: string[];
  companyid: string;
}

@Injectable({
  providedIn: 'root'
})
export class DBAuthenticationStrategy implements AuthenticationStrategy {
  constructor(private databaseService: DatabaseService) {}

  async authenticate(credentials: { username: string; password: string; }): Promise<AuthenticationResult> {
    try {
      const filter = {
        companyid: environment.companyname,
        username: credentials.username,
        password: credentials.password
      };

      const result = await firstValueFrom(
        this.databaseService.getDataByQuery(filter, 1, "users").pipe(
          map((response: any) => {
            if (!response?.items?.[0]) {
              return null;
            }
            return response.items[0] as UserAccount;
          })
        )
      );

      if (result) {
        return {
          success: true,
          username: result.username,
          modules: result.modules
        };
      } else {
        return {
          success: false
        };
      }
    } catch (error) {
      console.error('驗證過程發生錯誤:', error);
      return {
        success: false
      };
    }
  }
}
