export const environment = {
  production: true,
  title: 'Tpic',
  favicon: 'assets/images/tpic/icons/favicon.ico',
  appleTouchIcon:'',
  apiUrl: 'https://tpic-examcenter-api.azurewebsites.net', //http://localhost:8080  https://tpic-examcenter-api.azurewebsites.net
  companyname: 'Tpic',
  clientId: 'Tpic',
  copyright: 'Tpic',
  logoUrl: 'assets/images/Tpic/tpic_logo.png',
  Login_title:'',
  Login_logoUrl: 'assets/images/Tpic/tpic_login_logo.png',
  test_account: 'tpic',
  admin_account: 'admin',
  login_page_setting: {
    title_text:"歡迎使用工商台中<br>AI線上學習與模擬考試中心",
    id_text:"身份證字號",
    pw_text:"生日 (請使用民國年 例如：0860101)",
    login_text:"登入",
    forget_text:"忘記密碼",
  },
  css_file_name: 'Tpic.css',
  assistantid: 'asst_Eoq4YShI4IJex9m6Ni8XUkM6',
  vector_store_id: 'vs_7DWBUWYQuzCAL5jFMoQc9f13',
  AzureBlob:{
    blobUrl:'',
    blobsasToken:'',
    PrivateContainerName : '',
    PublicContainerName : '',
    FileUploadContainerName : ''
  },
  version: '2.5.0',
};
