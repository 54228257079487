import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { environment } from '../environments/environment';

// 定義 SaveTarget 的型別
export type SaveTarget = 'public' | 'fileupload';

@Injectable({
  providedIn: 'root'
})

export class AzureBlobService {

  private blobUrl = environment.AzureBlob.blobUrl;
  private blobsasToken = environment.AzureBlob.blobsasToken;
  private containerName = "";


  constructor() {}


  getcontainerName(savetarget:string){

    switch (savetarget) {
      case 'public':
        this.containerName = environment.AzureBlob.PublicContainerName;
        break;
      case 'fileupload':
        this.containerName = environment.AzureBlob.FileUploadContainerName;
        break;
      default:
        this.containerName = environment.AzureBlob.PublicContainerName;
        break;
    }


  }

  //現在AI對話跟客戶自己的檔案上傳會放在一起，要想一下怎麼處理
  async uploadFile(file: File,CompanyName:string = 'None',savetarget:SaveTarget): Promise<string> {

    // console.log('containerName', this.containerName);
    // console.log('blobsasToken', this.blobsasToken);
    // console.log('blobUrl', this.blobUrl);

    this.getcontainerName(savetarget);

    try {

      const now = new Date();
      const pad = (n: number): string => n.toString().padStart(2, '0');
      const timestamp = `${now.getFullYear()}${pad(now.getMonth() + 1)}${pad(now.getDate())}${pad(now.getHours())}${pad(now.getMinutes())}${pad(now.getSeconds())}`;

      // 組合新的檔案名稱
      const newFileName = `${CompanyName}_${timestamp}_${file.name}`;

      const blobServiceClient = new BlobServiceClient(`${this.blobUrl}?${this.blobsasToken}`);
      const containerClient = blobServiceClient.getContainerClient(this.containerName);
      //這邊在設定檔名
      const blobClient = containerClient.getBlockBlobClient(newFileName);

      // 使用新的 uploadData 方法上傳檔案
      await blobClient.uploadData(file);

      // 檔案上傳後的公開 URL
      const fileUrl = this.removeQueryStringBySV(blobClient.url);

      console.log('File uploaded to Azure Blob Storage:', fileUrl);

      return fileUrl;
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:', error);
      throw error;
    }
  }

  async uploadFileByBlob(blob: Blob, filename: string,savetarget:SaveTarget): Promise<string> {
    try {


      this.getcontainerName(savetarget);

      const blobServiceClient = new BlobServiceClient(`${this.blobUrl}?${this.blobsasToken}`);
      const containerClient = blobServiceClient.getContainerClient(this.containerName);
      //這邊在設定檔名
      const blobClient = containerClient.getBlockBlobClient(filename);

      // 使用新的 uploadData 方法上傳 Blob
      await blobClient.uploadData(blob);

      // 檔案上傳後的公開 URL
      const fileUrl = this.removeQueryStringBySV(blobClient.url);

      console.log('Blob uploaded to Azure Blob Storage:', fileUrl);

      return fileUrl;
    } catch (error) {
      console.error('Error uploading blob to Azure Blob Storage:', error);
      throw error;
    }
  }


  removeQueryStringBySV(url: string): string {
    return url.split('?sv')[0];
  }

}


