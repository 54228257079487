import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
  ) {}


  login(credentials: { username: string; password: string }): Observable<any> {

    const test_account = environment.test_account;

    if (
      credentials.username === test_account &&  credentials.password === test_account
    ) {
      // 模擬成功的回應
      return of({ success: true, token: 'fake-jwt-token' });
    } else {
      // 模擬失敗的回應
      return throwError(() => new Error('Invalid username or password'));
    }
  }
}
