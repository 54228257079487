

<div class="d-flex justify-content-between align-items-center">
  <div>
    Database ID：{{vector_store_id}} |
    Files Count：{{filecount}}
  </div>
  <div>
    <!-- <button *ngIf="havedeletefile"  type="button" (click)="showdeleteconfirm()" class="btn btn-danger" style="margin-right: 10px;"><i class="fa-regular fa-trash-can mx-1"></i>Delete</button> -->
    <button *ngIf="havedeletefile" type="button" class="btn btn-danger me-2" data-bs-toggle="modal" data-bs-target="#deleteConfirmModal">
      <i class="fa-regular fa-trash-can mx-1"></i>Delete
    </button>

    <app-fileuploader [vectorStoreId]="vector_store_id" [existFileList]="existFileList"></app-fileuploader>

  </div>
</div>


<div *ngIf="filelist.length > 0" class="table-responsive mt-2">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th></th>
        <th>No.</th>
        <!-- <th>ID</th> -->
        <th>Filename</th>
        <th>Size (bytes)</th>
        <th>Created At</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <!-- 迭代 filelist -->
      <tr *ngFor="let file of filelist; let i = index"
    [ngClass]="{'trdeleted': file.deleted}">
  <th>
    <a style="cursor: pointer;" (click)="deleteFile(file.id)">
      <i class="fa-solid fa-trash-can"></i>
    </a>
  </th>
  <td [ngStyle]="{'text-decoration': file.deleted ? 'line-through' : 'none'}">{{ i + 1 }}</td>
  <!-- <td>{{ file.id }}</td> -->
  <td [ngStyle]="{'text-decoration': file.deleted ? 'line-through' : 'none'}">{{ file.filename }}</td>
  <td [ngStyle]="{'text-decoration': file.deleted ? 'line-through' : 'none'}">{{ formatBytes(file.bytes) }}</td>
  <td [ngStyle]="{'text-decoration': file.deleted ? 'line-through' : 'none'}">{{ file.created_at}}</td>
  <td [ngStyle]="{'text-decoration': file.deleted ? 'line-through' : 'none'}">{{ file.status }}</td>
</tr>
    </tbody>
  </table>
</div>

<!-- 如果 filelist 為空，顯示 loading 信息 -->
<div id="showstatus">{{loadingstatus}}</div>


<!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteConfirmModal" tabindex="-1" aria-labelledby="deleteConfirmModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteConfirmModalLabel">Comfirm Window</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        File deletion cannot be undone. Are you sure you want to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="confirmDelete()">Comfirm</button>
      </div>
    </div>
  </div>
</div>
