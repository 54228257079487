import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-fire-work-permission',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './fire-work-permission.component.html',
  styleUrls: ['./fire-work-permission.component.scss'],
})
export class FireWorkPermissionComponent {
  @Output() valueSubmitted = new EventEmitter<any>();

  formfinished = false;

  openFlameReason_torch = [
    { name: '氣焊接或切斷', selected: false },
    { name: '電氣焊接或切斷', selected: false },
    { name: '氧焊', selected: false },
    { name: '高速切斷', selected: false },
    { name: '噴焊', selected: false },
    { name: '砂磨', selected: false },
    { name: '其他', selected: false },
  ];

  openFlameReason_generator = [
    { name: '引擎發電機', selected: false },
    { name: '活線作業', selected: false },
    { name: '鑽孔', selected: false },
    { name: '焊接', selected: false },
    { name: 'RC切割', selected: false },
    { name: 'PVC焊接', selected: false },
    { name: '火警偵測隔離', selected: false },
    { name: '其他', selected: false },
  ];

  environmentalHazardAssessmentCategories = [
    { name: '危險溶劑儲存作業區', selected: false },
    { name: '氣體儲存作業區', selected: false },
    { name: '易燃物儲存作業區', selected: false },
    { name: '高壓氣體儲存作業區', selected: false },
    { name: '化學物質儲存作業', selected: false },
    { name: '一般物料成品倉庫', selected: false },
    { name: '其他', selected: false }
  ];

  firePreventionMeasuresCategories = [
    { name: '危險物質搬離 公尺，搬設方式', selected: false },
    { name: '滅火器 幹粉 ___ 個，CO2 ___ 個', selected: false },
    { name: '易燃氣體如 ___ 日 時 分，檢知器數值 ___ %，檢知者:', selected: false },
    { name: '防火隔離措施，處置方式', selected: false },
    { name: '其他預防措施', selected: false }
  ];

  // 表单数据
  formData = {
    fireDuration: { start: '', end: '' },
    fireArea: '', // 改為儲存選項的字串
    alarmIsolation: '',
    openFlameReason: { torch: false, grinding: false },
    hiddenFlameReason: { generator: false, drilling: false },
    contractor: '',
    purchaseDepartment: '',
    safetyOfficer: '',
    departmentResponsible: '',
    environmentalHazardAssessment: { solvent: false, chemical: false },
    firePreventionMeasures: { extinguisher: false, distance: false },
    approvalDuration: { start: '', end: '' },
    environmentalSafetyStaff: '',
    environmentalSafetySupervisor: '',
  };

  async sendValue() {
    console.log('Form data:', this.formData);
    this.formfinished = true;

    // 可以添加後端接口調用的邏輯
    // await this.someService.sendData(this.formData);
  }
}
