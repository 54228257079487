import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment'; // 確保路徑正確

@Injectable({
  providedIn: 'root'
})
export class YhService {
  private apiUrl = `${environment.apiUrl}/api/tpic/getallcalss`;

  constructor(private http: HttpClient) {}

  getAllClasses(startDate: string, endDate: string): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl, { startDate, endDate });
  }
}
