/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from '../src/app/environments/environment';

function initializeApp(): void {

  // 設定標題
  document.title = environment.title;

  // 設定 favicon
  const link: HTMLLinkElement = document.querySelector(`link[rel*='icon']`) || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = environment.favicon;
  document.head.appendChild(link);

  // 移除任何已存在的 apple-touch-icon
  document.querySelectorAll('link[rel="apple-touch-icon"]').forEach(el => el.remove());

  // 設定 apple-touch-icon，使用絕對路徑並指定尺寸
  const appleTouchIcon: HTMLLinkElement = document.createElement('link');
  appleTouchIcon.rel = 'apple-touch-icon';
  appleTouchIcon.setAttribute('sizes', '180x180'); // 使用 setAttribute 代替直接賦值

  // 使用絕對路徑並確保圖片是 PNG 格式
  const absoluteIconPath = environment.appleTouchIcon;
  appleTouchIcon.href = absoluteIconPath;
  document.head.appendChild(appleTouchIcon);

  // 添加 iOS Web App meta 標籤
  const metaTags = [
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
    { name: 'apple-mobile-web-app-title', content: environment.title }
  ];

  metaTags.forEach(tag => {
    const existingMeta = document.querySelector(`meta[name="${tag.name}"]`);
    if (existingMeta) {
      existingMeta.setAttribute('content', tag.content);
    } else {
      const meta = document.createElement('meta');
      meta.name = tag.name;
      meta.content = tag.content;
      document.head.appendChild(meta);
    }
  });
}

initializeApp();

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
