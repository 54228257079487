import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as echarts from 'echarts'; // 引入 ECharts

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  template: `
    <div class="chart-container">
      <h3>{{ title }}</h3>
      <div #chartContainer style="width: 100%; height: 400px;"></div>
    </div>
  `,
  styleUrls: ['../report-component.scss']
})
export class PieChartComponent implements AfterViewInit {
  @Input() title: string = '';
  @Input() data: any[] = [];
  @Input() labels: string[] = [];

  @ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;

  ngAfterViewInit(): void {
    this.generateChart();
  }

  generateChart(): void {
    const chartElement = this.chartContainer.nativeElement;
    const myChart = echarts.init(chartElement);

    const chartOption = {
      title: {
        text: this.title,
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        data: this.labels
      },
      series: [
        {
          name: this.title,
          type: 'pie',
          radius: '50%',
          data: this.data.map((value, index) => ({
            name: this.labels[index] || `Item ${index + 1}`,
            value
          }))
        }
      ]
    };

    myChart.setOption(chartOption);
  }
}
