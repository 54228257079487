<div class="component-container">
  <h2>承覽商施工申請表</h2>
  <div *ngIf="formfinished">表單提交完成！</div>
  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">
      <div class="form-section">
        <label for="form-number">表單編號</label>
        <input
          type="text"
          id="form-number"
          [(ngModel)]="formData.formNumber"
          name="form-number"
          placeholder="填寫表單編號"
        />
      </div>

      <div class="form-section">
        <label for="fill-date">填表日期</label>
        <input
          type="date"
          id="fill-date"
          [(ngModel)]="formData.fillDate"
          name="fill-date"
        />
      </div>

      <div class="form-section">
        <label for="application-month">申請月份</label>
        <select
          id="application-month"
          [(ngModel)]="formData.applicationMonth"
          name="application-month"
          class="form-input"
        >
          <option value="" disabled selected>選擇月份</option>
          <option *ngFor="let month of months" [value]="month">{{ month }}</option>
        </select>
      </div>

      <div class="form-section">
        <label for="application-day">申請日期</label>
        <input
          type="text"
          id="application-day"
          [(ngModel)]="formData.applicationDay"
          name="application-day"
          placeholder="填寫日期"
        />
      </div>

      <div class="form-section">
        <label for="contractor-name">承覽商名稱</label>
        <input
          type="text"
          id="contractor-name"
          [(ngModel)]="formData.contractorName"
          name="contractor-name"
          placeholder="填寫承包商名稱"
        />
      </div>

      <div class="form-section">
        <label for="work-content">作業內容</label>
        <textarea
          id="work-content"
          [(ngModel)]="formData.workContent"
          name="work-content"
          placeholder="填寫作業內容"
        ></textarea>
      </div>

      <div class="form-section">
        <label>作業類別</label>
        <div class="checkbox-container">
          <div *ngFor="let category of workCategories" class="checkbox-item">
            <!-- 設置 id 與 label for 屬性匹配，這樣 label 可以點擊選擇 checkbox -->
            <input type="checkbox" [(ngModel)]="category.selected" name="work-category" [id]="'checkbox-' + category.name" />
            <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
          </div>
        </div>
      </div>

      <div class="form-section">
        <label for="work-location">工作地點</label>
        <input
          type="text"
          id="work-location"
          [(ngModel)]="formData.workLocation"
          name="work-location"
          placeholder="填寫工作地點"
        />
      </div>

      <div class="form-section">
        <label for="scheduled-start-date">預定開始日期</label>
        <input
          type="date"
          id="scheduled-start-date"
          [(ngModel)]="formData.scheduledStartDate"
          name="scheduled-start-date"
        />
      </div>

      <div class="form-section">
        <label for="scheduled-end-date">預定結束日期</label>
        <input
          type="date"
          id="scheduled-end-date"
          [(ngModel)]="formData.scheduledEndDate"
          name="scheduled-end-date"
        />
      </div>

      <div class="form-section">
        <label for="persons-in-charge">負責人</label>
        <input
          type="text"
          id="persons-in-charge"
          [(ngModel)]="formData.personsInCharge"
          name="persons-in-charge"
          placeholder="填寫負責人"
        />
      </div>

      <div class="form-section">
        <label for="safety-official">工安人員</label>
        <input
          type="text"
          id="safety-official"
          [(ngModel)]="formData.safetyOfficial"
          name="safety-official"
          placeholder="填寫工安人員"
        />
      </div>

      <div class="form-section">
        <label for="additional-info">附加資訊</label>
        <textarea
          id="additional-info"
          [(ngModel)]="formData.additionalInfo"
          name="additional-info"
          placeholder="填寫附加資訊"
        ></textarea>
      </div>


      <div class="form-section">
        <label>主承攬現場負責人簽名</label>
        <canvas id="ownerSignatureCanvas" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature"  (click)="clearSignature('ownerSignatureCanvas')">清除簽名</button>
      </div>

      <div class="form-section">
        <label>主承攬商工安人員簽名</label>
        <canvas id="safetySignatureCanvas" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature('safetySignatureCanvas')">清除簽名</button>
      </div>

      <button type="submit">提交</button>
    </form>
  </div>
</div>
