import { Injectable } from '@angular/core';

export interface MenuButton {
  icon: string;
  text: string;
  action: () => void;
  tooltip: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  getSideMenuIcons(
    companyCode: string,
    vectorStoreId: string,
    callbacks: {
      gotolobby: () => void;
      toggleVisibility: () => void;
      adjustFontSize: (direction: 'increase' | 'decrease') => void;
      clearmsg: () => void;
      gotoformlist:()=> void;
      gotoRAGDatabase:()=> void;
      gotoChat:()=> void;
      gotoToDolist:()=> void;
      gotoReportCenter:()=> void;
    }
  ): MenuButton[] {

    const buttons: MenuButton[] = [
      {
        icon: 'fa-regular fa-circle-left',
        text: '',
        action: callbacks.gotolobby,
        tooltip: 'Home',
      },
      {
        icon: 'fa-solid fa-circle-info',
        text: '',
        action: callbacks.toggleVisibility,
        tooltip: 'Show Instruction',
      }
    ];

    if (companyCode === 'Tpic') {
      buttons.push(
        {
          icon: 'fa-solid fa-magnifying-glass-plus',
          text: '',
          action: () => callbacks.adjustFontSize('increase'),
          tooltip: '放大字體',
        },
        {
          icon: 'fa-solid fa-magnifying-glass-minus',
          text: '',
          action: () => callbacks.adjustFontSize('decrease'),
          tooltip: '縮小字體',
        }
      );
    }

    if (companyCode === 'Quallico' || companyCode === 'JSAI' || companyCode === 'YH') {
      if (vectorStoreId) {
        buttons.push({
          icon: 'fa-solid fa-database',
          text: '',
          action: callbacks.gotoRAGDatabase,
          tooltip: 'Files Center',
        });
      }
    }


    buttons.push({
      icon: 'fa-solid fa-trash',
      text: '',
      action: callbacks.clearmsg,
      tooltip: 'clear message',
    });

    return buttons;
  }
}
