import { Component, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatabaseService } from '../../../services/database.service';
import SignaturePad from 'signature_pad';  // 引入 signature_pad

import {FormLinkerComponent} from '../../connector/form-linker/form-linker.component'


@Component({
  selector: 'app-corrective-action-form',
  standalone: true,
  imports: [FormsModule, CommonModule,FormLinkerComponent],
  templateUrl: './corrective-action-form.component.html',
  styleUrl: './corrective-action-form.component.scss'
})
export class CorrectiveActionFormComponent implements AfterViewInit, OnDestroy {
  @Output() valueSubmitted = new EventEmitter<any>();

  formfinished = false;

  // 表单数据
  formData = {
    ncID: '',
    criticality: '',
    date: '',
    dueDate: '',
    immediateAction: '',
    correctiveAction: '',
    reviewApprovalDate: '',
    rootCause: '',
    reviewApprovalPerson: '',
  };

  // Criticality 选项
  criticalityOptions = ['critical', 'major', 'minor'];
  // Root Cause 选项
  rootCauseOptions = ['Link to root cause analysis 1', 'Link to root cause analysis 2'];

  // 文件上传相关
  selectedFile: File | null = null;

  // 签名板相关
  private signaturePad: SignaturePad | undefined;

  // 签核流程
  approvalProcess = {
    id: 'process-001',
    steps: [
      {
        stepName: 'Step 1',
        approvers: [
          { approver: 'Alice', status: 'pending', timestamp: '', comment: '' },
          { approver: 'Bob', status: 'pending', timestamp: '', comment: '' }
        ],
        status: 'pending',
        timestamp: '',
        comment: ''
      },
      {
        stepName: 'Step 2',
        approvers: [
          { approver: 'Charlie', status: 'pending', timestamp: '', comment: '' },
        ],
        status: 'not-started', // 最初为未开始
        timestamp: '',
        comment: ''
      },
      {
        stepName: 'Step 3',
        approvers: [
          { approver: 'David', status: 'not-started', timestamp: '', comment: '' },
        ],
        status: 'not-started', // 最初为未开始
        timestamp: '',
        comment: ''
      },
    ]
  };

  constructor(private databaseService: DatabaseService) {}

  ngAfterViewInit() {
    const canvas = document.getElementById('signatureCanvas') as HTMLCanvasElement;
    this.signaturePad = new SignaturePad(canvas);
    this.updateCanvasSize();
    window.addEventListener('resize', this.updateCanvasSize.bind(this)); // 监听窗口大小变化
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateCanvasSize.bind(this)); // 清理事件监听
  }

  updateCanvasSize() {
    if (this.signaturePad) {
      const canvas = document.getElementById('signatureCanvas') as HTMLCanvasElement;
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;

      canvas.width = width;
      canvas.height = height;
      this.signaturePad.clear();
    }
  }

  getStatusClass(status: string) {
    switch (status) {
      case 'approved':
        return 'approved';
      case 'rejected':
        return 'rejected';
      case 'pending':
        return 'pending';
      case 'not-started':
        return 'not-started';
      default:
        return '';
    }
  }

  // 当所有签核人完成签核后，更新当前步骤为已批准/已拒绝
  approveStep(step: any, approver: any) {
    approver.status = 'approved';
    approver.timestamp = new Date().toISOString();
    approver.comment = 'Approved by user'; // 你可以根据需求添加签核意见

    // 检查所有签核人是否已签署，若已签署，更新步骤状态
    if (step.approvers.every((a: any) => a.status !== 'pending')) {
      step.status = 'approved';
      this.updateNextStepStatus();
    }
  }

  rejectStep(step: any, approver: any) {
    approver.status = 'rejected';
    approver.timestamp = new Date().toISOString();
    approver.comment = 'Rejected by user'; // 你可以根据需求添加签核意见

    // 更新步骤状态
    step.status = 'rejected';
    this.updateNextStepStatus();
  }

  // 更新下一个步骤的状态为 "pending"
  updateNextStepStatus() {
    // 找到第一个 `not-started` 状态的步骤
    const nextStep = this.approvalProcess.steps.find((step) => step.status === 'not-started');
    if (nextStep) {
      nextStep.status = 'pending'; // 确保只有一个步骤处于进行中
    }
  }

  goBack(step: any) {
    // 退回到上一个步骤
    const currentStepIndex = this.approvalProcess.steps.indexOf(step);
    if (currentStepIndex > 0) {
      const previousStep = this.approvalProcess.steps[currentStepIndex - 1];
      previousStep.status = 'pending';
      previousStep.approvers.forEach((approver: any) => {
        approver.status = 'pending';
        approver.timestamp = '';
        approver.comment = '';
      });

      // 更新当前步骤状态为“已退回”
      step.status = 'rejected';
      step.approvers.forEach((approver: any) => {
        approver.status = 'rejected';
      });
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  getSignatureData() {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      return this.signaturePad.toDataURL();
    }
    return null;
  }

  clearSignature() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  async sendValue() {

    alert("sendvalue");

    console.log('Form data:', this.formData);

    const signatureData = this.getSignatureData();
    const formData = new FormData();
    formData.append('formData', JSON.stringify(this.formData));

    if (this.selectedFile) {
      formData.append('file', this.selectedFile);
    }

    if (signatureData) {
      formData.append('signature', signatureData);
    }

    try {
      const response = await this.databaseService.sendDataToBackend(formData);
      console.log('Response from backend:', response);
    } catch (error) {
      console.error('Error submitting data:', error);
    }

    this.formfinished = true;
  }
}
