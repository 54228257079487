import { Component, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-hazard-notification-and-inspection-record',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hazard-notificationand-inspection-record.component.html',
  styleUrls: ['./hazard-notificationand-inspection-record.component.scss'],
})
export class HazardNotificationandInspectionRecordComponent implements AfterViewInit, OnDestroy {
  @Output() valueSubmitted = new EventEmitter<any>();

  currentTime: string = '';
  formfinished = false;

  // 签名板相关
  private signaturePad: SignaturePad | undefined;

  constructor() {}

  ngOnInit(): void {
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000); // 每秒更新時間
  }

  updateTime(): void {
    const now = new Date();
    this.currentTime = now.toLocaleString(); // 格式化時間
  }

  ngAfterViewInit() {
    const canvas = document.getElementById('signatureCanvas') as HTMLCanvasElement;
    this.signaturePad = new SignaturePad(canvas);
    this.updateCanvasSize();
    window.addEventListener('resize', this.updateCanvasSize.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateCanvasSize.bind(this));
  }

  updateCanvasSize() {
    if (this.signaturePad) {
      const canvas = document.getElementById('signatureCanvas') as HTMLCanvasElement;
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;

      canvas.width = width;
      canvas.height = height;
      this.signaturePad.clear();
    }
  }

  getSignatureData() {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      return this.signaturePad.toDataURL();
    }
    return null;
  }

  clearSignature() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  async sendValue() {
    alert("sendValue");

    const signatureData = this.getSignatureData();

    // Here you can handle the submission of data accordingly,
    // but for now it's just a placeholder.
    console.log('Signature Data:', signatureData);

    // You may want to process this signatureData as per your requirements.

    this.formfinished = true;
  }
}
