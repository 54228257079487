import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-form-number-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label [attr.for]="id">{{ label }}</label>
      <input
        type="number"
        [id]="id"
        [name]="name"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        class="form-input"
      />
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormNumberInputComponent),
      multi: true,
    },
  ],
  styleUrls: ['total-component.scss']
})
export class FormNumberInputComponent implements ControlValueAccessor {
  @Input() label: string = 'Label';
  @Input() id: string = 'number-input';
  @Input() name: string = 'number-input';
  @Input() placeholder: string = '';

  value: number | null = null;

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // 如有需要，可在這裡加入 disabled 狀態的處理
  }
}
