import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { ComponentMapping, ComponentMappingCollection } from '../models/component-map.model';
import { Observable, of, map, catchError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComponentMappingService {

  private containerName = 'configs';
  private companyid = environment.companyname;

  constructor(private databaseService: DatabaseService) {}

  /**
   * 取得元件對應表
   */
  getComponentMappings(): Observable<ComponentMapping[]> {

    //目前這邊所有公司都共用，之後要依據公司，甚至模組拆開來
    const filter = { configname: "hotkey" };

    return this.databaseService.getDataByQuery(filter, 1, this.containerName).pipe(
      map((response: any) => {


        if (!response?.items || response.items.length === 0) {
          console.log('No component mappings found, returning default');
          return this.getDefaultMappings();
        }

        // 從 items 陣列中提取元件對應資訊
        return response.items[0].items as ComponentMapping[];
      }),
      catchError(error => {
        console.error('Error fetching component mappings:', error);
        return of(this.getDefaultMappings());
      })
    );
  }

  /**
   * 儲存元件對應表
   */
  // saveComponentMappings(mappings: ComponentMapping[]): Observable<any> {
  //   const filter = { companyid: this.companyid };

  //   return this.databaseService.getDataByQuery(filter, 1, this.containerName).pipe(
  //     map((response: any) => {
  //       const data: ComponentMappingCollection = {
  //         companyid: this.companyid,
  //         mappings: mappings
  //       };

  //       if (!response?.items || response.items.length === 0) {
  //         // 新增記錄
  //         return this.databaseService.addData(data, this.containerName);
  //       } else {
  //         // 更新記錄
  //         data.id = response.items[0].id;
  //         return this.databaseService.updateData(data, this.containerName);
  //       }
  //     }),
  //     catchError(error => {
  //       console.error('Error saving component mappings:', error);
  //       throw error;
  //     })
  //   );
  // }

  /**
   * 預設的元件對應表，當 DB 中沒有資料時使用
   */
  private getDefaultMappings(): ComponentMapping[] {
    return [
      // { keyword: 'Demo-新增稽核缺失', component: 'demoformcomponent', jsondata: '' },
      // { keyword: 'demoformcomponent', component: 'demoformcomponent', jsondata: '' },
      // { keyword: 'rqcode', component: 'RqcodeComponent', jsondata: '' },
      // ... 其他預設對應
    ];
  }
}
