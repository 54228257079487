import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label [attr.for]="id">{{ label }}</label>
      <select
        [id]="id"
        [name]="name"
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        class="form-input"
      >
        <option value="" disabled>{{ placeholder }}</option>
        <option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true
    }
  ],
  styleUrls: ['total-component.scss'] // 自行定義樣式（非必要）
})
export class FormSelectComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '請選擇';
  // options 預期為一個物件陣列，每個物件包含 label 與 value
  @Input() options: { label: string; value: any }[] = [];

  value: any;

  // ControlValueAccessor callback 函數
  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // 如有需要可加入 disabled 狀態
  }
}
