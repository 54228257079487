import { ConfigurationService } from './../../services/configuration.service';
import { Component, CSP_NONCE, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleService, AssistantDataItem } from '../../services/modules.service';
import { environment } from '../../environments/environment';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-lobby',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './lobby.component.html',
  styleUrl: './lobby.component.scss',
})


export class LobbyComponent implements OnInit {
  AssistantDataItem: AssistantDataItem[] = [];
  username = '';
  modules = '';

  lobbyinfotext = '';
  show_nopermission = false;


  constructor(
    private moduleservice: ModuleService,
    private router: Router, // 注入 Router
    private renderer: Renderer2
  ) {}

  companyname = environment.companyname;

  ngOnInit() {
    // 取得存在 LocalStorage 的值
    this.username = localStorage.getItem('username') || '';
    this.modules = localStorage.getItem('modules') || '';

    interface ModuleItem {
      assistantNo: string;
      permission?: string;
    }

    // console.log("this.modules",this.modules);


    var result = JSON.parse(this.modules);

    // console.log("result",result);

    var allowedAssistantNos:string[] = [];

    // console.log("result.assistantNo",result.assistantNo)

    if(this.modules.includes("assistantNo")){
      const modulesArray: ModuleItem[] = JSON.parse(this.modules);
      // 將 modulesArray 轉成只包含 assistantNo 的字串陣列
      allowedAssistantNos = modulesArray.map(m => m.assistantNo);
    }
    else{
    //要特別處理TPIC的情況
      allowedAssistantNos = JSON.parse(this.modules);
    }

    // console.log("allowedAssistantNos",allowedAssistantNos)


    // 取得大廳資訊
    this.moduleservice.get_company_and_module_setting(this.companyname).subscribe((response) => {
      if (!response) {
        console.error('未收到回應數據');
        return;
      }


      this.show_nopermission = response.show_nopermission || false;

      if (allowedAssistantNos.length === 1 && allowedAssistantNos[0] === 'ALL') {
        this.AssistantDataItem = response.lobby;
      } else {
        if (this.show_nopermission) {

          //要將title是admin的拿掉

          // 包含所有項目，但將不在權限清單中的項目 enable 設為 false，並依權限排序
          this.AssistantDataItem = response.lobby
            .map(item => ({
              ...item,
              enable: allowedAssistantNos.includes(item.assistantNo) ? item.enable : false
            }))
            .sort((a, b) => {
              const aHasPermission = allowedAssistantNos.includes(a.assistantNo) ? 1 : 0;
              const bHasPermission = allowedAssistantNos.includes(b.assistantNo) ? 1 : 0;
              return bHasPermission - aHasPermission;
            });





            if(allowedAssistantNos[0] != 'ALL'){
              this.AssistantDataItem = this.AssistantDataItem.filter(item =>
                item.title.toLowerCase() !== 'admin'
              );
            }


        } else {
          // 只包含有權限的項目
          this.AssistantDataItem = response.lobby.filter(item =>
            allowedAssistantNos.includes(item.assistantNo)
          );
        }
      }

    });
  }
  gotoAI(assistantid: string) {
    //另開分頁到AI助手
    this.router.navigate(['/assistant'], {
      queryParams: { id: assistantid },
    });
  }
}
