import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AzureBlobService } from '../../services/azure-blob.service';  // Make sure to import your AzureBlobService

export interface Photo {
  fileName: string;
  fileUrl: string;
}

@Component({
  selector: 'app-form-photo-upload',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label for="{{ id }}">{{ label }}</label>
      <input
        type="file"
        [id]="id"
        [name]="name"
        (change)="handlePhotoUpload($event)"
        accept="image/*"
        multiple
      />
      <div class="photo-preview">
        <div *ngFor="let photo of photos; let i = index" class="photo-item">
          <img [src]="photo.fileUrl" alt="Preview" />
          <button type="button" (click)="removePhoto(i)">X</button>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPhotoUploadComponent),
      multi: true
    }
  ],
  styleUrls: ['total-component.scss'] // Add custom styling
})
export class FormPhotoUploadComponent implements ControlValueAccessor {
  @Input() label: string = '缺失照片 (可上傳多張，並可刪除)';
  @Input() id: string = '';
  @Input() name: string = '';

  @Output() photosChange = new EventEmitter<Photo[]>();

  photos: Photo[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private azureBlobService: AzureBlobService) {}

  // Handle photo upload and push the uploaded photo URL
  handlePhotoUpload(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      files.forEach((file) => {
        // Upload file to Azure Blob Storage
        this.azureBlobService.uploadFile(file, 'YourCompanyName', 'fileupload')
          .then((fileUrl: string) => {
            const photoItem: Photo = {
              fileName: file.name,
              fileUrl: fileUrl  // Azure URL after upload
            };
            this.photos.push(photoItem);  // Add to photo array
            this.photosChange.emit(this.photos);  // Emit changes
            this.onChange(this.photos);  // Update model
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });
      });
    }
  }

  // Remove photo from list
  removePhoto(index: number): void {
    this.photos.splice(index, 1);
    this.photosChange.emit(this.photos);  // Emit changes
    this.onChange(this.photos);  // Update model
  }

  // Implement ControlValueAccessor
  writeValue(value: Photo[]): void {
    if (value) {
      this.photos = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle disabled state if needed
  }
}
