import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-location',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label for="{{ id }}" class="inline-label">{{ label }}
        <button type="button" class="small-button" (click)="getCurrentLocation()">Get Location</button>
      </label>
      <input
        type="text"
        [id]="id"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        [name]="name"
        [placeholder]="placeholder"
      />
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormLocationComponent),
      multi: true
    }
  ],
  styleUrls: ['total-component.scss']
})
export class FormLocationComponent implements ControlValueAccessor {
  @Input() label: string = '';  // 標籤
  @Input() id: string = '';     // id 用於對應 label
  @Input() name: string = '';   // name 用於表單提交
  @Input() placeholder: string = ''; // 佔位符

  @Output() locationChange = new EventEmitter<string>(); // 用於通知父元件變更位置

  value: string = '';  // 綁定的值

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  // 當使用者點擊按鈕時，根據當前位置設置位置
  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.value = `${latitude}, ${longitude}`;
          this.locationChange.emit(this.value);
          // 通知表單控管機制
          this.onChange(this.value);
        },
        (error) => {
          alert('Unable to retrieve current location. Please check your GPS permissions.');
        }
      );
    } else {
      alert('The browser does not support GPS positioning.');
    }
  }

  // 用於 ControlValueAccessor 實作
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // 如有需要，可以處理 disabled 狀態
  }
}
