import { Component, ElementRef, ViewChild } from '@angular/core';
import { getDocument, PDFDocumentProxy, GlobalWorkerOptions } from 'pdfjs-dist';
import { FormsModule } from '@angular/forms'; // 匯入 FormsModule
import { CommonModule } from '@angular/common'; // 匯入 CommonModule
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  standalone: true,
  imports: [FormsModule, CommonModule], // 註冊 FormsModule
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  companyname = '';
  fileUrl =
    'https://quallico.blob.core.windows.net/quallico/1005-Animal-Products-Notice-Raw-Milk-Products.pdf?sp=r&st=2025-01-28T12:45:13Z&se=2025-01-28T20:45:13Z&spr=https&sv=2022-11-02&sr=b&sig=2f0mHDR7EhCyBTdGYzXb9eAtUmhdZb%2F6P85kpgAARzc%3D'; // PDF 檔案的 URL
  keyword = 'sample'; // 要搜尋的關鍵字
  targetPage = 2; // 預設頁面，從外面送進來

  currentPage = 1;
  totalPages = 0; // PDF 總頁數
  scale = 1.5; // 初始縮放比例
  rotation = 0; // 初始旋轉角度
  pageNumbers: number[] = []; // 用於生成下拉選單的頁數選項

  pdfInstance: PDFDocumentProxy | null = null;
  pdfData: ArrayBuffer | null = null; // 用於存儲下載的 PDF 文件數據
  @ViewChild('pdfCanvas', { static: false })
  canvasRef!: ElementRef<HTMLCanvasElement>;

  constructor(private cdr: ChangeDetectorRef) {
    GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.mjs';
  }

  ngOnInit(): void {
    this.currentPage = this.targetPage;

    this.downloadPDF(); // 初始化時下載 PDF
  }

  async downloadPDF() {
    try {
      const response = await fetch(this.fileUrl); // 下載 PDF 文件

      if (!response.ok) {
        throw new Error(`無法下載 PDF 文件，狀態碼: ${response.status}`);
      }
      this.pdfData = await response.arrayBuffer(); // 將文件轉換為 ArrayBuffer
      console.log('PDF 文件下載完成');
      this.loadPDF(); // 在成功下載後載入 PDF
    } catch (error) {
      console.error('下載 PDF 文件時發生錯誤:', error);
      alert('無法下載 PDF 文件，請檢查 URL 或網絡連接。');
    }
  }

  async downloadFile() {
    if (!this.fileUrl) {
      alert('無效的 PDF 文件 URL');
      return;
    }

    try {
      // 發送請求獲取檔案
      const response = await fetch(this.fileUrl);
      if (!response.ok) {
        throw new Error(`無法下載 PDF 文件，狀態碼: ${response.status}`);
      }

      // 獲取檔案名稱
      const contentDisposition = response.headers.get('Content-Disposition');
      let fileName = 'downloaded-file.pdf'; // 預設檔名
      if (contentDisposition && contentDisposition.includes('filename=')) {
        const matches = contentDisposition.match(/filename="(.+)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      } else {
        // 如果沒有 Content-Disposition，從 URL 中提取檔案名稱
        const urlParts = this.fileUrl.split('/');
        fileName = urlParts[urlParts.length - 1].split('?')[0];
      }

      // 轉換為 Blob
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // 創建下載連結
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // 使用提取的檔案名稱
      a.click();

      // 釋放 URL 資源
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('下載 PDF 文件時發生錯誤:', error);
      alert('無法下載 PDF 文件，請檢查 URL 或網絡連接。');
    }
  }

  loadPDF() {
    if (!this.pdfData) {
      alert('PDF 文件尚未下載完成');
      return;
    }

    getDocument({ data: this.pdfData }).promise.then((pdf) => {
      this.pdfInstance = pdf;
      this.totalPages = pdf.numPages; // 設定 PDF 總頁數

      // console.log("this.totalPages",this.totalPages);

      this.pageNumbers = Array.from(
        { length: this.totalPages },
        (_, i) => i + 1
      ); // 生成頁數選項

      // console.log("this.pageNumbers",this.pageNumbers);

      this.cdr.detectChanges(); // 強制刷新模板

      this.renderPage(this.currentPage); // 渲染第一頁
    });
  }

  renderPage(pageNumber: number) {
    console.log(pageNumber);

    const pageNumberInt = parseInt(pageNumber.toString().trim(), 10);

    this.currentPage = pageNumberInt;

    if (!this.pdfInstance) return;

    this.pdfInstance.getPage(pageNumberInt).then((page) => {
      const viewport = page.getViewport({
        scale: this.scale,
        rotation: this.rotation,
      });

      const canvas = this.canvasRef.nativeElement;
      const context = canvas.getContext('2d');
      if (!context) return;

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      page.render(renderContext);
    });
  }

  zoomIn() {
    this.scale += 0.2; // 放大
    this.renderPage(this.currentPage);
  }

  zoomOut() {
    if (this.scale > 0.5) {
      this.scale -= 0.2; // 縮小
      this.renderPage(this.currentPage);
    }
  }

  rotate() {
    this.rotation = (this.rotation + 90) % 360; // 每次旋轉 90 度
    this.renderPage(this.currentPage);
  }

  searchKeyword() {


    console.log("searchKeyword",this.currentPage,this.keyword);

    if (!this.keyword) return;

    this.pdfInstance?.getPage(this.currentPage).then(async (page) => {
      const textContent = await page.getTextContent();
      const items = textContent.items as any[];

      items.forEach((item) => {
        if (item.str.includes(this.keyword)) {
          console.log(
            `找到關鍵字: ${this.keyword}，在頁面: ${this.currentPage}`
          );
          // 可在這裡實現高亮邏輯
        }
      });
    });
  }

  goToPage() {
    if (
      this.currentPage < 1 ||
      !this.pdfInstance ||
      this.currentPage > this.totalPages
    ) {
      alert('無效的頁碼');
      return;
    }
    this.renderPage(this.currentPage);
  }

  goToPageFromList(page: number) {
    this.currentPage = page; // 更新當前頁碼
    this.renderPage(page);
  }
}
