import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-simulate-exam',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './simulate-exam.component.html',
  styleUrls: ['./simulate-exam.component.scss']
})
export class SimulateExamComponent {

  @Input() classname :string = ""; //科目名稱
  @Input() questions: Array<{
    text: string;
    options: string[];
    correctAnswerIndex: number[];
    selected: string | null;
    isCorrect?: boolean; // 新增屬性來標記答題正確性
  }> = []; // 從母元件傳入的題目資料
  @Input() totalminutes: number =80; // 考試時間 - 預設是80分鐘

  @Output() valueSubmitted = new EventEmitter<any>(); // 用於回傳數據
  @Output() explanationGenerated = new EventEmitter<string>(); // 定義輸出事件

  remainingTime: string = ''; // 倒數計時器預設顯示的文字
  timer: any;
  currentQuestionIndex: number = 0; // 當前題目索引
  jumpToIndex: number = 0; // 跳題的下拉選單值
  answeredCount: number = 0; // 已回答題目數
  showResult: boolean = false; // 是否顯示答題結果

  constructor() {

  }

  ngOnInit() {
    // 在 ngOnInit 中啟動計時器，才吃到到母元件送進來的值
    this.startTimer();
    this.updateAnsweredCount();
  }

  startTimer() {
    let time = this.totalminutes * 60; // 80 分鐘轉為秒
    this.timer = setInterval(() => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      this.remainingTime = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      if (time === 0) {
        clearInterval(this.timer);
        alert('時間到，請提交答案！');
      }
      time--;
    }, 1000);
  }

  prevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
    }
  }

  jumpToQuestion() {
    this.currentQuestionIndex = Number(this.jumpToIndex); // 確保是數字類型
  }

  selectAnswer(option: string) {
    this.questions[this.currentQuestionIndex].selected = option;
    this.updateAnsweredCount();
  }

  submitAnswers() {
    clearInterval(this.timer);

    let correctCount = 0;

    this.questions.forEach((question) => {
      // 確保 correctAnswerIndex 是一個陣列
      if (!Array.isArray(question.correctAnswerIndex)) {
        console.error(`Invalid correctAnswerIndex for question:`, question);
        question.isCorrect = false;
        return;
      }

      const selectedIndex = question.options.indexOf(question.selected!);
      question.isCorrect = question.correctAnswerIndex.includes(selectedIndex);

      if (question.isCorrect) {
        correctCount++;
      }
    });

    console.log('提交答案:', this.questions);
    alert(`答案已提交！您答對了 ${correctCount} 題，共 ${this.questions.length} 題。`);

    // 顯示結果
    this.showResult = true;

    // 回傳提交結果給母元件
    this.valueSubmitted.emit({
      correctCount,
      total: this.questions.length,
      questions: this.questions,
    });
  }

  updateAnsweredCount() {
    this.answeredCount = this.questions.filter(q => q.selected !== null).length;
  }

  goToQuestion(index: number) {
    this.currentQuestionIndex = index; // 更新當前題目索引
    this.jumpToIndex = index; // 更新跳題選單的值（如果需要同步顯示）
  }

  goToExplan(index: number) {
    // 更新當前題目索引和跳題選單值
    this.currentQuestionIndex = index;
    this.jumpToIndex = index;

    // 取得題目內容和答案
    const questiontext = this.questions[index].text; // 題目文字
    const correctIndexes = this.questions[index].correctAnswerIndex; // 答案索引
    const options = this.questions[index].options; // 選項

    // 獲取正確答案的選項文字
    const correctAnswers = correctIndexes.map((i) => options[i]).join(", ");

    // 替換模板中的佔位符
    const content = `在你提供的題目中，為什麼 "${questiontext}" 的答案是 "${correctAnswers}" 呢？`;

    console.log("生成的內容:", content);

    // 此處可以將 `content` 發送給其他處理邏輯，如展示詳解或發送至其他服務

    // 發送內容到母元件
    this.explanationGenerated.emit(content);

  }

}
