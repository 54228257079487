import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'app-demoform',
  standalone: true,
  imports: [FormsModule, CommonModule], // Add CommonModule
  templateUrl: './demoform.component.html',
  styleUrls: ['./demoform.component.scss'],
})
export class DemoformComponent {
  @Output() valueSubmitted = new EventEmitter<any>();

  formfinished = false;

  // 表單資料
  formData = {
    suggestionContent: '',
    responsibleUnit: '',
    improvementStatus: '',
    auditor: '',
    reportTime: '',
    auditLocation: '',
    photos: [] as { file: File; preview: string }[],
  };

  // 單位選項
  DepartOptions = ['管理部門A', '管理部門B', '管理部門C'];

  constructor() {
    // 預設提報時間為當下時間
    this.formData.reportTime = new Date().toISOString().slice(0, 16); // ISO 格式，適配 <input type="datetime-local">
  }

  // 獲取當前 GPS 定位
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.formData.auditLocation = `緯度: ${latitude}, 經度: ${longitude}`;
        },
        (error) => {
          alert('無法獲取當前位置，請檢查 GPS 權限。');
        }
      );
    } else {
      alert('瀏覽器不支援 GPS 定位功能。');
    }
  }

  // 處理照片上傳
  handlePhotoUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.formData.photos.push({
            file,
            preview: e.target?.result as string,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  // 移除照片
  removePhoto(index: number) {
    this.formData.photos.splice(index, 1);
  }

  //提交表單
  //將資料發射到母頁面
  sendValue() {
    this.valueSubmitted.emit(this.formData);
    this.formfinished = true;
  }
}
