<div class="container-fluid">
  <div class="row">

<!-- 左側區塊 -->
<div id="menudiv" class="col-1 d-none d-lg-flex flex-column align-items-center">
  <button
    class="btn btn-custom mb-2"
    *ngFor="let btn of menuButtons"
    [title]="btn.tooltip"
     (click)="btn.action()"
  >
    <i [class]="btn.icon"></i>
  </button>
</div>

<div id="chatdiv" class="col-12 col-lg-10">

  <div id="chatcontainer" [hidden]="showtarget!='chat'">

   <!-- <span class="close-icon" (click)="closeDiv()" *ngIf="!showintroduction">×</span> -->
  <!-- <i *ngIf="showintroduction && vector_store_id !== ''" class="fa-solid fa-sliders setup-icon"
    (click)="gotofilecenter()" title="Go to File Center"></i> -->

  <div id="Introduction" *ngIf="showintroduction">

    <div class="container" style="margin-left: 0px">
      <div class="image-column">
        <img *ngIf="init_image" [src]="init_image" width="150px" alt="image">
        <div *ngIf="!init_image" class="assistant_imagebyword">R</div>
      </div>

      <div class="text-column">
        <p [innerHTML]="init_word"></p>
        <div class="menubuttondiv">
          <button class="p-button p-component p-element p-button inline-btn p-button-primary mr-2 my-1"
            style="margin-right: 5px;" *ngFor="let item of init_meun"
            (click)="menubuttonclick(item.title,item.content,item.functionname)" [disabled]="!item.enable">
            {{ item.title }}
          </button>
        </div>

      </div>
    </div>
  </div>

  <div id="chat-container" class="chat-container">
    <div *ngFor="let message of chatMessages; let i = index" [ngClass]="message.role">

      <div *ngIf="message.role === 'assistant'" class="assistant-message">
        <div class="icon-column">
          <img src="{{ chatavator }}" alt="Assistant Icon" class="assistant-icon" />
        </div>
        <div class="content-column">
          <app-markdown-viewer [markdownContent]="message.content"></app-markdown-viewer>
          <ng-container #dynamicComponentContainers></ng-container>
        </div>
      </div>

      <!-- 用戶的訊息 -->
      <div *ngIf="message.role === 'user'">
        <div class="image-preview" *ngIf="!!message.images && message.images.length > 0">
          <a *ngFor="let img of message.images" [href]="img" target="_blank">
            <img [src]="img" alt="Sent Image" />
          </a>
        </div>
        <div [innerHTML]="message.content"></div>
      </div>
    </div>

    <div class="button-set" *ngIf="actionbuttons && actionbuttons.length > 0">
      <button
        *ngFor="let btn of actionbuttons"
        class="p-button p-component p-element p-button inline-btn p-button-primary mr-2 my-1"
         (click)="btn.action && btn.action()"
      >
        {{ btn.text }}
      </button>
    </div>
  </div>


  <div id="centerdiv">

    <div class="chat-input-container">

      <div class="image-preview-container" *ngIf="imagePreviews.length > 0">
        <div class="image-preview" *ngFor="let preview of imagePreviews; let i = index">
          <div class="image-wrapper">

            <a *ngIf="!preview.uploading" [href]="preview.url" target="_blank">
              <img [src]="preview.url" alt="Image Preview" />
            </a>

            <!-- 移除圖片按鈕 -->
            <button *ngIf="!preview.uploading" class="remove-image-button" (click)="removeImage(i)">
              <i class="fa-solid fa-x"></i>
            </button>
          </div>
        </div>
      </div>


      <div class="input-area">
        <!-- 檔案上傳按鈕 -->
        <button *ngIf="tools_fileupload" type="button" class="upload-button" (click)="triggerFileUpload()" title="上傳檔案">
          <i class="fa-solid fa-paperclip"></i>
        </button>

        <!-- 隱藏的檔案上傳框 -->
        <input type="file" accept=".jpg, .jpeg, .png" id="fileInput" (change)="onFilesSelected($event)" multiple
          hidden />

        <!-- QR Code 拍照上傳按鈕 -->
        <!-- <button *ngif type="button" class="qr-code-button" (click)="triggerQRCodeUpload()" title="拍照上傳 QR Code">
          <i class="fa-solid fa-qrcode"></i>
        </button> -->

        <!-- 隱藏的檔案上傳框 -->
        <!-- <input type="file" accept="image/*" id="qrCodeInput" (change)="onQRCodeUpload($event)" capture="environment"
          hidden /> -->

        <!-- 輸入框 -->
        <textarea [(ngModel)]="userMessage" placeholder="Ask anything" (input)="autoResize($event)"
          (keydown)="onKeyDown($event)"></textarea>

        <!-- 送出按鈕固定在右下角 -->
        <!-- <button type="button" class="send-button" (click)="chatwithAI(userMessage)">➤</button> -->
        <button type="button" class="send-button" (click)="chatwithAI(userMessage)" [disabled]="isSendingMessage">
          <span *ngIf="isSendingMessage" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!isSendingMessage">➤</span>
        </button>
      </div>
    </div>

  </div>
</div>

<app-filebank *ngIf="showtarget == 'file'" [vector_store_id]="vector_store_id"></app-filebank>

<!-- <div  #filebankcontainer ></div> -->


</div>


 <!-- 右側區塊 -->
 <div id="toolsdiv" class="col-1 d-none d-lg-flex flex-column align-items-center" >

  <!-- <div *ngIf="showtarget == 'chat'">
  <button
    class="btn btn-custom mb-2"
    title="Show Info"
     (click)="toggleVisibility()"
  >
    <i class="fa-regular fa-eye-slash"></i>
  </button>
  <button
    class="btn btn-custom mb-2"
    title="Clear Chat"
     (click)="clearmsg()"
  >
    <i class="fa-regular fa-trash-can"></i>
  </button>

</div> -->


</div>
</div>
</div>




