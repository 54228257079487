<div class="blob-explorer" style="min-height: 500px;">
  <!-- Folder section: displayed as horizontal tabs -->
  <div class="folders" *ngIf="folders.length > 0">
    <div class="folder-tabs-container" style="overflow-x: auto; max-width: 100%;">
      <div *ngIf="!showNewFolderInput" class="add-folder-button" (click)="showNewFolderInput = true" >➕</div>
      <input *ngIf="showNewFolderInput" type="text" #newFolderName (blur)="handleNewFolderBlur(newFolderName.value)" (keydown.enter)="handleNewFolderEnter(newFolderName.value)" placeholder="New folder name" [class.error]="isDuplicateFolderName" />
      <div *ngIf="isDuplicateFolderName" class="error-message">Folder name already exists</div>
      <ul class="folder-tabs">
        <li
          *ngFor="let folder of folders"
          [class.active]="folder.fullPath === selectedFolder"
          (click)="selectFolder(folder.fullPath)">
          📁 {{ folder.name }} ({{ folder.fileCount }})
        </li>
      </ul>
    </div>
    <!-- <div *ngIf="showNewFolderInput" class="new-folder-input">

    </div> -->
  </div>

  <!-- File upload section -->
  <div style="margin: 5px 0;" *ngIf="selectedFolder && files.length >= 0">
    <button type="button" data-bs-toggle="modal" data-bs-target="#fileUploaderModal">
      <i class="fa-solid fa-file-arrow-up mx-2"></i>Upload Files
    </button>
  </div>

  <!-- File list section: displayed as a table with file name, last modified date, and action buttons -->
  <div class="files" *ngIf="selectedFolder && files.length >= 0" style="overflow-x: auto; max-width: 100%;">
    <table class="file-table">
      <thead>
        <tr>
          <th style="width: 5%;">#</th>
          <th style="width: 15%;">Actions</th>
          <th style="width: 50%;">File Name</th>
          <th style="width: 20%;">Last Modified</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of files; let i = index" (mouseover)="highlightRow($event)" (mouseout)="unhighlightRow($event)">
          <td>{{ i + 1 }}</td>
          <td>
            <button class="btn btn-danger btn-sm" (click)="deleteFile(file.name)">
              <i class="fa fa-trash"></i>
            </button>
            <button class="btn btn-primary btn-sm ms-2" *ngIf="complianceMode" (click)="openParseModal(file.name, parseModal)">解析</button>
          </td>
          <td (click)="downloadFile(file.name)" style="cursor: pointer;">{{ file.name }}</td>
          <td>{{ file.lastModified | date:'yyyy/MM/dd' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Upload new version section -->
  <div class="upload-version" *ngIf="uploadVersionForFile">
    <h3>Upload new version to {{ uploadVersionForFile }}</h3>
    <input type="file" (change)="uploadNewVersion($event)" />
    <button (click)="uploadVersionForFile = null">Cancel</button>
  </div>
</div>

<!-- File uploader modal -->
<div class="modal fade" id="fileUploaderModal" tabindex="-1" aria-labelledby="fileUploaderModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploaderModalLabel">File Uploader</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- File drop area -->
        <div
          class="file-drop-area border border-primary p-3 mb-3 text-center"
          (dragover)="onDragOver($event)"
          (drop)="onFileDropped($event)"
        >
          <p>Please drag and drop files here or</p>
          <button class="btn btn-primary" (click)="fileInput.click()">Choose files</button>
          <input
            #fileInput
            type="file"
            multiple
            (change)="onFileSelected($event)"
            hidden
          />
        </div>

        <!-- File list preview -->
        <ul class="list-group">
          <li *ngFor="let uploadFile of filesToUpload; let i = index" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <!-- Display file name -->
              <span>{{ uploadFile.file.name }}</span>

              <!-- Delete file button -->
              <button *ngIf="!isUploading" class="btn btn-danger btn-sm ms-2" (click)="removeFile(i)">Delete</button>
            </div>

            <!-- Upload progress bar -->
            <div class="progress mt-2">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width.%]="uploadFile.progress"
                [attr.aria-valuenow]="uploadFile.progress"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ uploadFile.progress }}%
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button *ngIf="!isUploading" type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearFiles()">Cancel</button>
        <button *ngIf="!isUploading"
          type="button"
          class="btn btn-success"
          [disabled]="filesToUpload.length === 0"
          (click)="uploadFiles()"
        >
          Upload
        </button>
        <button *ngIf="isUploadFinished" type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearFiles()">Done</button>
      </div>

      <!-- Upload results display -->
      <div *ngIf="uploadResults.length > 0 && isUploadFinished">
        <div style="text-align: center;">Upload Results</div>
        <ul class="list-group">
          <li *ngFor="let result of uploadResults" class="list-group-item">
            <strong>{{ result.fileName }}</strong>: {{ result.status }}
            <span *ngIf="result.message" class="text-danger">{{ result.message }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- 解析模式選擇視窗 -->
<ng-template #parseModal let-modal>
  <div class="modal-dialog modal-dialog-custom">
    <div class="modal-content modal-content-custom">
      <div class="modal-header">
        <h5 class="modal-title" id="parseModalLabel">Parser Tools</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title" id="parseModalLabel">File Name - {{ fileToParse }}</h5>
        <!-- <label for="parserModelSelect">Choose Parser model:</label>
        <select id="parserModelSelect" class="form-select" [(ngModel)]="selectedParseMode">
          <option *ngFor="let mode of parseModes" [value]="mode">{{ mode }}</option>
        </select> -->
        <div class="button-set">
        <button type="button" class="btn btn-primary mt-3" [disabled]="isLoading" (click)="startParsing(modal)">
            Start
        </button>


        <button *ngIf="parserstatus>=1" type="button" class="btn btn-primary mt-3" (click)="sendToAI()">Send To AI</button>

      </div >

      <div class="page-status mt-3">
        <a *ngFor="let content of textContent" class="page-link"
          [ngClass]="{'success': content.status === 'Success', 'fail': content.status === 'Fail'}"
          (click)="content.status === 'Fail' ? retryParsing(content.page) : null">
          {{ content.page }}
        </a>
      </div>

      <div class="button-set">
        <button *ngIf="parserstatus>=1" type="button" class="btn btn-primary mt-3" [disabled]="isLoading" (click)="showContent()">
          Show Content
       </button>

       <button *ngIf="parserstatus>=2" type="button" class="btn btn-primary mt-3" (click)="showView('json')">Show JSON</button>
       <button *ngIf="parserstatus>=2" type="button" class="btn btn-primary mt-3" (click)="showView('regulation')">Show Regulation</button>
       <button *ngIf="parserstatus>=2" type="button" class="btn btn-primary mt-3" (click)="showView('checklist')">Show Checklist</button>

      </div>


        <div *ngIf="showContentFlag && viewMode === 'content'">
          <h2>原始檔案共{{textContent.length}}頁</h2>
          <div *ngFor="let content of textContent">
            <strong>Page {{ content.page }}:</strong>
            <pre [innerHTML]="replaceNewlineWithBr(content.data)"></pre>
          </div>
        </div>

        <div *ngIf="finalResult && viewMode === 'regulation'">
          <h3>法規名稱：{{ finalResult.Laws.LawName }}</h3>
          <p>異動日期：{{ finalResult.Laws.LawModifiedDate }}</p>
          <div *ngFor="let article of finalResult.LawArticles">
            <h3 *ngIf="article.ArticleType === 'C'">{{ article.ArticleContent }}</h3>
            <div *ngIf="article.ArticleType === 'A'">
              <h4>{{ article.ArticleNo }}</h4>
              <!-- <span class="priority" [ngClass]="{'high': article.Priority === 'High', 'medium': article.Priority === 'Medium', 'low': article.Priority === 'Low'}">
                {{ article.Priority }}
              </span> -->
              <p [innerHTML]="replaceNewlineWithBr(article.ArticleContent)"></p>

            </div>
          </div>
        </div>
        <div *ngIf="finalResult && viewMode === 'checklist'" class="checklist-container">
          <div class="checklist-left">
            <div *ngFor="let article of finalResult.LawArticles; let i = index" (click)="scrollToArticle(i)">
              <h3 *ngIf="article.ArticleType === 'C'">{{ article.ArticleContent }}</h3>
            </div>
          </div>
          <div class="checklist-right">
            <div *ngFor="let article of finalResult.LawArticles; let i = index" [id]="'article-' + i">
              <h3 *ngIf="article.ArticleType === 'C'">{{ article.ArticleContent }}</h3>
              <div *ngIf="article.ArticleType === 'A'">
                <h4>{{ article.ArticleNo }}</h4>
                <p [innerHTML]="replaceNewlineWithBr(article.ArticleContent)"></p>
                <div class="button-group">
                  <button type="button" class="btn btn-outline-primary" [ngClass]="{'btn-primary': article.relevant === true}" (click)="markRelevant(i)">相關</button>
                  <button type="button" class="btn btn-outline-secondary" [ngClass]="{'btn-secondary': article.relevant === false}" (click)="markIrrelevant(i)">無關</button>
                  <div *ngIf="article.relevant === true" class="compliance-buttons">
                    <button type="button" class="btn btn-outline-success" [ngClass]="{'btn-success': article.compliance === 'compliant'}" (click)="markCompliant(i)">符合</button>
                    <button type="button" class="btn btn-outline-danger" [ngClass]="{'btn-danger': article.compliance === 'non-compliant'}" (click)="markNonCompliant(i)">不符合</button>
                    <button type="button" class="btn btn-outline-warning" [ngClass]="{'btn-warning': article.compliance === 'reference'}" (click)="markReference(i)">參考</button>
                  </div>
                </div>
                <textarea class="form-control mt-2" [(ngModel)]="article.notes" placeholder="輸入備註"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="finalResult && viewMode === 'json'" class="json-editor mt-3">
          <h5>JSON Data</h5>
          <textarea class="form-control" rows="10" [(ngModel)]="jsonEditorContent" (ngModelChange)="updateFinalResult()"></textarea>
        </div>
        <!-- <div *ngIf="textContent.length > 0" class="mt-3">
          <h5>解析內容</h5>
          <div *ngFor="let content of textContent">
            <strong>Page {{ content.page }}:</strong>
            <pre>{{ content.data }}</pre>
          </div>
        </div> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveToDatabase()">Save</button>
      </div>
    </div>
  </div>
</ng-template>

