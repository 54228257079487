import { AuthService } from '../../services/auth.service';

import {
  Component,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ViewChildren,
  QueryList,
  viewChild,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OpanaiService } from '../../services/openai.service';
import { MarkdownViewerComponent } from '../../shared/markdown-viewer/markdown-viewer.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ModuleService,AssistantDataItem ,InitializedItem,InitMenuItem} from '../../services/modules.service';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';

import { Renderer2, ElementRef, AfterViewInit } from '@angular/core';

import { COMPONENT_MAP } from '../../conponents/component-map'; //元件的對應表
import { ignoreElements } from 'rxjs';

import { AzureBlobService } from '../../services/azure-blob.service';
import { MenuService,MenuButton } from '../../services/MenuService';
import {FilebankComponent} from '../filebank/filebank.component';

import { EventEmitter } from '@angular/core';

import { ComponentMappingService } from '../../services/component-mapping.service';
import { ComponentMapping } from '../../models/component-map.model';

import jsQR from 'jsqr'; // 使用 jsQR 來解析 QR Code

interface Button {
  icon?: string; // 按鈕的圖標
  text?: string; // 按鈕的文字
  action?: () => void; // 按鈕的點擊行為
  tooltip?: string; // 提示文字
}

interface Annotation {
  index: number;
  type: string;
  text: string;
  start_index: number;
  end_index: number;
  file_citation: {
    file_id: string;
    quote: string;
  };
  filename: string;
  fileurl:string;
  filepage:string;
}

type ShowTarget = 'chat' | 'file' | 'form' | 'report' |'todo' ;

@Component({
  selector: 'app-assistant',
  standalone: true,
  imports: [CommonModule, FormsModule, MarkdownViewerComponent,FilebankComponent],
  templateUrl: './assistant.component.html',
  styleUrl: './assistant.component.scss',
})
export class AssistantComponent implements OnInit {


  @ViewChild('filebankcontainer', { read: ViewContainerRef }) filebankcontainer!: ViewContainerRef;


  componentMappings: ComponentMapping[] = [];

  showintroduction = true;
  showrecommentlist = true;

  //設定是否要開啟steam
  withstram = true;

  AssistantDataItem: AssistantDataItem | null = null;
  Recommendbuttonlist: { title: string; content: string }[] = [];

  //要先判斷是跟一般模型對話還是跟assistant對話
  chattarget = ''; //assistant or model

  modelname = '';
  assistantNo = ''; //AI助手的編號(自己編的編號)
  assistantid = ''; //AI助手的編號(Key)
  companyname = ''; //公司名稱
  vector_store_id = '';

  containerName = 'quallico'; //blob的container名稱

  init_image = '';
  init_word = '';
  init_meun: InitMenuItem[] = [];
  chatavator = '';
  selectedIcon: string | null = null;
  slogan = '';

  threadid = '';

  selectedFiles: File[] = [];
  imagePreviews: { url: string; uploading: boolean; progress: number }[] = [];

  tools_fileupload = false;
  tools_qrcode = false;

  conponent_callstr = '';

  //動態載入Component用
  @ViewChildren('dynamicComponentContainers', { read: ViewContainerRef })
  dynamicComponentContainers!: QueryList<ViewContainerRef>;

  componentRefs: ComponentRef<any>[] = [];
  cdr = inject(ChangeDetectorRef);

  userMessage!: string;
  assistantReply!: string;

  chatMessages: {
    role: string;
    content: string;
    component?: string | null;
    images?: string[];
  }[] = [];

  isSendingMessage = false;

  meunbuttons: Button[] = []; // 明確設定型別為 Button[]
  actionbuttons: Button[] = []; // 明確設定型別為 Button[]



  //控制是否顯示chat視窗
  showtarget:ShowTarget = "chat";

  //關鍵字Mapping表,用 getter才會每次要使用的時候，都是最新的值
  get componentMapping(): ComponentMapping[] {
    return this.componentMappings;
  }

  //用來存steam模式下的附件資料
  annotations: Annotation[] = [];

  menuButtons: MenuButton[] = [];

  constructor(
    private authService: AuthService,
    private openAiApiService: OpanaiService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef,
    private moudleservice: ModuleService,
    private router: Router, // 注入 Router
    private azureBlobService: AzureBlobService,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private componentMappingService: ComponentMappingService
  ) {}

  ngOnInit() {


    if (!this.authService.isLoggedIn()) {
      this.authService.redirectToLogin();
      return; // 防止後續代碼執行
    }

    this.companyname = environment.companyname;
    this.assistantNo = this.router.routerState.snapshot.root.queryParams['id'];

    localStorage.setItem('authToken', this.assistantNo );

    if (this.assistantNo == '') {
      //導回到lobby頁面
      this.router.navigate(['/lobby']);
    }

    //取得hotkey
    this.loadComponentMappings();

    //取得assistant資料
    //取得recommentbuttonlist
    // 使用 DataService 取得資料
    this.moudleservice
      .get_assistant_by_no(this.companyname, this.assistantNo)
      .subscribe((items) => {
        this.AssistantDataItem = items;

        this.modelname = this.AssistantDataItem?.title || '';
        this.assistantid = this.AssistantDataItem?.assistantid || '';

        if (this.assistantid == '') {
          alert('error: assistantid is empty. Redirect to lobby page');
          //導回到lobby頁面
          this.router.navigate(['/lobby']);
        } else {
          this.init_image =
            this.AssistantDataItem?.Initialized?.init_image || '';
          this.init_word = this.AssistantDataItem?.Initialized?.init_word || '';
          this.init_meun = this.AssistantDataItem?.Initialized?.init_meun || [];
          this.chatavator =
            this.AssistantDataItem?.Initialized?.chatavator || '';

          this.assistantid = this.AssistantDataItem?.assistantid || '';
          this.vector_store_id = this.AssistantDataItem?.vector_store_id || '';

          //檢查可以使用那些tools
          var tools = this.AssistantDataItem?.tools || [];

          if (tools.length > 0) {
            tools.forEach((tool) => {
              if (tool == 'fileupload') {
                this.tools_fileupload = true;
              }
            });
          }


          if (this.assistantid.startsWith('asst')) {
            this.chattarget = 'assistant';
          } else {
            this.chattarget = 'model';
          }

          //取得meunbutton
          this.menuButtons = this.menuService.getSideMenuIcons(this.companyname, this.vector_store_id, {
            gotolobby: this.gotolobby.bind(this),
            toggleVisibility: this.toggleVisibility.bind(this),
            adjustFontSize: this.adjustFontSize.bind(this),
            clearmsg: this.clearmsg.bind(this),
            gotoformlist: this.gotoformlist.bind(this),
            gotoRAGDatabase: this.gotoRAGDatabase.bind(this),
            gotoChat: this.gotoChat.bind(this),
            gotoToDolist: this.gotoToDoList.bind(this),
            gotoReportCenter: this.gotoReportCenter.bind(this),
          });
        }
      });

  }

  private loadComponentMappings(): void {

    this.componentMappingService.getComponentMappings().subscribe(mappings => {
      this.componentMappings = mappings;
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      const message = params['message'];
      const chattarget = params['chattarget'];

      if (message && chattarget) {
        this.chattarget = chattarget;
        this.chatwithAI(message, message);
      }
    });
  }


  menubuttonclick(title: string, content: string, functionname: string = '') {
    // this.showintroduction = false;
    // this.showchat = true;

    if(functionname!="")
    {
        const func = (this as any)[functionname];
    if (typeof func === 'function') {
      func.call(this);  // 確保 this 的指向正確
    } else {
      console.error("找不到對應的函式：" + functionname);
    }
    }
    else{
        //content有值才顯示在對話視窗
    if (content) {
      //跟AI對話
      this.chatwithAI(title, content, functionname);
    } else {
      console.log('找不到對應的內容');
    }
    }

  }

  //還沒用到的功能


  CallsimulateExamComponent(){

    this.isSendingMessage = true; // 開始 Loading 狀態

    var jsonobject:any;

    //只有幾堂課，是從AI產生
    if(this.modelname == '高壓氣體供應及消費作業主管' || this.modelname == '高壓氣體製造安全作業主管')
    {
      jsonobject = {
        "component": "simulateExamComponent",
        "classname": this.modelname,
        "totalminutes": 10,
        "testnumbers": 10,
        "fromdatabase":false
        };

    }else{
      jsonobject = {
        "component": "simulateExamComponent",
        "classname": this.modelname,
        "totalminutes": 100,
        "testnumbers": 80,
        "fromdatabase":true
        };
    }

    try {

    this.addNewConponentMessage("simulateExamComponent", jsonobject);

    } catch (error) {
      this.isSendingMessage = false; // 開始 Loading 狀態

    }
    finally{
      this.isSendingMessage = false; // 開始 Loading 狀態

    }

  }

  chatwithAI(title: string, message?: string, component?: string) {
    if (this.isSendingMessage) return; // 防止重複送出
    if (this.userMessage == '' && title == '') return; // 防止空白訊息

    this.actionbuttons = []; // 清空按鈕
    this.conponent_callstr = ''; //清空判斷是否為Component用的str

    // console.log(this.userMessage);

    this.isSendingMessage = true; // 開始 Loading 狀態

    if (!message) {
      message = title;
    }

    var userMessage = message;

    //先看看是不是有中關鍵字列表
    const mapping = this.componentMapping.find(
      (map) => map.keyword === userMessage
    );

    if (mapping) {

      this.loadcomponent(mapping);

    } else {
      // console.log('開始:', this.isSendingMessage);

      //如果沒有mapping到關鍵字，先看是不是送物件過來

      if (this.chattarget == 'assistant') {
        if (this.withstram == false) {
          this.chatwithassitant(title, message, component);
        }

        if (this.withstram == true) {
          this.chatwithassitantWithSteam(title, message, component);
        }
      } else if (this.chattarget == 'model') {
        this.chatwithmodel(title, message, component);
      } else {
        console.log('找不到對應的對話目標');
      }
    }

    this.imagePreviews = [];
  }


  loadcomponent(mapping:any){


    try {

      this.addNewConponentMessage(mapping.component, mapping.jsondata);
    } catch (error) {
      // 如果解析失敗，調用方法並只傳遞 component
      console.log("傳送到addNewConponentMessage失敗")

    }
    finally{
      this.finishLoading();
    }

  }

  chatwithassitant(title: string, message: string, component?: string) {
    // console.log('chatwithassitant');
    // console.log('message:', message);

    //顯示在畫面上的是title
    //送到Chatgpt的是content

    //如果有圖片，先轉到使用一般的model，不能使用助手，但助手的threadid要一起帶過去
    if (this.imagePreviews.length > 0) {
      this.chatwithmodel(title, message, component, this.threadid);
      return;
    }

    const userComponent = component || '';

    if (title != '') {
      this.chatMessages.push({
        role: 'user',
        content: title,
        component: userComponent,
        images: this.imagePreviews.map((preview) => preview.url),
      });
      this.userMessage = '';
    } else {
      return;
    }

    //如果有上傳圖片，需要先轉到使用一般的model，不能使用助手
    //直接這樣使用，threadid會跑掉，會導致出現錯誤
    //assistant模型，目前還不支援上傳圖片，先把 imageAttachments 拿掉
    this.openAiApiService
      .chatwithassistant(message, this.assistantid, this.threadid)
      .subscribe((response) => {
        //  console.log('response:', response);

        this.assistantReply = response.reply;
        this.threadid = response.threadid;
        //  console.log('assistant reply:', this.assistantReply);

        if (this.assistantReply.includes('"component"')) {
          //找到整段json的內容
          //  console.log('找到component');
          this.HandleConponentString(this.assistantReply);
        } else {
          var result = this.contentfransfer(this.assistantReply);

          //  console.log('result:', result);

          //拆解出文字跟button的部分

          this.chatMessages.push({
            role: 'assistant',
            content: result,
            component: '',
          });

          // 通知 Angular 檢測變更並更新 DOM
          this.cdr.detectChanges();

          // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
          setTimeout(() => {
            this.setchatlink();
          }, 0);

          this.finishLoading();
        }
      });
  }

  //新加入的非同步的方法
  async chatwithassitantWithSteam(
  title: string,
  message: string,
  component?: string
) {
  // 清空註解存放區
  this.annotations = [];

  const userComponent = component || '';

  if (title !== '') {


    var imglist = this.imagePreviews.map((preview) => preview.url);

    // **加入使用者訊息，包含圖片**
    this.chatMessages.push({
      role: 'user',
      content: title,
      component: userComponent,
      images: imglist, // 讓 Assistant 可接收圖片
    });

    this.userMessage = '';
  } else {
    return;
  }

  // **新增 AI 回應的佔位訊息**
  const assistantMessage = {
    role: 'assistant',
    content: '',
    component: '',
  };

  this.chatMessages.push(assistantMessage);
  const assistantIndex = this.chatMessages.length - 1; // 取得剛剛 push 的 AI 訊息索引


  // **檢查是否已有有效的 threadid**
  if (!this.threadid) {
    this.openAiApiService.getThreadId().subscribe({
      next: (response) => {
        this.threadid = response.threadid; // **設定 threadid**
        this.startChatWithAssistant(message, assistantIndex,imglist);
      },
      error: (err) => {
        console.error('Error fetching threadid:', err);
      },
    });
  } else {
    this.startChatWithAssistant(message, assistantIndex,imglist);
  }
}

// **更新 `startChatWithAssistant`，支援圖片**
private startChatWithAssistant(
  message: string,
  assistantIndex: number,
  imglist: string[]
): void {



  this.openAiApiService
    .chatwithassistantWithSteam(message, this.assistantid, this.threadid,this.companyname,imglist )
    .subscribe({
      next: (data) => {
        this.appendToChatMessages(assistantIndex, data);
      },
      error: (err) => {
        console.error('發生錯誤:', err);
      },
      complete: () => {
        if (this.conponent_callstr != '') {
          try {
            console.log("this.conponent_callstr", this.conponent_callstr);
            if (this.chatMessages[assistantIndex].content == "") {
              this.chatMessages.splice(assistantIndex, 1);
            }
            this.HandleConponentString(this.conponent_callstr);
          } catch (error) {
            this.chatMessages[assistantIndex].content += this.conponent_callstr;
          }
        }

        if (this.annotations.length > 0) {
          this.appendAnotations(assistantIndex, this.annotations);
        }

        this.finishLoading();
      },
    });
}


  appendToChatMessages(assistantIndex: number, data: string) {
    // data是個字串，並且要解析成物件
    const jsonArray = JSON.parse(data); // 解析成陣列

    if (jsonArray && jsonArray.length > 0) {
      const jsonObject = jsonArray[0]; // 取得陣列中的第一個物件

      if (jsonObject.type === 'text' && jsonObject.text?.value) {
        //如果送來的是註解
        if (
          jsonObject.text?.annotations &&
          jsonObject.text?.annotations.length > 0
        ) {
          // 取得註解
          var annotation = jsonObject.text?.annotations[0];

          // console.log('annotation', annotation);

          // 查找註解是否已經存在於 this.annotations 中
          const existingAnnotationIndex = this.annotations.findIndex(
            (item) => item.text === annotation.text
          );

          if (existingAnnotationIndex !== -1) {
            // 註解已經存在，更新 content 並將 index 加入
            const index = existingAnnotationIndex;
            this.chatMessages[assistantIndex].content += `[${index}]`;
          } else {
            // 註解不存在，推入新的註解物件到 this.annotations
            const newAnnotation: Annotation = {
              index: this.annotations.length, // 新增註解的 index 是當前 annotations 陣列的長度
              type: annotation.type || '',
              text: annotation.text || '',
              start_index: annotation.start_index || 0,
              end_index: annotation.end_index || 0,
              file_citation: {
                file_id: annotation.file_citation?.file_id || '',
                quote: annotation.file_citation?.quote || '',
              },
              filename: '',
              fileurl: '',
              filepage: '',
            };

            this.annotations.push(newAnnotation);

            // 將新的註解 index 加入 content
            const index = this.annotations.length - 1; // 新增註解後的 index
            this.chatMessages[assistantIndex].content += `[${index}]`;
          }

          return;
        }

        //如果送進來的是 以{開頭 且

        if (this.conponent_callstr != '') {
          this.conponent_callstr += jsonObject.text?.value;
          return;
        } else {
          if (
            typeof jsonObject.text?.value === 'string' &&
            jsonObject.text.value.trim().startsWith('{') &&
            this.conponent_callstr == ''
          ) {
            this.conponent_callstr += jsonObject.text?.value;
            return;
          }
        }

        // 如果沒有註解，則直接將 value 加入 content
        this.chatMessages[assistantIndex].content += jsonObject.text?.value;
      }
    }
  }

  //TODO:尚未完成
  appendAnotations(assistantIndex: number, data: Annotation[]) {}

  HandleConponentString(componentString: string) {

      try {
        const jsonObject = JSON.parse(componentString);
        // console.log('提取到的 JSON 对象:', jsonObject);

        var componentname = jsonObject.component;

        //將其他的屬性放到陣列中
        this.addNewConponentMessage(componentname, jsonObject);
      } catch (error) {
        console.error('解析 JSON 出错:', error);
      } finally {
        this.finishLoading();
      }

  }

  chatwithmodel(
    title: string,
    message?: string,
    component?: string,
    threadid?: string
  ) {
    // console.log('chatwithmodel');

    const AIModel = 'gpt-4o';
    const userComponent = component || '';

    if (title !== '') {
      this.chatMessages.push({
        role: 'user',
        content: title,
        component: userComponent,
        images: this.imagePreviews.map((preview) => preview.url),
      });
      this.userMessage = '';
    } else {
      this.finishLoading();
      return;
    }

    if (!message) {
      message = title;
    }

    const userMessage = message;

    const imageAttachments: string[] = this.imagePreviews.map(
      (preview) => preview.url
    );

    this.openAiApiService
      .chatwithgpts(userMessage, imageAttachments, this.threadid, AIModel)
      .subscribe((response) => {
        this.assistantReply = response.reply;

        if (threadid) {
          this.threadid = threadid; //用來接從assistant回傳的threadid
        } else {
          this.threadid = response.threadid; //保持跟model的threadid一致
        }

        // this.threadid = response.threadid;

        this.chatMessages.push({
          role: 'assistant',
          content: this.assistantReply,
          component: '',
          images: this.imagePreviews.map((preview) => preview.url),
        });
        this.userMessage = '';
        this.imagePreviews = [];
        this.selectedFiles = [];

        this.finishLoading();
      });
  }

  // getSanitizedContent(content: string): SafeHtml {
  //   content = content.replace(/\n/g, '<br>');
  //   return this.sanitizer.bypassSecurityTrustHtml(content);
  // }

  // contentformate(content: string): string {
  //   // 將換行符號替換為 <br> 標籤
  //   return content.replace(/\n/g, '<br>');
  // }

  //針對最後一個產生的assistant對話，新增裡面的Click事件
  setchatlink() {
    // const chatContainer = document.querySelector('.chat-container'); // 找到 .chat-container 元素
    // if (chatContainer) {
    //   const assistantDivs = chatContainer.querySelectorAll('.assistant'); // 查詢 chatContainer 裡面的 .assistant 元素
    //   console.log('assistantDivs:', assistantDivs);
    //   // 如果存在 .assistant 元素，則取最後一個元素
    //   const lastAssistantDiv = assistantDivs[assistantDivs.length - 1];
    //   if (lastAssistantDiv) {
    //     // 查詢最後一個 .assistant 裡面的所有 .canclick 元素
    //     const canclickElements = lastAssistantDiv.querySelectorAll('.canclick');
    //     console.log('canclickElements:', canclickElements);
    //     // 可以在這裡綁定點擊事件
    //     canclickElements.forEach((canclick) => {
    //       canclick.classList.add('canclick');
    //       this.renderer.listen(canclick, 'click', () => {
    //         const value = canclick.getAttribute('data-value') || '';
    //         this.setInputValue(value);
    //       });
    //     });
    //   }
    // }
  }

  loadComponents(componentKey: string, jsondata: string = ''): void {
    // console.log('componentKey:', componentKey);
    // console.log("jsondata",jsondata);

    //資料變更後重新渲染元件
    const containers = this.dynamicComponentContainers.toArray();
    const lastContainerIndex = containers.length - 1;
    //找到最後一個容器
    const container = containers[lastContainerIndex];

    const componentClass = COMPONENT_MAP[componentKey];

    if (!container) {
      console.error(`找不到對應的容器: index ${lastContainerIndex}`);
      return;
    }

    const componentRef: ComponentRef<any> =
      container.createComponent(componentClass);

    //將回傳的參數，送進去component
    let parsedData: any = jsondata;
    if (typeof jsondata === 'string' && jsondata != '') {
      try {
        parsedData = JSON.parse(jsondata);
      } catch (error) {
        console.error('無法解析 jsondata:', error);
      }
    }

    // 將回傳的參數，送進去 component
    if (parsedData != '') {
      Object.keys(parsedData).forEach((key) => {
        const value = parsedData[key as keyof typeof parsedData];
        // console.log(`Key: ${key}, Value: ${value}`);
        if (key !== 'component') {
          componentRef.instance[key] = value;
        }
      });
    }

    //針對特別的component做處理，設定Input值


    if (componentKey === 'filedownloader') {
      componentRef.instance.containerName = this.containerName;
    }


    //設定Output值 => 每個元件有的output不一樣，要先判斷是否有，才做綁定
    if ('chatwithAI' in componentRef.instance && componentRef.instance.chatwithAI instanceof EventEmitter) {
      componentRef.instance.chatwithAI.subscribe((data: any) => {
          this.chatwithAI(data);
      });
  }

  if ('Showlist' in componentRef.instance && componentRef.instance.Showlist instanceof EventEmitter) {
    componentRef.instance.Showlist.subscribe((data: any) => {
        this.Showlist(data);
    });
}

if ('fillform' in componentRef.instance && componentRef.instance.fillform instanceof EventEmitter) {
  componentRef.instance.fillform.subscribe((data: any) => {
      this.fillform(data);
  });
}

    this.componentRefs.push(componentRef); // 儲存子元件的引用
  }

  ngOnDestroy(): void {
    // 清除組件參考，避免記憶體洩漏
    for (const componentRef of this.componentRefs) {
      if (componentRef) {
        componentRef.destroy();
      }
    }
  }



  contentfransfer(content: string): string {
    // // 替換以數字開頭的選項行，加上 `<a>` 標籤
    // content = content.replace(/^\(\d+\)\s.*$/gm, (match) => {
    //   return `<a class='canclick' href="#" onclick="return false;" data-value='${match}'>${match}</a>`;
    // });

    // // 匹配並替換被【】包含的所有文字，去除【】符號
    // content = content.replace(/【([^【】]+)】/g, (match, p1) => {
    //   return `<a class='canclick' href="#" onclick="return false;" data-value='${p1}'>${p1}</a>`;
    // });

    return content;
  }

  setInputValue(value: string) {
    this.userMessage = value;
  }

  Showlist(formname:string){


    var componentKey = 'showlistComponent';
    var jsondata = {
      "component": "showlistComponent",
      "formName":formname
      };

    this.addNewConponentMessage(componentKey,jsondata);
  }


  fillform(formname:string){

    var componentKey = 'DynamicFormComponent';
    var jsondata = {
      "component": "DynamicFormComponent",
      "formName":formname,
      "assistantid":this.assistantid,
      "assistantname":this.modelname
      };

    this.addNewConponentMessage(componentKey,jsondata);

  }

  // 捕捉按鍵事件
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // 阻止默認的換行行為
      this.chatwithAI(this.userMessage);
      this.userMessage = ''; // 清空輸入框
    }
  }

  closeDiv() {
    this.showintroduction = true;
    // this.showchat = true;
    // this.showrecommentlist = false;

    this.chatMessages = [];

    //清空threadid
    this.threadid = '';
  }

  onIconClick(icon: string) {
    this.selectedIcon = icon; // 設定當前選取的圖示

    if (icon == 'sad') {
      this.slogan = '(明日は明日の風が吹く)';
    } else {
      this.slogan = '';
    }
  }

  //導頁到檔案中心
  gotofilecenter() {

    var jsonstr = {
      "component": "FilebankComponent",
      "vector_store_id": this.vector_store_id,
      }

    this.addNewConponentMessage("FilebankComponent",jsonstr);

  }

  /**
   * 動態加載元件並插入到 chatMessages
   */
  addNewConponentMessage(componentKey: string, jsondata: any= {}): void {


    const componentClass = COMPONENT_MAP[componentKey];

    // console.log('componentClass:', componentClass);

    if (!componentClass) {
      console.error(`找不到對應的元件: ${componentKey}`);
      return;
    }

    //將元件加入到 chatMessages
    this.chatMessages.push({
      role: 'assistant',
      content: '',
      component: componentKey,
    });

    // console.log('chatMessages:', this.chatMessages);

    // 通知 Angular 檢測變更並更新 DOM
    this.cdr.detectChanges();

    // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
    // render元件
    setTimeout(() => {
      this.loadComponents(componentKey, jsondata);
    }, 0);
  }

  onValueSubmitted(data: any, index: number) {
    // console.log('來自子元件的資料:', data);
    // console.log('該元件在 chatMessages 的索引:', index);
    // 可以將資料寫入資料庫或進一步處理
  }

  // 自動調整輸入框高度
  autoResize(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // 先重置高度，避免疊加
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  // 當用戶選擇文件時
  async onFilesSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];

        // 生成本地預覽 URL
        const localPreviewUrl = URL.createObjectURL(file);
        this.imagePreviews.push({
          url: localPreviewUrl,
          uploading: true,
          progress: 0,
        });
        this.selectedFiles.push(file);

        // 模擬上傳進度
        const index = this.imagePreviews.length - 1;

        try {
          // 上傳檔案到 Azure Blob Storage 並取得 URL
          const fileUrl = await this.azureBlobService.uploadFile(file,this.companyname,"public");
          // console.log('Uploaded file URL:', fileUrl);

          // 上傳成功後更新預覽的 URL 並標記為上傳完成
          //先不處理uploading的動畫，改成Loading完成後，可以點擊
          this.imagePreviews[index].url = fileUrl;
          this.imagePreviews[index].uploading = false;


        } catch (error) {
          console.error('Error uploading file:', error);
          this.imagePreviews[index].uploading = false;
          alert('檔案上傳失敗，請重試。');
        }
      }
    }
  }

  // 觸發檔案上傳
  triggerFileUpload() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  // 移除指定索引的圖片預覽
  removeImage(index: number) {
    this.imagePreviews.splice(index, 1);
    this.selectedFiles.splice(index, 1);
  }

  triggerQRCodeUpload(): void {
    const qrCodeInput = document.getElementById(
      'qrCodeInput'
    ) as HTMLInputElement;
    qrCodeInput.click(); // 打開相機或圖片選擇器
  }

  onQRCodeUpload(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (context) {
            // 壓縮圖片，限制寬度和高度
            const maxDimension = 500; // 最大邊長，根據需求可調整
            const scale = Math.min(
              maxDimension / img.width,
              maxDimension / img.height
            );
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;

            context.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 獲取圖片像素數據並解析 QR Code
            const imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            );
            const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

            if (qrCode) {
              const text = qrCode.data;

              if (text.startsWith('QRData')) {
                const result = text.replace(/^QRData[:：]/, ''); // 清除前綴
                this.chatwithAI(result);
              } else {
                alert('內容或格式不符，無法解析');
              }
            } else {
              alert('未檢測到有效的 QR Code');
            }
          }
        };
        if (reader.result) {
          img.src = reader.result.toString();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  currentFontSize: number = 16; // 預設字體大小

  adjustFontSize(action: 'increase' | 'decrease') {
    const chatDiv = document.getElementById('chatdiv');
    if (chatDiv) {
      if (action === 'increase' && this.currentFontSize < 32) {
        this.currentFontSize += 2;
      } else if (action === 'decrease' && this.currentFontSize > 16) {
        this.currentFontSize -= 2;
      }
      chatDiv.style.fontSize = `${this.currentFontSize}px`;
    }
  }

  clearmsg() {
    this.chatMessages = [];
  }

  gotolobby() {
    this.router.navigate(['/lobby']);
  }

  toggleVisibility() {
    this.showintroduction = !this.showintroduction;
    // 這裡填入隱藏/顯示的邏輯
  }

  finishLoading() {
    this.userMessage = '';
    this.isSendingMessage = false;
    this.scrollToBottom();
  }

  // 滾動到對話視窗底部
  scrollToBottom(): void {
    const chatContainer = document.getElementById('chat-container');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }




  gotoChat():void{
    this.showtarget = "chat";
  }

  gotoRAGDatabase():void{
    this.gotofilecenter();
  }

  gotoToDoList():void{
    alert("Go To To Do List")
    // this.showtarget = "todo";
  }

  gotoReportCenter():void{
    alert("Go To Repoer Center")
    //  this.showtarget = "report";
  }


  gotoformlist():void{
    alert("Go To Form Center")
    // alert("gotoformlist");

    // this.showtarget = "form";

  }





}
