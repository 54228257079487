<div class="rag-container">
  <h2>RAG 問答測試</h2>
  <div class="input-area">
    <input
      type="text"
      [(ngModel)]="question"
      placeholder="請輸入你的問題..."
    />
    <button (click)="askQuestion()">送出</button>
  </div>
  <div class="answer-area" *ngIf="answer">

    <h3>AI Search 結果：</h3>
    <ul>
      <li *ngFor="let doc of vectorSearchdata; let i = index">
        <strong>Document {{ i + 1 }}: {{ doc.title }}</strong>
        <p>{{ doc.chunk }}</p>
      </li>
    </ul>
    <h3>AI 回答：</h3>
    <p>{{ answer }}</p>
  </div>
</div>
