import { Component } from '@angular/core';

@Component({
  selector: 'app-contractor-annual-performance',
  standalone: true,
  imports: [],
  templateUrl: './contractor-annual-performance.component.html',
  styleUrl: './contractor-annual-performance.component.scss'
})
export class ContractorAnnualPerformanceComponent {

}
