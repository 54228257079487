<div class="user-management-container">
  <h1>用戶管理</h1>
  <p>管理系統用戶和權限</p>

  <!-- 錯誤訊息顯示 -->
  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>

  <!-- 載入中指示器 -->
  <div *ngIf="isLoading" class="loading-spinner">
    <div class="spinner"></div>
    <p>載入中...</p>
  </div>

  <!-- 用戶表單 -->
  <div *ngIf="!isLoading" class="user-form-container" [class.expanded]="isEditing">
    <form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
      <h2>{{ isEditing ? '編輯用戶' : '新增用戶' }}</h2>

      <!-- 基本資料 -->
      <div class="form-row">
        <div class="form-group">
          <label for="username">用戶名 *</label>
          <input type="text" id="username" formControlName="username" placeholder="請輸入用戶名" class="form-control">
          <div *ngIf="userForm.get('username')?.invalid && userForm.get('username')?.touched" class="error-text">
            <span *ngIf="userForm.get('username')?.errors?.['required']">用戶名為必填</span>
            <span *ngIf="userForm.get('username')?.errors?.['minlength']">用戶名至少需要3個字符</span>
          </div>
        </div>

        <div class="form-group">
          <label for="password">密碼 {{ isEditing ? '(留空不更改)' : '*' }}</label>
          <input type="password" id="password" formControlName="password" placeholder="請輸入密碼" class="form-control">
          <div *ngIf="!isEditing && userForm.get('password')?.invalid && userForm.get('password')?.touched" class="error-text">
            <span *ngIf="userForm.get('password')?.errors?.['required']">密碼為必填</span>
            <span *ngIf="userForm.get('password')?.errors?.['minlength']">密碼至少需要6個字符</span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="fullName">姓名 *</label>
          <input type="text" id="fullName" formControlName="fullName" placeholder="請輸入姓名" class="form-control">
          <div *ngIf="userForm.get('fullName')?.invalid && userForm.get('fullName')?.touched" class="error-text">
            <span *ngIf="userForm.get('fullName')?.errors?.['required']">姓名為必填</span>
          </div>
        </div>

        <div class="form-group">
          <label for="email">電子郵件 *</label>
          <input type="email" id="email" formControlName="email" placeholder="請輸入電子郵件" class="form-control">
          <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched" class="error-text">
            <span *ngIf="userForm.get('email')?.errors?.['required']">電子郵件為必填</span>
            <span *ngIf="userForm.get('email')?.errors?.['email']">請輸入有效的電子郵件地址</span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="department">部門</label>
          <input type="text" id="department" formControlName="department" placeholder="請輸入部門" class="form-control">
        </div>

        <div class="form-group">
          <label for="position">職位</label>
          <input type="text" id="position" formControlName="position" placeholder="請輸入職位" class="form-control">
        </div>

        <div class="form-group checkbox">
          <label>
            <input type="checkbox" formControlName="enabled"> 啟用帳號
          </label>
        </div>
      </div>

      <!-- 權限設定 -->
      <div class="permissions-section">
        <h3>權限設定</h3>
        <div formArrayName="permissions">
          <div *ngFor="let permission of permissionsArray.controls; let i = index" [formGroupName]="i" class="permission-item">
            <div class="form-group">
              <label>AI 助手</label>
              <select formControlName="assistantNo" class="form-control">
                <option value="">-- 選擇助手 --</option>
                <option *ngFor="let assistant of assistants" [value]="assistant.assistantNo">
                  {{ assistant.title }}
                </option>
                <option value="ALL">所有助手</option>
              </select>
            </div>

            <div class="form-group">
              <label>權限</label>
              <select formControlName="permission" class="form-control">
                <option value="">-- 選擇權限 --</option>
                <option *ngFor="let option of permissionOptions" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <button type="button" class="btn btn-danger" (click)="removePermission(i)">
              移除
            </button>
          </div>

          <button type="button" class="btn btn-outline" (click)="addPermission()">
            + 添加權限
          </button>
        </div>
      </div>

      <!-- 表單按鈕 -->
      <div class="form-actions">
        <button type="submit" class="btn btn-primary" [disabled]="userForm.invalid">
          {{ isEditing ? '更新' : '創建' }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancelEdit()">取消</button>
      </div>
    </form>
  </div>

  <!-- 用戶列表 -->
  <div *ngIf="!isLoading && !isEditing" class="user-table-container">
    <div class="table-header">
      <h2>用戶列表</h2>
      <button class="btn btn-primary" (click)="isEditing = true">+ 新增用戶</button>
    </div>

    <div class="table-responsive">
      <table class="user-table">
        <thead>
          <tr>
            <th>用戶名</th>
            <th>姓名</th>
            <th>電子郵件</th>
            <th>部門</th>
            <th>職位</th>
            <th>權限</th>
            <th>狀態</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>{{ user.username }}</td>
            <td>{{ user.fullName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.department }}</td>
            <td>{{ user.position }}</td>
            <td>
              <div class="permission-tags">
                <span *ngFor="let perm of user.permissions.slice(0, 3)" class="tag">
                  {{ getAssistantName(perm.assistantNo) }}: {{ perm.permission }}
                </span>
                <span *ngIf="user.permissions.length > 3" class="tag more">
                  +{{ user.permissions.length - 3 }} 更多
                </span>
              </div>
            </td>
            <td>
              <span [ngClass]="user.enabled ? 'status-enabled' : 'status-disabled'">
                {{ user.enabled ? '啟用' : '停用' }}
              </span>
            </td>
            <td class="actions">
              <button class="btn btn-sm btn-edit" (click)="editUser(user)">編輯</button>
              <button class="btn btn-sm btn-delete" (click)="deleteUser(user)">刪除</button>
            </td>
          </tr>
          <tr *ngIf="users.length === 0">
            <td colspan="8" class="no-data">暫無用戶資料</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
