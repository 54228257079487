import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatabaseService } from '../../../services/database.service';

@Component({
  selector: 'app-form-list-table',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './form-list-table.component.html',
  styleUrls: ['./form-list-table.component.scss']
})
export class FormListTableComponent implements OnInit {
  data: any[] = [];
  companyid: string = '';

  @Output() Showlist = new EventEmitter<string>();
  @Output() fillform = new EventEmitter<string>();

  constructor(private databaseService: DatabaseService) {}

  ngOnInit(): void {
    // 取得 localStorage 中的 companyid
    this.companyid = localStorage.getItem('companyid') ?? '';
    if (!this.companyid) return;

    // 建立 filter 物件
    const filter: any = { companyid: this.companyid };
    const pageSize = 100;

    // 取得表單列表（例如從 form-config 容器）
    this.databaseService.getDataByQuery(filter, pageSize, 'form-config')
      .subscribe({
        next: (result) => {
          console.log('Form list:', result);
          this.data = result.items;
          // 表單列表取得後，接著取得各表單填寫次數
          this.fetchFormCounts();
        },
        error: (error) => {
          console.error('Error fetching form list:', error);
        },
        complete: () => {
          console.log('Form list fetching complete');
        }
      });
  }

  // 取得各表單被填寫次數的資料
  fetchFormCounts(): void {
    this.databaseService.getFormCount(this.companyid)
      .subscribe({
        next: (result) => {
          console.log('Form count:', result);
          // 假設 result.items 為 [{ formName, filledCount }, ...]
          const countMap = new Map<string, number>();
          result.items.forEach((item: any) => {
            // 使用 item.formName 而非 item.componentname
            countMap.set(item.formName, item.filledCount);
          });
          // 將每筆表單資料更新加入 filledCount 屬性，並依據 form.formName 查詢
          this.data = this.data.map(form => {
            return {
              ...form,
              filledCount: countMap.get(form.formName) || 0
            };
          });
        },
        error: (error) => {
          console.error('Error fetching form count:', error);
        },
        complete: () => {
          console.log('Form count fetching complete');
        }
      });
  }

  // 點擊查看該表單所有列表時觸發
  viewFormList(formname: string): void {
    this.Showlist.emit(formname);
  }

  // 點擊新建一筆資料時觸發
  createNewRecord(formname: string): void {
    this.fillform.emit(formname);
  }
}
