import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContainerClient } from '@azure/storage-blob';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // 使用 NgbModal
import { getDocument, PDFDocumentProxy, GlobalWorkerOptions } from 'pdfjs-dist';
import { ChangeDetectorRef } from '@angular/core';
import { OpanaiService } from '../../services/openai.service';
import { DatabaseService } from '../../services/database.service';

export interface Folder {
  name: string;
  fullPath: string;
  expanded: boolean;
  subfolders?: Folder[];
  fileCount?: number;
}

export interface FileInfo {
  name: string;
  lastModified: Date;
  updatedBy?: string;
  fullPath: string;
  rag?: boolean; // 是否已送到 rag 資料夾
}

interface UploadFile {
  file: File;
  progress: number;
}

interface TextContent {
  page: number;
  data: string;
  AIResponse?: string;
  status?: string;
}

interface TextContent {
  page: number;
  data: string;
  AIRespnese?: string;
  status?: string;
}

@Component({
  selector: 'app-azure-blob-explorer',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './azure-blob-explorer.component.html',
  styleUrls: ['./azure-blob-explorer.component.scss'],
})

export class AzureBlobExplorerComponent implements OnInit {
  @Input() CN: string = 'Quallico'; // 從母組件取得客戶代碼
  @Input() AssistantId: string = '';
  @Input() complianceMode: boolean = true; // 新增的 boolean 參數，預設為 true

  // 若不限制 blob 前置字串則設為空字串
  folderPrefix: string = '';
  containerClient!: ContainerClient; // 使用 ContainerClient 直接連線到客戶專屬 container
  folders: Folder[] = [];
  files: FileInfo[] = [];
  selectedFolder: string | null = null;
  uploadVersionForFile: string | null = null;
  showNewFolderInput: boolean = false;
  isDuplicateFolderName: boolean = false;
  filesToUpload: UploadFile[] = [];
  isUploading: boolean = false;
  isUploadFinished: boolean = false;
  uploadResults: { fileName: string; status: string; message?: string }[] = [];
  finalResult: any = null; // 最終結果物件
  fileToParse: string | null = null; // 要解析的檔案名稱
  textContent: TextContent[] = []; // 存放每一頁的內容
  isLoading = false; // 處理狀態
  jsonresult: string[] = []; // 解析結果
  AIResult: string = ''; // 最終 JSON 結果
  showChecklist: boolean = false;
  viewMode: string = 'regulation'; // 新增的 viewMode 變數
  progress: number = 0; // 新增的 progress 變數
  jsonEditorContent: string = ''; // 新增的 jsonEditorContent 變數
  showContentFlag: boolean = false; // 新增的 showContentFlag 變數

  parserstatus: number = 0;

  parseModes: string[] = ['Laws & Regulations Database of The Republic of China (Taiwan)']; // 新增的 parseModes 變數
  selectedParseMode: string = '全國法規資料庫'; // 預設解析模式


  users: string[] = ['User1', 'User2', 'User3']; // Example user list
  filteredUsers: string[] = [];

  @ViewChild('parseModal') parseModal: any;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private openAiApiService: OpanaiService,
     private databaseService: DatabaseService,
  ) {
    // 設定 PDF.js 的全局 WorkerSrc，指向正確的本地版本
    GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.mjs';
  }

  async ngOnInit() {
    console.log(`初始化 Azure Blob Explorer，CN: ${this.CN}`);
    if (this.CN) {
      await this.loadCustomerFolders();
    }
  }

  /** 透過後端 API 取得 SAS Token 並連線到客戶專屬 container */
  async loadCustomerFolders() {
    if (!this.CN.trim()) {
      console.warn("CN 為空，無法載入客戶資料夾");
      return;
    }

    try {
      const response = await fetch(`${environment.apiUrl}/api/azblob/getSasToken`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ CN: this.CN }),
      });

      if (!response.ok) {
        throw new Error("取得 SAS Token 失敗");
      }

      const data = await response.json();
      console.log("data.sasUrl", data.sasUrl);
      this.containerClient = new ContainerClient(data.sasUrl);

      // 顯示 container/compliance-data 裡的所有資料夾
      this.folderPrefix = 'compliance-data/';
      this.folders = [];
      this.selectedFolder = null;
      await this.loadFolders();
    } catch (error) {
      console.error("載入客戶資料夾失敗:", error);
    }
  }

  /** 載入 container 裡的所有資料夾 */
  async loadFolders() {
    const uniqueFolders = new Set<string>();

    // 以 "/" 為階層分隔符號，列出所有資料夾
    for await (const blob of this.containerClient.listBlobsByHierarchy("/", { prefix: this.folderPrefix })) {
      if (blob.kind === 'prefix') {
        uniqueFolders.add(blob.name);
      }
    }

    const folders = Array.from(uniqueFolders).map(folder => ({
      name: folder.replace(this.folderPrefix, '').replace('/', ''),
      fullPath: folder,
      expanded: false,
      subfolders: [],
      fileCount: 0
    }));

    // 計算每個資料夾中的檔案數量
    for (const folder of folders) {
      folder.fileCount = await this.getFileCount(folder.fullPath);
    }

    this.folders = folders;

    // 頁面載入時，自動選取第一個資料夾並載入其檔案
    if (this.folders.length > 0) {
      this.selectedFolder = this.folders[0].fullPath;
      await this.selectFolder(this.selectedFolder);
    }
  }

  async getFileCount(folderPath: string): Promise<number> {
    let count = 0;
    for await (const blob of this.containerClient.listBlobsByHierarchy("/", { prefix: folderPath })) {
      if (blob.kind !== 'prefix' && !blob.name.endsWith('init.txt')) {
        count++;
      }
    }
    return count;
  }

  /** 選擇資料夾後列出該資料夾內所有檔案，並讀取 blob 的屬性與 metadata */
  async selectFolder(folderPath: string) {
    this.selectedFolder = folderPath;
    const files: FileInfo[] = [];

    for await (const blob of this.containerClient.listBlobsByHierarchy("/",
      { prefix: folderPath, includeMetadata: true })) {
      if (blob.kind !== 'prefix' && !blob.name.endsWith('init.txt')) {
        // 讀取最後修改日與 metadata 中的 updatedBy 欄位
        const lastModified = blob.properties.lastModified ? new Date(blob.properties.lastModified) : new Date();
        const updatedBy = blob.metadata && blob.metadata['updatedBy'] ? blob.metadata['updatedBy'] : '--';
        const rag = blob.metadata && blob.metadata['rag'] === 'Y';

        const fileName = blob.name.replace(folderPath, '');
        files.push({
          name: fileName,
          lastModified,
          updatedBy,
          fullPath: blob.name,
          rag,  // true or undefined/false
        });
      }
    }
    this.files = files;
  }

  /** 自動更新 SAS Token 機制 */
  async safeBlobOperation(operation: () => Promise<void>) {
    try {
      await operation();
    } catch (error: any) {
      if (error.statusCode === 403) {
        await this.loadCustomerFolders();
        await operation(); // 重新執行操作
      } else {
        console.error("操作失敗:", error);
      }
    }
  }

  /** 上傳新檔案 */
  async uploadFile(files: FileList) {
    await this.safeBlobOperation(async () => {
      if (!this.selectedFolder || !files || files.length === 0) return;

      const file = files[0];
      const filePath = `${this.selectedFolder}${file.name}`;
      const blobClient = this.containerClient.getBlockBlobClient(filePath);

      // 可在上傳時傳入 metadata（例如：更新人員）
      await blobClient.uploadData(file, {
        metadata: { updatedBy: 'UserName' } // 請替換為實際使用者識別
      });
      await this.selectFolder(this.selectedFolder);
    });
  }

  /** 上傳新版本 */
  async uploadNewVersion(event: any) {
    await this.safeBlobOperation(async () => {
      if (!this.selectedFolder || !this.uploadVersionForFile) return;

      const file = event.target.files[0];
      if (!file) return;

      const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, '');
      const versionedFileName = `${this.uploadVersionForFile}_v${timestamp}`;
      const filePath = `${this.selectedFolder}${versionedFileName}`;
      const blobClient = this.containerClient.getBlockBlobClient(filePath);

      await blobClient.uploadData(file);
      this.uploadVersionForFile = null;
      await this.selectFolder(this.selectedFolder);
    });
  }

  /** 刪除檔案 */
  async deleteFile(fileName: string) {
    await this.safeBlobOperation(async () => {
      if (!this.selectedFolder) return;

      const filePath = `${this.selectedFolder}${fileName}`;
      const blobClient = this.containerClient.getBlockBlobClient(filePath);
      await blobClient.delete();
      this.files = this.files.filter(file => file.name !== fileName);
      await this.refreshFolderFileCounts();
    });
  }

  /** 下載檔案 */
  async downloadFile(fileName: string) {
    await this.safeBlobOperation(async () => {
      if (!this.selectedFolder) return;

      const filePath = `${this.selectedFolder}${fileName}`;
      const blobClient = this.containerClient.getBlockBlobClient(filePath);
      const downloadResponse = await blobClient.download();
      const blob = await downloadResponse.blobBody;

      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  }

  /** 準備上傳新版本 */
  prepareUploadVersion(fileName: string) {
    this.uploadVersionForFile = fileName;
  }


  async toggleRag(file: FileInfo) {
    // 取得原始 blob client
    const originalBlobClient = this.containerClient.getBlockBlobClient(file.fullPath);

    // 目標路徑：假設將檔案複製到 rag-data/ 子資料夾，檔名保持不變
    const targetPath = `rag-data/${file.name}`;
    const targetBlobClient = this.containerClient.getBlockBlobClient(targetPath);

    if (!file.rag) {
      // 尚未標記 => 複製到 rag-data 並更新 metadata
      try {
        // 1. 透過 startCopyFromURL 複製檔案
        // 取得原始 blob 的 URL (含 SAS token)
        const sourceUrl = originalBlobClient.url;


        const copyPoller = await targetBlobClient.beginCopyFromURL(sourceUrl);
        await copyPoller.pollUntilDone();



        // 2. 更新原始 blob 的 metadata，加入 rag:true
        // 先取得原有 metadata
        const originalProperties = await originalBlobClient.getProperties();
        const newMetadata = Object.assign({}, originalProperties.metadata);
        newMetadata['rag'] = 'Y';
        await originalBlobClient.setMetadata(newMetadata);


        // 3. 更新 UI
        file.rag = true;
        // console.log(`檔案 ${file.name} 已複製到 rag-data 並標記為 rag`);
      } catch (error) {
        // console.error("標記為 rag 失敗：", error);
      }
    } else {
      // 已標記 => 刪除 rag-data 中的檔案，並移除 metadata 中的 rag 欄位
      try {
        // 1. 刪除 rag-data 資料夾中的檔案
        await targetBlobClient.delete();

        // 2. 更新原始 blob 的 metadata，移除 rag 欄位
        const originalProperties = await originalBlobClient.getProperties();
        const newMetadata = Object.assign({}, originalProperties.metadata);
        delete newMetadata['rag'];
        await originalBlobClient.setMetadata(newMetadata);

        // 3. 更新 UI
        file.rag = false;
        // console.log(`檔案 ${file.name} 已從 rag-data 刪除，標記取消`);
      } catch (error) {
        // console.error("取消 rag 標記失敗：", error);
      }
    }
  }

  handleNewFolderBlur(folderName: string) {
    if (!folderName.trim()) {
      this.showNewFolderInput = false;
      this.isDuplicateFolderName = false;
      return;
    }

    const folderExists = this.folders.some(folder => folder.name === folderName);
    if (folderExists) {
      this.isDuplicateFolderName = true;
    } else {
      this.isDuplicateFolderName = false;
      this.showNewFolderInput = false;
    }
  }

  handleNewFolderEnter(folderName: string) {
    if (!folderName.trim()) {
      this.showNewFolderInput = false;
      this.isDuplicateFolderName = false;
      return;
    }

    const folderExists = this.folders.some(folder => folder.name === folderName);
    if (folderExists) {
      this.isDuplicateFolderName = true;
    } else {
      this.isDuplicateFolderName = false;
      this.createFolder(folderName);
      this.showNewFolderInput = false;
    }
  }

  /** 新增資料夾 */
  async createFolder(folderName: string) {
    const folderPath = `${this.folderPrefix}${folderName}/`;
    const initFileClient = this.containerClient.getBlockBlobClient(`${folderPath}init.txt`);

    try {
      await initFileClient.upload("This is an init file.", 23); // 建立 init.txt 檔案
      await this.loadFolders(); // 重新載入資料夾列表
      // console.log(`資料夾 ${folderName} 已建立`);

      // 自動選取新建立的資料夾
      this.selectedFolder = folderPath;
      await this.selectFolder(this.selectedFolder);
    } catch (error) {
      console.error("建立資料夾失敗：", error);
    }
  }

  /** 重新命名資料夾 */
  async renameFolder(oldFolderName: string, newFolderName: string) {
    if (!this.selectedFolder) return;

    const oldFolderPath = `${this.selectedFolder}${oldFolderName}/`;
    const newFolderPath = `${this.selectedFolder}${newFolderName}/`;

    try {
      // 取得原始資料夾內所有 blob
      for await (const blob of this.containerClient.listBlobsByHierarchy("/", { prefix: oldFolderPath })) {
        const oldBlobClient = this.containerClient.getBlockBlobClient(blob.name);
        const newBlobClient = this.containerClient.getBlockBlobClient(blob.name.replace(oldFolderPath, newFolderPath));

        // 複製 blob 到新資料夾
        await newBlobClient.beginCopyFromURL(oldBlobClient.url);
        await oldBlobClient.delete(); // 刪除原始 blob
      }

      await this.loadFolders(); // 重新載入資料夾列表
      // console.log(`資料夾 ${oldFolderName} 已重新命名為 ${newFolderName}`);
    } catch (error) {
      console.error("重新命名資料夾失敗：", error);
    }
  }

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFiles(files);
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    this.addFiles(input.files);
  }

  addFiles(fileList: FileList | null) {
    if (!fileList) return;

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      this.filesToUpload.push({ file, progress: 0 });
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  uploadFiles() {
    this.isUploading = true; // 開始上傳時隱藏刪除按鈕和下方按鈕

    // 清空之前的上傳結果
    this.uploadResults = [];

    // 上傳每個檔案
    const uploadPromises = this.filesToUpload.map((uploadFile, index) => {
      return this.uploadFileToAzure(uploadFile, index);
    });

    // 當所有上傳完成時，顯示結果視窗
    Promise.all(uploadPromises)
      .then(() => {
        this.isUploadFinished = true;
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        this.isUploadFinished = true;
      });
  }

  async uploadFileToAzure(uploadFile: UploadFile, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const filePath = `${this.selectedFolder}${uploadFile.file.name}`;
      const blobClient = this.containerClient.getBlockBlobClient(filePath);

      blobClient.uploadData(uploadFile.file, {
        onProgress: (progress) => {
          uploadFile.progress = Math.round((progress.loadedBytes / uploadFile.file.size) * 100);
        }
      }).then(async () => {
        this.uploadResults.push({
          fileName: uploadFile.file.name,
          status: 'success',
        });
        await this.refreshFolderFileCounts();
        resolve();
      }).catch((error) => {
        this.uploadResults.push({
          fileName: uploadFile.file.name,
          status: 'fail',
          message: error.message,
        });
        reject(error);
      });
    });
  }

  async refreshFolderFileCounts() {
    for (const folder of this.folders) {
      folder.fileCount = await this.getFileCount(folder.fullPath);
    }
  }

  clearFiles() {
    this.filesToUpload = [];
    this.uploadResults = [];
  }

  removeFile(index: number) {
    this.filesToUpload.splice(index, 1);
  }

  // getFileCount: string): number {
  //   return this.files.filter(file => file.fullPath.startsWith(folderPath) && !file.name.endsWith('init.txt')).length;
  // }

  highlightRow(event: Event) {
    (event.currentTarget as HTMLElement).classList.add('highlight');
  }

  unhighlightRow(event: Event) {
    (event.currentTarget as HTMLElement).classList.remove('highlight');
  }

  openParseModal(fileName: string, content: any) {
    this.fileToParse = fileName;
    this.modalService.open(content, { size: 'xl', fullscreen: true });
  }

  async startParsing(modal: any) {
    if (!this.fileToParse) return;

    // 取得檔案的 URL
    const fileUrl = `${this.selectedFolder}${this.fileToParse}`;
    const blobClient = this.containerClient.getBlockBlobClient(fileUrl);
    const downloadResponse = await blobClient.download();
    const blob = await downloadResponse.blobBody;

    if (blob) {
      const file = new File([blob], this.fileToParse, { type: 'application/pdf' });
      await this.parsePDF(file);
    }


  }

  async saveToDatabase() {
    if (!this.finalResult) {
      console.error('Final result is empty, cannot save to database.');
      return;
    }

    // Add CompanyId and AssistantId to the final result
    const dataToSave = {
      CompanyId: this.CN,
      AssistantId: this.AssistantId,
      ...this.finalResult
    };

    this.databaseService.sendDataToBackend(dataToSave, "regulation-container")
      .then(response => {
        console.log('Response from backend:', response);
        alert('資料儲存成功');
        this.modalService.dismissAll(); // Close the modal
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  }

  async parsePDF(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;

      // 使用 PDF.js 進行解析
      const pdf: PDFDocumentProxy = await getDocument({ data: arrayBuffer }).promise;

      this.textContent = []; // 清空文字內容

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const parserresult = await page.getTextContent();
        let pageText = '';
        let lastY: number | null = null;
        const xThreshold = 60; // 設定 x 座標的門檻值

        parserresult.items.forEach((item: any) => {

          // if (item.str.includes("1")) {
          //   // 取得 x 座標，通常在 transform 陣列的第5個元素
          //   const x = item.transform[4];
          //   console.log(`在第 ${i} 頁中，文字 "${item.str}" 的 x 座標為 ${x}`);
          // }

          // 取得 x 座標 (transform[4])
          const x = item.transform[4] as number;
          if (x < xThreshold) {
            // 如果 x 小於門檻值，則跳過詆項資料
            return;
          }

          // 取得 y 座標 (transform[5])
          const y = item.transform[5] as number;
          // 如果 y 座標與上一個項目有明顯差異，則認為是換行
          if (lastY !== null && Math.abs(y - lastY) > 5) {
            pageText += '\n';
          }
          pageText += item.str + ' ';
          lastY = y;
        });

        this.textContent.push({ page: i, data: pageText });
      }

      // 處理跨頁內容
      this.handleCrossPageContent();

      this.parserstatus = 1;


    };

    fileReader.readAsArrayBuffer(file);
  }


  handleCrossPageContent() {
    const regex = /第\s*\d+\s*條/;
    for (let i = 1; i < this.textContent.length; i++) {

      //把條號顯示很奇怪的先統一
      this.textContent[i].data = this.textContent[i].data.replace(/第\s+(\d+)\s+條/g, "第 $1 條");

      const data = this.textContent[i].data;
      const match = data.match(regex);

      if (match) {
        const index = data.indexOf(match[0]);
        const previousContent = data.substring(0, index).trim();
        const currentContent = data.substring(index).trim();

        this.textContent[i - 1].data += ` ${previousContent}`;
        this.textContent[i].data = currentContent;
      }
    }
  }

  async sendToAI(pageindex: number = -1): Promise<void> {
    this.isLoading = true;
    const prompt = '請把以下的法規內容轉成以下的json格式。\n' +
    '章節的形式是第 一 章，如果是章節，ArticleType設定為C，ArticleNo設定為空值，ArticleContent設定為章節名稱'+
    '條文的形式是第 1 條，如果是條文，ArticleType設定為A，ArticleNo設定為條次(文字內容要完全一致)，ArticleContent設定為條文內容，回傳的條文內容要保持原本的換行格式\n' +
   '同一個條文的內容，全部放在同一個條文的ArticleContent裡面\n';

    const jsonFormat = JSON.stringify([
      {
        "Laws": {
          "LawName": "",
          "LawModifiedDate": ""
        },
        "LawArticles": [
        {
          "ArticleType": "",
          "ArticleNo": "",
          "ArticleContent": "",
        }
        ]
      }
    ]);

    this.jsonresult = [];

    //取得法規資料

    //取得法條資料

    const pagesToProcess = pageindex === -1 ? this.textContent : [this.textContent[pageindex - 1]];

    if (pageindex !== -1) {
      this.textContent[pageindex - 1].status = '';
      this.textContent[pageindex - 1].AIRespnese = '';
    } else {
      this.jsonresult = [];
    }

    for (const item of pagesToProcess) {
      try {
        const finalmessage = `${prompt}\n\njson格式如下:${jsonFormat}\n\n法規內容如下：${item.data}`;

        // 呼叫API取得回應
        await this.openAiApiService
          .chatwithgpts(finalmessage, [], '', 'gpt-4o-mini')
          .subscribe({
            next: (response) => {

              // 取 response 的第一個 [ 和最後一個 ] 之間的值
              const cleanedResponse = response.reply.substring(
                response.reply.indexOf('['),
                response.reply.lastIndexOf(']') + 1
              );

              // 將回應存入對應頁面的 AIRespnese
              const page = this.textContent.find((p) => p.page === item.page);
              if (page) {
                page.AIRespnese = cleanedResponse;
                page.status = 'Success';
              }

              const parsedResponse = JSON.parse(cleanedResponse);

              //找出this.textContent中status為Success的項目總數
              const successCount = this.textContent.filter((p) => p.status === 'Success').length;

              //計算完成率 (成功數/總數)
              this.progress = Math.round((successCount / this.textContent.length) * 100);

              //當進度是100%時，取得最終結果
              if (this.progress === 100) {

                // 建立最終結果物件
                this.finalResult = {
                  Laws: {},
                  LawArticles: []
                };

                // 逐頁處理 this.textContent
                this.textContent.forEach((page, index) => {
                  if (page.AIRespnese) {
                    try {
                      // 將每個頁面的 AIRespnese 轉成物件
                      const parsedPage = JSON.parse(page.AIRespnese);
                      // 假設解析後結果為陣列，且第一個物件符合格式
                      if (Array.isArray(parsedPage) && parsedPage.length > 0) {
                        const pageObj = parsedPage[0];
                        // 如果是第一個頁面，取用其 Laws 當作最終結果的 Laws
                        if (index === 0) {
                          this.finalResult.Laws = pageObj.Laws;
                        }
                        // 將每頁的 LawArticles 合併進最終結果
                        if (pageObj.LawArticles && Array.isArray(pageObj.LawArticles)) {
                          this.finalResult.LawArticles = this.finalResult.LawArticles.concat(pageObj.LawArticles);
                        }
                      }
                    } catch (e) {
                      console.error(`頁面 ${page.page} 的 AIRespnese 解析失敗`, e);
                    }
                  }
                });

                console.log("Final JSON Object:", this.finalResult);
                this.jsonEditorContent = JSON.stringify(this.finalResult, null, 2); // 更新 JSON 編輯器內容

                this.isLoading = false;

                this.parserstatus = 2;

              }

            },
            error: (err) => {
              const page = this.textContent.find((p) => p.page === item.page);
              if (page) {
                page.status = 'Fail';
              }

              console.log('執行失敗：page' + item.page);
            },
          });
      } catch (error) {
        console.error('處理失敗:', error);
        const page = this.textContent.find((p) => p.page === item.page);
        if (page) {
          page.status = 'Fail';
        }
      }

    }



  }

  retryParsing(pageIndex: number) {
    this.sendToAI(pageIndex);
  }

  getjsonresult(): void {
    this.jsonresult.sort((a: any, b: any) => a.id - b.id);
    this.AIResult = JSON.stringify(this.jsonresult, null, 2);
  }

   replaceNewlineWithBr(content: string): string {
    if (!content) {
      return '';
    }
    return content.replace(/\n/g, '<br>');
  }

  scrollToArticle(index: number) {
    const element = document.getElementById('article-' + index);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  markRelevant(index: number) {
    this.finalResult.LawArticles[index].relevant = true;
    this.finalResult.LawArticles[index].compliance = null; // Reset compliance when marked relevant
  }

  markIrrelevant(index: number) {
    this.finalResult.LawArticles[index].relevant = false;
    this.finalResult.LawArticles[index].compliance = null; // Reset compliance when marked irrelevant
  }

  markCompliant(index: number) {
    this.finalResult.LawArticles[index].compliance = 'compliant';
  }

  markNonCompliant(index: number) {
    this.finalResult.LawArticles[index].compliance = 'non-compliant';
  }

  markReference(index: number) {
    this.finalResult.LawArticles[index].compliance = 'reference';
  }

  updateFinalResult() {
    try {
      this.finalResult = JSON.parse(this.jsonEditorContent);
    } catch (e) {
      console.error('JSON 解析失敗:', e);
    }
  }

  showContent(){
    this.showContentFlag = true;
    this.viewMode = 'content';
  }

  showView(view: string) {
    this.viewMode = view;
    this.showContentFlag = false;
  }



}
