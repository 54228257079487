import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { YhService } from '../../../services/yh.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-yh-allclass-trend',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatTooltipModule],
  templateUrl: './yh-allclass-trend.component.html',
  styleUrls: ['./yh-allclass-trend.component.scss'],
})
export class YhAllclassTrendComponent implements OnInit {
  filterForm = new FormGroup({
    startDate: new FormControl('2025-01-01'),
    endDate: new FormControl('2025-12-31'),
  });

  isLoading = false;
  dataLoaded = false;
  classes: any[] = [];
  formattedData: any = {};
  months: string[] = [];

  selectedCourse: string | null = null;
  selectedMonth: string | null = null;

  courseStats: {
    [key: string]: {
      totalCount: number;
      confirmedCount: number;
      openRate: number;
    };
  } = {};

  constructor(private yhService: YhService) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    const { startDate, endDate } = this.filterForm.value;
    if (!startDate || !endDate) return;

    this.isLoading = true;
    this.dataLoaded = false;

    this.yhService.getAllClasses(startDate, endDate).subscribe((data) => {
      this.classes = data.sort((a, b) => a.MName.localeCompare(b.MName));
      this.processData();
      this.isLoading = false;
      this.dataLoaded = true;
    });
  }

  processData() {
    const groupedData: {
      [key: string]: {
        [key: string]: { count: number; hasConfirmed: boolean };
      };
    } = {};

    const courseStats: {
      [key: string]: {
        totalCount: number;
        confirmedCount: number;
        openRate: number;
      };
    } = {};

    this.classes.forEach((cls) => {
      const ym = cls.StartYM;
      const mName = cls.MName;

      if (!groupedData[mName]) {
        groupedData[mName] = {};
        courseStats[mName] = { totalCount: 0, confirmedCount: 0, openRate: 0 };
      }

      if (!groupedData[mName][ym]) {
        groupedData[mName][ym] = { count: 0, hasConfirmed: false };
      }

      groupedData[mName][ym].count++;
      courseStats[mName].totalCount++; // ✅ 增加總數

      if (cls.CState === '確定開班') {
        groupedData[mName][ym].hasConfirmed = true;
        courseStats[mName].confirmedCount++; // ✅ 增加開課數
      }
    });

    // ✅ 計算開課率
    Object.keys(courseStats).forEach((mName) => {
      const { totalCount, confirmedCount } = courseStats[mName];
      courseStats[mName].openRate =
        totalCount > 0
          ? parseFloat(((confirmedCount / totalCount) * 100).toFixed(1))
          : 0;
    });

    // 取得所有月份 (確保表頭完整)
    this.months = Array.from(
      new Set(this.classes.map((cls) => cls.StartYM))
    ).sort();

    this.formattedData = groupedData;
    this.courseStats = courseStats; // ✅ 存入計算結果
  }

  selectCourse(mName: string | null) {
    this.selectedCourse = this.selectedCourse === mName ? null : mName; // 點擊相同課程時取消選取
  }

  selectMonth(ym: string) {
    this.selectedMonth = this.selectedMonth === ym ? null : ym; // 點擊相同月份時取消選取
  }

  getCellClass(mName: string, ym: string): string {
    let classes = '';

    if (!this.formattedData[mName] || !this.formattedData[mName][ym])
      return classes;

    classes += this.formattedData[mName][ym].hasConfirmed
      ? ' green-bg'
      : ' gray-bg';

    if (this.selectedCourse === mName) {
      classes += ' highlight-row';
    }

    if (this.selectedMonth === ym) {
      classes += ' highlight-column';
    }

    return classes.trim();
  }

  getTooltip(mName: string, ym: string): string {
    if (!this.formattedData[mName] || !this.formattedData[mName][ym]) return '';

    const filteredCourses = this.classes.filter(
      (cls) => cls.MName === mName && cls.StartYM === ym
    );

    // ✅ 使用 `\n` 讓 Angular Material 的 Tooltip 自動換行
    return filteredCourses
      .map(
        (cls) =>
          `${cls.StartYM} - ${cls.CName} - ${cls.CState} - 學員: ${cls.StudentCount}`
      )
      .join('\n');
  }

  setYearRange(yearOrEvent: number | Event) {
    let year: number;

    if (typeof yearOrEvent === 'number') {
      year = yearOrEvent; // 如果是數字，直接使用
    } else {
      const target = yearOrEvent.target as HTMLSelectElement;
      year = parseInt(target.value, 10); // 從事件中取得數字
    }

    this.filterForm.patchValue({
      startDate: `${year}-01-01`,
      endDate: `${year}-12-31`,
    });
  }

  showTooltip(event: MouseEvent) {
    const tooltip = document.getElementById('custom-tooltip');
    if (!tooltip) return;

    const target = event.target as HTMLElement;
    const tooltipContent = target.getAttribute('data-tooltip');

    if (!tooltipContent) return;

    tooltip.innerHTML = tooltipContent; // ✅ 顯示多行 Tooltip
    tooltip.style.display = 'block';
    tooltip.style.left = `${event.pageX + 10}px`;
    tooltip.style.top = `${event.pageY + 10}px`;
  }

  hideTooltip() {
    const tooltip = document.getElementById('custom-tooltip');
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  }

  get courseNames(): string[] {
    return Object.keys(this.formattedData);
  }
}
