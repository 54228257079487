<div class="assistant-management-container">
  <h1>AI 助手管理</h1>
  <p>查看並管理系統中的 AI 助手</p>

  <!-- 錯誤訊息顯示 -->
  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>

  <!-- 載入中指示器 -->
  <div *ngIf="isLoading" class="loading-spinner">
    <div class="spinner"></div>
    <p>載入中...</p>
  </div>

  <!-- 助手表單 -->
  <div *ngIf="!isLoading && isEditing" class="assistant-form-container">
    <form [formGroup]="assistantForm" (ngSubmit)="submitAssistantForm()">
      <h2>{{ selectedAssistant ? '編輯助手' : '新增助手' }}</h2>

      <!-- 基本資料 -->
      <div class="form-row">
        <div class="form-group">
          <label for="assistantNo">助手編號 *</label>
          <input type="text" id="assistantNo" formControlName="assistantNo" placeholder="請輸入助手編號" class="form-control">
          <div *ngIf="assistantForm.get('assistantNo')?.invalid && assistantForm.get('assistantNo')?.touched" class="error-text">
            <span *ngIf="assistantForm.get('assistantNo')?.errors?.['required']">助手編號為必填</span>
          </div>
        </div>

        <div class="form-group">
          <label for="title">標題 *</label>
          <input type="text" id="title" formControlName="title" placeholder="請輸入標題" class="form-control">
          <div *ngIf="assistantForm.get('title')?.invalid && assistantForm.get('title')?.touched" class="error-text">
            <span *ngIf="assistantForm.get('title')?.errors?.['required']">標題為必填</span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group full-width">
          <label for="content">內容描述 *</label>
          <textarea id="content" formControlName="content" placeholder="請輸入內容描述" class="form-control" rows="3"></textarea>
          <div *ngIf="assistantForm.get('content')?.invalid && assistantForm.get('content')?.touched" class="error-text">
            <span *ngIf="assistantForm.get('content')?.errors?.['required']">內容描述為必填</span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="imageurl">圖片 URL *</label>
          <input type="text" id="imageurl" formControlName="imageurl" placeholder="請輸入圖片 URL" class="form-control">
          <div *ngIf="assistantForm.get('imageurl')?.invalid && assistantForm.get('imageurl')?.touched" class="error-text">
            <span *ngIf="assistantForm.get('imageurl')?.errors?.['required']">圖片 URL 為必填</span>
          </div>
          <div *ngIf="assistantForm.get('imageurl')?.value" class="image-preview">
            <img [src]="assistantForm.get('imageurl')?.value" alt="圖片預覽">
          </div>
        </div>

        <div class="form-group">
          <label for="memo">備註訊息</label>
          <textarea id="memo" formControlName="memo" placeholder="請輸入備註訊息" class="form-control" rows="3"></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="assistantid">助手 ID</label>
          <input type="text" id="assistantid" formControlName="assistantid" placeholder="請輸入助手 ID" class="form-control">
        </div>

        <div class="form-group">
          <label for="vector_store_id">向量存儲 ID</label>
          <input type="text" id="vector_store_id" formControlName="vector_store_id" placeholder="請輸入向量存儲 ID" class="form-control">
        </div>

        <div class="form-group checkbox">
          <label>
            <input type="checkbox" formControlName="enable"> 啟用助手
          </label>
        </div>
      </div>

      <!-- 初始化設定 -->
      <div formGroupName="Initialized" class="initialized-section">
        <h3>初始化設定</h3>

        <div class="form-row">
          <div class="form-group">
            <label for="init_image">初始圖片 URL</label>
            <input type="text" id="init_image" formControlName="init_image" placeholder="請輸入初始圖片 URL" class="form-control">
            <div *ngIf="assistantForm.get('Initialized')?.get('init_image')?.value" class="image-preview">
              <img [src]="assistantForm.get('Initialized')?.get('init_image')?.value" alt="初始圖片預覽">
            </div>
          </div>

          <div class="form-group">
            <label for="init_word">初始文字</label>
            <input type="text" id="init_word" formControlName="init_word" placeholder="請輸入初始文字" class="form-control">
          </div>

          <div class="form-group">
            <label for="chatavator">對話頭像 URL</label>
            <input type="text" id="chatavator" formControlName="chatavator" placeholder="請輸入對話頭像 URL" class="form-control">
            <div *ngIf="assistantForm.get('Initialized')?.get('chatavator')?.value" class="image-preview">
              <img [src]="assistantForm.get('Initialized')?.get('chatavator')?.value" alt="對話頭像預覽">
            </div>
          </div>
        </div>

        <!-- 初始選單項目 -->
        <div class="menu-section">
          <h4>初始選單項目</h4>
          <div formArrayName="init_meun">
            <div *ngFor="let menu of menuArray.controls; let i = index" [formGroupName]="i" class="menu-item">
              <div class="form-row">
                <div class="form-group">
                  <label>標題 *</label>
                  <input type="text" formControlName="title" placeholder="請輸入選單標題" class="form-control">
                  <div *ngIf="menu.get('title')?.invalid && menu.get('title')?.touched" class="error-text">
                    <span *ngIf="menu.get('title')?.errors?.['required']">標題為必填</span>
                  </div>
                </div>

                <div class="form-group">
                  <label>內容 *</label>
                  <input type="text" formControlName="content" placeholder="請輸入選單內容" class="form-control">
                  <div *ngIf="menu.get('content')?.invalid && menu.get('content')?.touched" class="error-text">
                    <span *ngIf="menu.get('content')?.errors?.['required']">內容為必填</span>
                  </div>
                </div>

                <div class="form-group">
                  <label>函數名稱</label>
                  <input type="text" formControlName="functionname" placeholder="請輸入函數名稱" class="form-control">
                </div>

                <div class="form-group checkbox">
                  <label>
                    <input type="checkbox" formControlName="enable"> 啟用選單
                  </label>
                </div>

                <button type="button" class="btn btn-danger" (click)="removeMenuItem(i)">
                  移除
                </button>
              </div>
            </div>

            <button type="button" class="btn btn-outline" (click)="addMenuItem()">
              + 添加選單項目
            </button>
          </div>
        </div>
      </div>

      <!-- 表單按鈕 -->
      <div class="form-actions">
        <button type="submit" class="btn btn-primary" [disabled]="assistantForm.invalid">
          {{ selectedAssistant ? '更新' : '創建' }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancelEdit()">取消</button>
      </div>
    </form>
  </div>

  <!-- 助手列表 -->
  <div *ngIf="!isLoading && !isEditing" class="assistant-list-container">
    <div class="table-header">
      <h2>助手列表</h2>
      <button class="btn btn-primary" (click)="addAssistant()">+ 新增助手</button>
    </div>

    <div class="assistants-grid">
      <div *ngFor="let assistant of moduleConfig?.lobby" class="assistant-card">
        <div class="card-header">
          <h3>{{ assistant.title }}</h3>
          <span class="assistant-no">ID: {{ assistant.assistantNo }}</span>
          <span class="status" [class.enabled]="assistant.enable" [class.disabled]="!assistant.enable">
            {{ assistant.enable ? '啟用' : '停用' }}
          </span>
        </div>

        <div class="card-body">
          <div class="image-container">
            <img [src]="assistant.imageurl" alt="{{ assistant.title }} 圖片">
          </div>

          <div class="content">
            <p>{{ assistant.content }}</p>
            <div *ngIf="assistant.memo" class="memo" [innerHTML]="assistant.memo"></div>
          </div>
        </div>

        <div class="card-footer">
          <button class="btn btn-edit" (click)="editAssistant(assistant)">編輯</button>
          <button class="btn btn-delete" (click)="deleteAssistant(assistant)">刪除</button>
        </div>
      </div>

      <div *ngIf="!(moduleConfig?.lobby ?? []).length" class="no-data">
        暫無助手資料
      </div>
    </div>
  </div>
</div>
