import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export interface RadioOption {
  value: string;
  label: string;
  color?: string;
  iconClass?: string;
}

@Component({
  selector: 'app-form-radiobutton',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label class="question-label">{{ label }}</label>
      <div class="button-group">
        <button
          *ngFor="let option of options"
          type="button"
          [ngClass]="[
            'btn-' + (option.color || 'blue'),
            value === option.value ? 'selected' : ''
          ]"
          (click)="onOptionClick(option.value)"
        >
          <i *ngIf="option.iconClass" [class]="option.iconClass"></i>
          {{ option.label }}
        </button>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadiobuttonComponent),
      multi: true,
    },
  ],
  styleUrls: ['total-component.scss'],
})
export class FormRadiobuttonComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() options: RadioOption[] = [];
  @Input() name: string = '';  // 新增 name 輸入屬性

  @Output() clearRemarks = new EventEmitter<void>();

  value: string = '';

  onChange: any = () => {};
  onTouched: any = () => {};

  onOptionClick(selectedValue: string): void {
    this.value = selectedValue;
    this.onChange(this.value);
    this.clearRemarks.emit();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
