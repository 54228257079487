import { provideRouter, Route } from '@angular/router';

import { LayoutFullComponent } from './layouts/layout-full/layout-full.component';
import { LayoutCenterComponent } from './layouts/layout-center/layout-center.component';
import { LayoutCenterToolbarComponent } from './layouts/layout-center-toolbar/layout-center-toolbar.component';

import { ChatbotComponent } from './pages/chatbot/chatbot.component';
import { FilebankComponent } from './pages/filebank/filebank.component';
import { SdsparserComponent } from './pages/sdsparser/sdsparser.component';
import { LoginComponent } from './pages/login/login.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { AssistantComponent } from './pages/assistant/assistant.component';
import { WidgetdemoComponent } from './pages/widgetdemo/widgetdemo.component';

import { UrlRedirectComponent } from './pages/url-redirect/url-redirect.component';

import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutFullComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'urlredirect', component: UrlRedirectComponent },
      // 可以在這裡加入未授權頁面
      { path: 'unauthorized', loadComponent: () => import('./pages/unauthorized/unauthorized.component').then(c => c.UnauthorizedComponent) },
    ],
  },
  {
    path: '',
    component: LayoutCenterComponent,
    canActivate: [AuthGuard], // 為整個 LayoutCenter 添加保護
    children: [
      { path: 'filebank', component: FilebankComponent },
      { path: 'sdsparser', component: SdsparserComponent },
      { path: 'lobby', component: LobbyComponent },
      { path: 'widgetdemo', component: WidgetdemoComponent },
    ],
  },
  {
    path: '',
    component: LayoutCenterToolbarComponent,
    canActivate: [AuthGuard], // 為整個 LayoutCenterToolbar 添加保護
    children: [
      { path: 'assistant', component: AssistantComponent },
    ],
  },
  // 確保如果用戶訪問不存在的路徑，有一個處理方法
  {
    path: '**',
    redirectTo: 'login',  // 或者使用一個 404 頁面
  },
];

export const appRouting = provideRouter(routes);
