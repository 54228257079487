import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-calendar-pie',
  standalone: true,
  imports: [],
  templateUrl: './calendar-pie.component.html',
  styleUrls: ['./calendar-pie.component.scss']
})
export class CalendarPieComponent implements OnInit {

  @ViewChild('echartContainer', { static: true }) echartContainer!: ElementRef;

  chartOption: any;

  ngOnInit(): void {
    const cellSize = [80, 80];
    const pieRadius = 30;

    function getVirtualData() {
      const date = +echarts.time.parse('2017-02-01');
      const end = +echarts.time.parse('2017-03-01');
      const dayTime = 3600 * 24 * 1000;
      const data: [string, number][] = [];
      for (let time = date; time < end; time += dayTime) {
        data.push([echarts.time.format(time, '{yyyy}-{MM}-{dd}', false), Math.floor(Math.random() * 10000)]);
      }
      return data;
    }

    const scatterData = getVirtualData();
    const pieSeries = scatterData.map(function (item, index) {
      return {
        type: 'pie',
        id: 'pie-' + index,
        center: item[0],
        radius: pieRadius,
        coordinateSystem: 'calendar',
        label: {
          formatter: '{c}',
          position: 'inside'
        },
        data: [
          { name: '無缺失', value: Math.round(Math.random() * 24) },
          { name: '輕度缺失', value: Math.round(Math.random() * 24) },
          { name: '中重度缺失', value: Math.round(Math.random() * 24) }
        ]
      } as echarts.PieSeriesOption;
    });

    this.chartOption = {
      tooltip: {},
      legend: {
        data: ['無缺失', '輕度缺失', '中重度缺失'],
        bottom: 20
      },
      calendar: {
        top: 'middle',
        left: 'center',
        orient: 'vertical',
        cellSize: cellSize,
        yearLabel: {
          show: false,
          fontSize: 30
        },
        dayLabel: {
          margin: 20,
          firstDay: 1,
          nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        monthLabel: {
          show: false
        },
        range: ['2017-02']
      },
      series: [
        {
          id: 'label',
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 0,
          label: {
            show: true,
            formatter: function (params: any) {
              return echarts.time.format(params.value[0], '{dd}', false);
            },
            offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
            fontSize: 14
          },
          data: scatterData
        },
        ...pieSeries
      ]
    };
  }

  ngAfterViewInit(): void {
    const chart = echarts.init(this.echartContainer.nativeElement);
    chart.setOption(this.chartOption);
  }
}
