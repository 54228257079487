import { Component, OnInit, Input ,Output,EventEmitter, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpanaiService } from '../../services/openai.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileuploaderComponent } from '../../shared/fileuploader/fileuploader.component';
import { environment } from '../../environments/environment';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-filebank',
  standalone: true,
  imports: [CommonModule, FileuploaderComponent],
  templateUrl: './filebank.component.html',
  styleUrls: ['./filebank.component.scss'],
})
export class FilebankComponent implements OnInit {

  @Input() vector_store_id: string = '';  // 透過 Input 注入 vector_store_id
  @Output() backToChat: EventEmitter<void> = new EventEmitter<void>();

  filelist: any[] = [];
  filecount = 0;
  havedeletefile = false;

  //取得 Vector Store ID
  loadingstatus = 'Loading files...';

  existFileList:string[] = [];


  constructor(
    private openAiApiService: OpanaiService,
    private modalService: NgbModal,
    private router: Router // 注入 Router
  ) {}

  ngOnInit() {
    if (!this.vector_store_id || this.vector_store_id.trim() === '') {
      alert('vector_store_id is empty');
      // 若 vector_store_id 為空則導回 lobby 頁面
    } else {
      // 取得檔案列表
      this.getfillist(this.vector_store_id);
    }
  }

  getfillist(vector_store_id: string) {
    this.openAiApiService.getfilelist(vector_store_id).subscribe((response) => {

      this.filelist = response.files;
      this.filecount = response.count;

      if (this.filecount == 0) {
          this.loadingstatus=  'No files found.'; // 設置顯示文字
      } else {
        this.loadingstatus='';
        // 從 filelist 中提取名稱並存入 existFileList
      this.existFileList = this.filelist.map((file: { filename: string }) => file.filename);
      }
    });
  }

  formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const sizeInUnit = bytes / Math.pow(k, i);
    return `${sizeInUnit.toFixed(2)} ${sizes[i]}`;
  }

  deleteFile(fileId: string): void {
    // 找到被刪除的文件
    const file = this.filelist.find((f) => f.id === fileId);
    if (file) {
      // 切換 deleted 狀態
      file.deleted = !file.deleted;
    }

    const deletedCount = this.filelist.filter((f) => f.deleted).length;

    this.havedeletefile = deletedCount > 0;
  }

  showdeleteconfirm(): void {
    // 顯示 Bootstrap Modal
    this.modalService.open({ ariaLabelledBy: 'modal-basic-title' });
  }

  confirmDelete(): void {
    const deletedCount = this.filelist.filter((f) => f.deleted).length;

    if (deletedCount > 0) {
      for (const file of this.filelist) {
        if (file.deleted) {
          this.DeleteFileByReal(file.id);
        }
      }
    }

    //重新計算檔案個數
    this.filecount = this.filelist.length;
  }

  DeleteFileByReal(fileId: string): void {
    // 實際刪除文件的邏輯
    this.openAiApiService
      .deletefile(this.vector_store_id, fileId)
      .subscribe((response) => {
        // if (response.status === 'success') {
        //   alert("刪除成功");
        // }
      });

    this.filelist = this.filelist.filter((f) => f.id !== fileId);
  }



}
