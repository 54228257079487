import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService, FindState, FindResultMatchesCount } from 'ngx-extended-pdf-viewer';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';  // 引入 NgxExtendedPdfViewerModule
import { FormatJsonPipe } from '../../pipes/format-sdsjson.pipe';
import { FormsModule } from '@angular/forms';  // 引入 FormsModule

@Component({
  selector: 'app-sdsparser',
  standalone: true,  // 標明這是一個 standalone 組件
  templateUrl: './sdsparser.component.html',
  styleUrls: ['./sdsparser.component.scss'],
  imports: [CommonModule, NgxExtendedPdfViewerModule,FormatJsonPipe,FormsModule]  // 導入必要的模組
})


export class SdsparserComponent {


  pdfSrc = 'assets/files/sds/71-106_yce-648.pdf'; // 設定 PDF 檔案的位置
  filename = '71-106_yce-648.pdf';

  samplejson:any = {
    "0. 基本情報": {
      "整理番号": "71-106",
      "作成日": "2020/07/27",
      "製品及び会社情報": {
        "製品名": "ロックタイト（はめ合い用接着剤）",
        "製品番号": "YCE-648",
        "製品コード": "236606",
        "会社名": "京都機械工具株式会社",
        "住所": "京都府久世郡久御山町佐山新開地１２８番地",
        "担当部門": "Ｔ＆Ｍ推進本部 商品開発部",
        "電話番号": "0774-46-3700",
        "FAX番号": "0774-46-3008",
        "メールアドレス": "export-m@kyototool.co.jp"
      },
      "その他の情報": "詳細は別紙の SDSを参照のこと。本記載内容は、現時点で入手できる情報に基づいて作成しておりますが、最新情報により改訂される場合があります。また、情報の正確性や完全性、製品の安全を保証するものではありません。"
    },
    "1. 製品及び会社情報": {
      "製品名": "LOCTITE 648 RETAINING COMPOUND",
      "製品コード": "236606",
      "SDS番号": "153474",
      "会社名": "ヘンケルジャパン株式会社",
      "住所": "東京都品川区東品川2-2-8 スフィアタワー天王洲 14F 140-0002",
      "担当部門": "",
      "緊急連絡先": "+81 (45) 758-1820",
      "整理番号": "236606"
    },
    "2. 危険有害性の要約": {
      "GHS分類": {
        "吸引性呼吸器有害性": "",
        "皮膚腐食性": "区分 1A",
        "眼に対する損傷/眼刺激性": "区分 1",
        "皮膚感作性": "区分 1",
        "特定標的臓器毒性 - 単回暴露": "区分 3 (気道刺激性)",
        "水生環境有害性(長期間)": "区分 3"
      },
      "GHSラベル要素": {
        "絵表示": ["危険"],
        "注意喚起語": "危険",
        "危険有害性情報": [
          "H314 重篤な皮膚の薬傷及び眼の損傷",
          "H317 アレルギ一性皮膚反応を起こすおそれ",
          "H318 重篤な眼の損傷",
          "H335 呼吸器への刺激のおそれ",
          "H412 長期継続的影響によって水生生物に有害"
        ],
        "注意書き": {
          "安全対策": "P261 粉じん/煙/ガス/ミスト/蒸気/スプレーの吸入を避けること。",
          "応急措置": [
            "P301+P330+P331 飲み込んだ場合：口をすすぐこと。無理に吐かせないこと。",
            "P303+P361+P353 皮膚に付着した場合：直ちに汚染された衣類を全て脱ぐこと。皮膚を流水／シャワーで洗うこと。",
            "P304+P340+P310 吸入した場合：被災者を空気の新鮮な場所に移動し、呼吸しやすい姿勢で休息させること。直ちに医師に連絡すること。",
            "P305+P351+P338+P315 眼に入った場合：水で数分間注意深く洗うこと。コンタクトレンズを外すこと。直ちに医師に診断/手当てを受けること。",
            "P333+P313 皮膚刺激または発疹が生じた場合：医師の診断/手当てを受けること。",
            "P362+P364 汚染された衣類を脱ぎ、再使用する場合には洗濯をすること。"
          ],
          "保管": "P403+P233 換気の良い場所で保管すること。容器を密閉しておくこと。P405 施錠して保管すること。",
          "廃棄": "P501 廃棄するときは、適用法令および製品特性に従い、適切な処理および廃棄施設に内容物/容器を廃棄すること。"
        }
      },
      "製品ラベル有害性情報の注意": "製品ラベルの有害性情報は、個別の製品安全データシートの記載内容と異なる場合があります。"
    },
    "3. 組成、成分情報": {
      "化学物質・混合物の区別": "混合物",
      "化学名又は一般名": "",
      "成分及び含有量": [
        {
          "成分": "アクリル酸",
          "wt%": "8.6%"
        },
        {
          "成分": "ポリウレタンメタクリレート樹脂",
          "wt%": "> 50 - < 60%"
        },
        {
          "成分": "ヒドロキシプロピルメタクリレート",
          "wt%": ">= 1 - < 10%"
        },
        {
          "成分": "メタクリレートエステル",
          "wt%": "> 10 - < 20%"
        },
        {
          "成分": "キュメンヒドロパーオキサイド",
          "wt%": "1.5%"
        },
        {
          "成分": "ポリグリコールジメタクリレート",
          "wt%": "> 1 - < 10%"
        }
      ],
      "化学特性": "",
      "官報公示整理番号": "",
      "CASナンバー": "",
      "危険有害成分": {
        "化学物質管理促進法(PRTR法)": "",
        "労働安全衛生法": "",
        "毒物劇物取締法": ""
      }
    },
    "4. 応急措置": {
      "吸入した場合": "新鮮な空気のある場所に移動し、呼吸しやすい姿勢で休息させること。医師の診察を受けること。",
      "皮膚に付着した場合": "流水とせっけんでよく洗い流すこと。医師の診察を受けること。",
      "目に入った場合": "多量の水で洗うこと。できるならばぬるま湯で最低でも15分間、まぶたを押さえて眼を開けた状態で洗うこと。医師の診察を受けること。",
      "飲み込んだ場合": ["無理に吐かせないこと。医師の診察を受けること。被災者を安静にしておくこと。"],
      "最も重要な徴候及び症状": [],
      "応急措置をする者の保護": "",
      "医師に対する特別注意事項": ""
    },
    "5. 火災時の措置": {
      "消火剤": ["二酸化炭素", "泡", "粉末"],
      "使ってはならない消火剤": "",
      "火災時の特定危険有害性": "炭素酸化物、窒素酸化物、刺激性有機蒸気。",
      "特定の消火方法": ["火災が起きた場合は容器に散水して低温を保つ。"],
      "消火を行う者の保護": "自給式呼吸器および出動服のような全身保護服を着用すること。"
    },
    "6. 漏出時の措置": {
      "人体に対する注意事項": ["皮膚および眼への接触を避けること。保護具を着用すること。十分な換気を保つこと。"],
      "環境に対する注意事項": ["製品が下水または排水溝に入らないようにすること。"],
      "回収・中和並びに封じ込め及び浄化の方法": ["廃棄するまで密閉できる容器に隙間を空けて入れ保管する。不活性な材質のもので吸収する（例えば、砂、シリカゲル、酸性バインダー、一般的なバインダー、おがくず）。"],
      "二次災害の防止策": []
    },
    "7. 取扱い及び保管上の注意": {
      "取扱い": {
        "技術的対策": [],
        "局所排気・全体換気": "",
        "注意事項": "感作の危険を防ぐため長時間若しくは、繰り返しの接触は避けること。よく換気された場所で使用すること。皮膚および眼への接触を避けること。"
      },
      "保管": {
        "適切な保管条件": ["原容器に入れたまま、8-21°Cで保管する。"],
        "注意事項": "異物混入のおそれがあるため使用した液は元の容器へ戻さないこと。"
      }
    },
    "8. 暴露防止及び保護措置": {
      "設備対策": [],
      "管理濃度": "",
      "許容濃度": [],
      "保護具": {
        "呼吸器用の保護具": "換気の良い場所でのみ使用すること。",
        "手の保護具": "",
        "目の保護具": "保護眼鏡を着用すること。",
        "皮膚及び身体の保護具": "適切な保護服を着用すること。"
      },
      "適切な衛生対策": ""
    },
    "9. 物理的及び化学的性質": {
      "形状": "液体",
      "色": "緑",
      "臭い": "特徴的な",
      "pH": "該当なし",
      "融点・凝固点": "データ無し",
      "沸点": "> 100.0 °C (> 212 °F)",
      "分解温度": "",
      "引火点": "> 93.3 °C (> 199.94 °F)",
      "自然発火温度": "データ無し",
      "爆発特性": {
        "爆発限界": {
          "下限": "",
          "上限": ""
        }
      },
      "蒸気圧": "< 400 Pa",
      "蒸気密度": "",
      "比重": "1.1",
      "溶解性": "",
      "n-オクタノール/水分配係数": "",
      "動粘度": ""
    },
    "10. 安定性及び反応性": {
      "安定性": "",
      "反応性": "強酸化剤、還元剤",
      "避けるべき条件": "通常の使用、保管条件では安定。",
      "混触危険物質": "",
      "危険有害な分解生成物": "刺激性の有機蒸気、炭素酸化物",
      "その他": ""
    },
    "11. 有害性情報": {
      "基油として": {
        "急性毒性": [],
        "皮膚腐食性・刺激性": "",
        "眼に対する損傷性・刺激性": "",
        "呼吸器又は皮膚感作性": {
          "呼吸器感作性": "",
          "皮膚感作性": ""
        },
        "生殖細胞変異原性": "",
        "発がん性": [],
        "生殖毒性": "",
        "特定標的臓器毒性(単回ばく露)": "",
        "特定標的臓器毒性(反復ばく露)": ""
      }
    },
    "12. 環境影響情報": {
      "基油として": {
        "水生環境毒性": {
          "魚類急性毒性": "",
          "魚類最大無影響量": "",
          "甲殻類遊泳阻害": "",
          "甲殻類最大無影響量": "",
          "藻類最大無影響量": "",
          "微生物発光阻害": ""
        },
        "水生環境急性有害性": "",
        "水生環境慢性有害性": "",
        "移動性": [],
        "残留性・分解性": "",
        "生体蓄積性": "",
        "オゾン層への有害性": "",
        "その他": ""
      }
    },
    "13. 廃棄上の注意": {
      "残余廃棄物": ["国及び地方自治体の規則に従って廃棄すること。"],
      "汚染容器及び包装": "使用後は、残留物の付着したチューブ、箱、ボトルは化学汚染物質として公認された埋め地に処理するか焼却する。"
    },
    "14. 輸送上の注意": {
      "国際規制": {
        "国連分類、品名等": "",
        "国連番号": ""
      },
      "国内規制": {
        "陸上輸送": {
          "消防法": "",
          "容器": ""
        },
        "海上輸送": "",
        "航空輸送": ""
      },
      "輸送の特定の安全対策及び条件": []
    },
    "15. 適用法令": {
      "国内法令": {
        "消防法": "第4類引火性液体, 第三石油類 非水溶性",
        "化学物質管理促進法(PRTR法)": "第1種指定化学物質 アクリル酸 政令番号 4, キュメンヒドロパーオキサイド 政令番号 440",
        "労働安全衛生法": "名称等を通知すべき有害物 アクリル酸, 変異原性が認められた既存化学物質 キュメンヒドロパーオキサイド",
        "毒物劇物取締法": "該当しない",
        "海洋汚染防止法": "",
        "下水道法": "",
        "水質汚濁防止法": "",
        "廃棄物の処理及び清掃に関する法律": ""
      }
    },
    "16. その他の情報": [
      "発行日: 14.11.2019",
      "問い合わせ先: 近藤 由紀子、製品安全性及び規制業務担当",
      "MSDSの内容に関するお問い合わせ ヘンケルジャパン株式会社 製品安全性及び規制業務担当 横浜市磯子区新磯子町27-7 TEL:045-758-1780 FAX:045-758-1771"
    ]
  };

  public searchTerm: string = '';
  public currentMatchNumber?: number;
  public totalMatches?: number;
  public findState: FindState = FindState.FOUND;

  constructor(
    private pdfViewerService: NgxExtendedPdfViewerService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  // 在視圖加載後監聽點擊事件
  ngAfterViewInit() {
    const jsonKeys = this.el.nativeElement.querySelectorAll('.json-key');
    jsonKeys.forEach((key: HTMLElement) => {
      this.renderer.listen(key, 'click', () => {
        this.onKeyClick(key.getAttribute('data-key'));
      });
    });
  }

  // 處理點擊事件
  onKeyClick(key: string | null) {
    if (key) {
      this.searchbykey(key);
    }
  }

  // 搜尋並高亮關鍵字
  search(): void {
    if (this.searchTerm) {
      this.searchbykey(this.searchTerm);
    }
  }

  searchbykey(keyword: string) {
    // 觸發搜尋
    this.pdfViewerService.find(keyword, {
      highlightAll: true,  // 將所有匹配詞語高亮
      matchCase: false,    // 忽略大小寫
      wholeWords: false,   // 允許部分匹配
    });
  }

  updateFindMatchesCount(result: FindResultMatchesCount) {
    this.currentMatchNumber = result.current;
    this.totalMatches = result.total;
  }

  updateFindState(result: FindState) {
    this.findState = result;
  }

  // 跳到下一個匹配項
  findNext(): void {
    this.pdfViewerService.findNext();
  }

  // 跳到上一個匹配項
  findPrevious(): void {
    this.pdfViewerService.findPrevious();
  }

  // 重置高亮效果
  resetHighlights(): void {
    this.pdfViewerService.find('', { highlightAll: false }); // 清除搜尋與高亮
    this.currentMatchNumber = undefined;
    this.totalMatches = undefined;
  }

  getJsonKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }
}


