<div class="component-container">
  <h2>動火許可單</h2>
  <div *ngIf="formfinished">Form submission completed!</div>
  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">

      <div class="form-section">
        <label for="fire-duration">預計動火期間</label>
        <input
          type="date"
          id="fire-duration-start"
          [(ngModel)]="formData.fireDuration.start"
          name="fire-duration-start"
        />
        <input
          type="date"
          id="fire-duration-end"
          [(ngModel)]="formData.fireDuration.end"
          name="fire-duration-end"
        />
      </div>

      <div class="form-section">
        <label>動火區域</label>
        <div class="radio-container">
        <div class="radio-item">
          <input type="radio" [(ngModel)]="formData.fireArea" value="indoor" name="fire-area" id="fire-area-indoor" />
          <label for="fire-area-indoor">室內</label>
        </div>
        <div class="radio-item">
          <input type="radio" [(ngModel)]="formData.fireArea" value="outdoor" name="fire-area" id="fire-area-outdoor" />
          <label for="fire-area-outdoor">室外</label>
        </div>
      </div>
      </div>

      <div class="form-section">
        <label>火警偵測隔離</label>
        <div class="radio-container">
        <div class="radio-item">
          <input type="radio" [(ngModel)]="formData.alarmIsolation" value="need" name="alarm-isolation" id="isolation-need" />
          <label for="isolation-need">需要</label>
        </div>
        <div class="radio-item">
          <input type="radio" [(ngModel)]="formData.alarmIsolation" value="not-need" name="alarm-isolation" id="isolation-not-need" />
          <label for="isolation-not-need">不需要</label>
        </div>
      </div>
      </div>



      <div class="form-section">
        <label>動火原因(明火)</label>
        <div class="checkbox-container">
          <div *ngFor="let category of openFlameReason_torch" class="checkbox-item">
            <!-- 設置 id 與 label for 屬性匹配，這樣 label 可以點擊選擇 checkbox -->
            <input type="checkbox" [(ngModel)]="category.selected" name="work-category" [id]="'checkbox-' + category.name" />
            <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
          </div>
        </div>
      </div>

      <div class="form-section">
        <label>動火原因(暗火)</label>
        <div class="checkbox-container">
          <div *ngFor="let category of openFlameReason_generator" class="checkbox-item">
            <!-- 設置 id 與 label for 屬性匹配，這樣 label 可以點擊選擇 checkbox -->
            <input type="checkbox" [(ngModel)]="category.selected" name="work-category" [id]="'checkbox-' + category.name" />
            <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
          </div>
        </div>
      </div>


      <div class="form-section">
        <label for="contractor">承攬廠商</label>
        <input type="text" id="contractor" [(ngModel)]="formData.contractor" name="contractor" placeholder="輸入承攬廠商" />
      </div>

      <div class="form-section">
        <label for="purchase-department">請購部門</label>
        <input type="text" id="purchase-department" [(ngModel)]="formData.purchaseDepartment" name="purchase-department" placeholder="輸入請購部門" />
      </div>

      <div class="form-section">
        <label for="safety-officer">現場工安負責人</label>
        <input type="text" id="safety-officer" [(ngModel)]="formData.safetyOfficer" name="safety-officer" placeholder="輸入現場工安負責人" />
      </div>

      <div class="form-section">
        <label for="department-responsible">部門現場負責人</label>
        <input type="text" id="department-responsible" [(ngModel)]="formData.departmentResponsible" name="department-responsible" placeholder="輸入部門現場負責人" />
      </div>


      <div class="form-section">
        <label>作業環境危險評估</label>
        <div class="checkbox-container">
          <div *ngFor="let category of environmentalHazardAssessmentCategories" class="checkbox-item">
            <input type="checkbox" [(ngModel)]="category.selected" name="hazard-assessment" [id]="'checkbox-' + category.name" />
            <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
          </div>
        </div>
      </div>

      <div class="form-section">
        <label>防火措施</label>
        <div class="checkbox-container">
          <div *ngFor="let category of firePreventionMeasuresCategories" class="checkbox-item">
            <input type="checkbox" [(ngModel)]="category.selected" name="fire-prevention" [id]="'checkbox-' + category.name" />
            <label [for]="'checkbox-' + category.name">{{ category.name }}</label>
          </div>
        </div>
      </div>

      <div class="form-section">
        <label for="approval-duration">核准動火時間</label>
        <input
          type="date"
          id="approval-duration-start"
          [(ngModel)]="formData.approvalDuration.start"
          name="approval-duration-start"
        />
        <input
          type="date"
          id="approval-duration-end"
          [(ngModel)]="formData.approvalDuration.end"
          name="approval-duration-end"
        />
      </div>

      <div class="form-section">
        <label for="environmental-safety-staff">環安衛部門人員</label>
        <input type="text" id="environmental-safety-staff" [(ngModel)]="formData.environmentalSafetyStaff" name="environmental-safety-staff" placeholder="輸入環安衛部門人員" />
      </div>

      <div class="form-section">
        <label for="environmental-safety-supervisor">環安衛部門主管</label>
        <input type="text" id="environmental-safety-supervisor" [(ngModel)]="formData.environmentalSafetySupervisor" name="environmental-safety-supervisor" placeholder="輸入環安衛部門主管" />
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
