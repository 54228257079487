<div class="component-container">
  <div *ngIf="formfinished">表單填寫完成</div>
  <div *ngIf="!formfinished">

    <form (ngSubmit)="sendValue()">

      <div class="form-section">
        <h2>承攬人工作場所環境及危害因素通知書</h2>
        <p>工作環境危害因素告知書如下：<br>
          茲依據「職業安全衛生法」第二十六條之規定，謹將有關工廠區工
          作環境、危害因素暨有關安全衛生規定事項告知各承攬人(含再
          承攬人)如下：
        </p>
        <ol>
          <li>承攬人於承攬本廠區工作時，應先充份瞭解本廠區工作環境、危害因素並依「職業安全衛生法」及有關安全衛生規定採取必要之防護措施。</li>
          <li>承攬人應就其部份負擔「職業安全衛生法」所訂僱主之責任。</li>
          <li>承攬人於承攬工作開始時，應即指定工地代表或勞工安全衛生管理人員，就其承攬部分實施自動檢查及負責與本所聯繫。</li>
          <li>承攬人及其所雇勞工應接受本所之指導與協調。</li>
          <li>承攬人應約束其所雇用勞工遵守貴所報備核准之「安全衛生工作守則」及其它有關安全衛生事項之規定。</li>
          <li>二個以上承攬人共同作業時，應互推一人為代表人，負責統一防止職災害之工作。</li>
          <li>承攬人將其承攬人之全部或一部份再交付第三者承攬時，應先徵得本所之同意。再承攬人亦應接受本規定事項之約束。</li>
          <li>本工程為新建工程，部份工作係為高架作業。具有墜落危害之潛在因素，各承攬人於從事作業前應預防患採取之措施。</li>
          <li>為預防物件有自高處掉落擊傷人員之危害因素，承攬人於高處作業時，應整頓工作環境，避免物件掉落，各承攬人並應督促所屬勞工進入工區應佩戴安全帽及於高架作業時配掛安全帶。</li>
          <li>各承攬人因施工作業可能對本所或其它承攬廠商之勞工造成不安全工作環境或危害因素，應通知本所，並採取必要之措施。</li>
          <li>承攬人在施工前應對其勞工施予工作內容說明、危害預知、安全教育訓練、急救方式、緊急應變處理….等訓練</li>
          <li>承攬人應指派專人擔任現場安全衛生管理員，常駐工地執行自動檢查、安全衛生管理，並接受本所安衛人員督導</li>
          <li>承攬人在施工場所應豎立危害/警告標示及圈圍黃色警示帶</li>
          <li>施工中如有安全之虞，本承攬商施工管理委員會得立即要求停工，期間承攬人之所有損失，概由承攬人負完全責任</li>
        </ol>

        <table>
          <thead>
            <tr>
              <th>注意事項</th>
              <th>施工環境或方式</th>
              <th>潛在危害因素</th>
              <th>應備安全措施</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>局限空間作業</td>
              <td>缺氧、可燃性氣體</td>
              <td>
                <ol>
                  <li>機械通風。</li>
                  <li>個人防護裝具(呼吸防護裝具)及防護設備。</li>
                  <li>實施氣體及毒氣氣體濃度測量。</li>
                  <li>每2小時測定(氧、可燃性氣體濃度)。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>電焊、氣焊、氣焰</td>
              <td>灼傷、燃燒、火災、爆炸、輻射危害</td>
              <td>
                <ol>
                  <li>個人防護裝備(電焊/圍巾/防耳套等)。</li>
                  <li>儲備滅火器。</li>
                  <li>遠離火源。</li>
                  <li>鋼焊機需外接接地，備齊防跳防止裝置。</li>
                  <li>瓶瓶直立固定。</li>
                  <li>附近若有易燃物清除或備防火袋。</li>
                  <li>電線跨馬路須有過路橋裝置。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>高架作業</td>
              <td>墜落、施工架倒塌</td>
              <td>
                <ol>
                  <li>安全帶、鋼安全網、良好栓子。</li>
                  <li>施工架確實安裝牢固。</li>
                  <li>施工架頂層可30~45cm須備護欄，避免墜落。</li>
                  <li>18歲以下、55歲以上男女/工人不得施工。</li>
                  <li>有隱匿或機械操作應帶安全帶。</li>
                  <li>施工架以鋼管、門型架為之若具孟宗竹外。</li>
                  <li>梯4cm以上鐵鋼釘鋪鋪線。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>4.</td>
              <td>吊裝作業</td>
              <td>翻倒、吊桿彎曲、貨件掉落、鋼電</td>
              <td>
                <ol>
                  <li>合格人員操作。</li>
                  <li>吊車員自檢。</li>
                  <li>強勁操作點。</li>
                  <li>高層防護或派員監視。</li>
                  <li>嚴禁人員站立於吊裝物上方。</li>
                  <li>工作人員不得作業於吊裝物上方。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>電器作業</td>
              <td>感電</td>
              <td>
                <ol>
                  <li>備漏電路器。</li>
                  <li>電線檢查。</li>
                  <li>禁經潮濕地。</li>
                  <li>須有漏電裝置檢測。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>其他</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-section">
        <label>當前時間</label>
        <p>{{ currentTime }}</p>
      </div>

      <div class="form-section">
        <label>施工單位人員簽名</label>
        <canvas id="signatureCanvas" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature()">清除簽名</button>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
