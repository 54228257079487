import { Component, Input,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { OpanaiService } from '../../services/openai.service';

interface UploadFile {
  file: File;
  progress: number;
  fileId?: string; // 儲存上傳後的 file_id
}

@Component({
  selector: 'app-fileuploader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.scss']
})
export class FileuploaderComponent implements OnInit {

  @Input() vectorStoreId!: string; // 接收的參數

  files: UploadFile[] = []; // 修正型別
  isUploading: boolean = false;
  isUploadFinished: boolean = false;

  uploadResults: { fileName: string; status: string; message?: string }[] = [];

  constructor(
    private http: HttpClient,
    private openAiApiService: OpanaiService
  ) {}

  ngOnInit(): void {
    if (!this.vectorStoreId) {
      throw new Error('vectorStoreId is required');
    }
    else{
      console.log("vectorStoreId:"+this.vectorStoreId);
    }
  }

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFiles(files);
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    this.addFiles(input.files);
  }

  addFiles(fileList: FileList | null) {
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        this.files.push({ file: fileList[i], progress: 0 }); // 初始化 progress
      }
    }
  }

  // 刪除選擇的檔案
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  // 上傳檔案，並顯示進度條
  uploadFiles() {
    this.isUploading = true; // 開始上傳時隱藏刪除按鈕和下方按鈕

    // 清空之前的上傳結果
    this.uploadResults = [];

    // 上傳每個檔案
    const uploadPromises = this.files.map((uploadFile, index) => {
      return this.uploadFileToOpenAi(uploadFile, index);
    });

    // 當所有上傳完成時，顯示結果視窗
    Promise.all(uploadPromises)
      .then(() => {
        this.isUploadFinished = true;
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        this.isUploadFinished = true;
      });
  }

  // 將檔案上傳到 OpenAI，並更新進度
  async uploadFileToOpenAi(uploadFile: UploadFile, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.openAiApiService.uploadFiles([uploadFile.file], this.vectorStoreId).subscribe(
        (event) => {
          // 監控上傳進度
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              uploadFile.progress = Math.round((100 * event.loaded) / event.total);
            }
          } else if (event.type === HttpEventType.Response) {
            // 上傳完成
            this.uploadResults.push({
              fileName: uploadFile.file.name,
              status: 'success',
            });
            resolve(); // 完成上傳
          }
        },
        (error) => {
          // 上傳失敗
          this.uploadResults.push({
            fileName: uploadFile.file.name,
            status: 'fail',
            message: error.message,
          });
          reject(error); // 失敗上傳
        }
      );
    });
  }

  // 清除已選擇的檔案
  clearFiles() {
    this.files = [];
  }
}
