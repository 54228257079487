<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#fileUploaderModal">
  <i class="fa-solid fa-file-arrow-up mx-2"></i>Upload
</button>


<!-- 檔案上傳器 Modal -->
<div class="modal fade" id="fileUploaderModal" tabindex="-1" aria-labelledby="fileUploaderModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploaderModalLabel">File Uploader</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- 檔案拖放區域 -->
        <div
          class="file-drop-area border border-primary p-3 mb-3 text-center"
          (dragover)="onDragOver($event)"
          (drop)="onFileDropped($event)"
        >
        <p>Please prioritize uploading files in PDF format; <br>other file formats may not be supported.<br>Drag and drop the file here or</p>
          <button class="btn btn-primary" (click)="fileInput.click()">choose files</button>
          <input
            #fileInput
            type="file"
            multiple
            accept="application/pdf"
            (change)="onFileSelected($event)"
            hidden
          />
        </div>

        <!-- 檔案列表預覽 -->
        <ul class="list-group">
          <li *ngFor="let uploadFile of files; let i = index" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <!-- 顯示檔案名稱 -->
              <span>{{ uploadFile.file.name }}</span>

              <!-- 刪除檔案按鈕 -->
              <button *ngIf="!isUploading" class="btn btn-danger btn-sm ms-2" (click)="deleteFile(i)">Delete</button>
            </div>

            <!-- 上傳進度條 -->
            <div class="progress mt-2">
              <div
                class="progress-bar"
                role="progressbar"
                [style.width.%]="uploadFile.progress"
                [attr.aria-valuenow]="uploadFile.progress"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ uploadFile.progress }}%
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button *ngIf="!isUploading" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button *ngIf="!isUploading"
          type="button"
          class="btn btn-success"
          [disabled]="files.length === 0"
          (click)="uploadFiles()"
        >

          Upload

        </button>
        <button *ngIf="isUploadFinished" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Done</button>
      </div>

        <!-- 上傳結果顯示 -->
        <div *ngIf="uploadResults.length > 0 && isUploadFinished">
          <div style="text-align: center;">Upload Results
          </div>
          <ul class="list-group">
            <li *ngFor="let result of uploadResults" class="list-group-item">
              <strong>{{ result.fileName }}</strong>: {{ result.status }}
              <span *ngIf="result.message" class="text-danger">{{ result.message }}</span>
            </li>
          </ul>

        </div>

    </div>
  </div>
</div>
