import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DatabaseService } from '../../../services/database.service';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';

interface LogItem {
  timestamp: string;
  companyId: string;
  logType: string;
  userId: string;
  message: string;
  level: string;
}

@Component({
  selector: 'app-loglist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loglist.component.html',
  styleUrl: './loglist.component.scss',
  providers: [DatePipe]
})
export class LoglistComponent implements OnInit {
  @Input() logType: string = '';

  logs: LogItem[] = [];
  isLoading: boolean = false;
  error: string | null = null;
  totalLogs: number = 0;

  constructor(private databaseService: DatabaseService) {}

  ngOnInit(): void {
    this.fetchLogs();
  }

  fetchLogs(): void {
    this.isLoading = true;
    this.error = null;

    const companyId = environment.companyname;

    // 建立查詢條件
    const filter = this.logType ?
      { companyId, logType: this.logType } :
      { companyId };

    // 從資料庫取得資料
    this.databaseService.getDataByQuery(filter, 1000, "Logs")
      .pipe(
        map((response: any) => {
          if (!response?.items) {
            throw new Error('找不到日誌資料');
          }
          // Filter out logs where userId is 'tpic'
          const filteredLogs = response.items.filter((log: LogItem) => log.userId !== 'tpic') as LogItem[];
          this.totalLogs = filteredLogs.length;
          return filteredLogs;
        })
      )
      .subscribe({
        next: (data) => {
          this.logs = data;
          this.isLoading = false;
        },
        error: (err) => {
          console.error('取得日誌資料失敗:', err);
          this.error = '無法載入日誌資料，請稍後再試';
          this.isLoading = false;
        }
      });
  }
}
