<div class="container-fluid vh-100">
  <div class="row h-100">
    <div class="col-md-6 d-flex align-items-center justify-content-center login-left">
      <div class="text-center">
        <div class="login-tittle" [innerHTML]="login_title"></div>
        <img class="login-img mb-4" *ngIf="logoUrl" [src]="logoUrl" alt="Logo" style="max-width: 400px;">
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-center login-right ">
      <div class="login-form w-75">
        <h2 class="mb-4 text-dark" [innerHTML]="title_text"></h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="email" class="form-label">{{id_text}}</label>
            <input type="email" class="form-control" id="username" formControlName="username" >
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">{{pw_text}}</label>
            <input type="password" class="form-control" formControlName="password" id="password" >
          </div>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <button type="submit" class="btn btn-primary login" [disabled]="loginForm.invalid || isSubmitting">
              <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              {{login_text}}
            </button>

            <a  class="text-secondary">{{forget_text}}</a>
          </div>
        </form>
        <p class="text-center text-muted mt-5 ">Copyright © {{copyright}} <span *ngIf="version"> | Version:{{version}}</span> </p>
      </div>
    </div>
  </div>
</div>
