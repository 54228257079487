import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-datetime',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="form-section">
      <label [attr.for]="id">{{ label }}</label>
      <input
        type="datetime-local"
        [id]="id"
        [name]="name"
        [(ngModel)]="value"
        (ngModelChange)="handleChange($event)"
        (blur)="onTouched()"
      />
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDatetimeComponent),
      multi: true
    }
  ],
  styleUrls: ['total-component.scss']
})
export class FormDatetimeComponent implements ControlValueAccessor {
  @Input() label: string = 'Label';
  @Input() id: string = 'datetime-local';
  @Input() name: string = 'datetime-local';

  // 存放給 input 的字串值
  value: string = '';

  // ControlValueAccessor callbacks
  onChange = (value: any) => {};
  onTouched = () => {};

  // 當外部設定值時（例如從資料庫讀取的 Unix 時間戳）
  writeValue(obj: any): void {
    if (typeof obj === 'number') {
      // 將 Unix 時間戳（秒）轉成 Date 物件
      const date = new Date(obj * 1000);
      // 將 Date 轉成符合 datetime-local 格式的字串：YYYY-MM-DDTHH:MM
      // 注意：toISOString() 會回傳 UTC 時間，如果你希望顯示本地時間，
      // 可能需要另外處理格式化
      this.value = date.toISOString().slice(0, 16);
    } else {
      this.value = obj || '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // 如有需要，可在這裡處理 disabled 狀態
  }

  // 當使用者變更日期時，將字串轉換為 Unix 時間戳後傳出去
  handleChange(newValue: string) {
    this.value = newValue;
    const timestamp = this.convertToUnixTimestamp(newValue);
    this.onChange(timestamp);
  }

  convertToUnixTimestamp(datetimeStr: string): number {
    try {
      const date = new Date(datetimeStr);
      const timestamp = Math.floor(date.getTime() / 1000);
      // 如果轉換結果為 NaN，表示轉換失敗
      if (isNaN(timestamp)) {
        return 0;
      }
      return timestamp;
    } catch (error) {
      console.error('convertToUnixTimestamp 轉換失敗', error);
      return 0;
    }
  }

}
