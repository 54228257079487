<div class="component-container">
  <h2>矯正措施單</h2>
  <div *ngIf="formfinished">Form submission completed!</div>
  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">

      <div class="form-section">
      <label>Link to other form</label>
      <!-- <app-form-linker></app-form-linker> -->
      <app-form-linker [categories]="['Actions']"></app-form-linker>
      <!-- <app-form-linker [preselectedFormId]="'form1'"></app-form-linker> -->
      <!-- <app-form-linker [categories]="['Improvement']" [preselectedFormId]="'form2'"></app-form-linker> -->
      </div>

      <div class="form-section">
        <label for="nc-id">NC ID</label>
        <input
          type="text"
          id="nc-id"
          [(ngModel)]="formData.ncID"
          name="nc-id"
          placeholder="Enter NC ID"
        />
      </div>

      <div class="form-section">
        <label for="criticality">Criticality</label>
        <select
          id="criticality"
          name="criticality"
          [(ngModel)]="formData.criticality"
          class="form-input"
        >
          <option value="" disabled selected>Select Criticality</option>
          <option *ngFor="let crit of criticalityOptions" [value]="crit">{{ crit }}</option>
        </select>
      </div>

      <div class="form-section">
        <label for="date">Date</label>
        <input
          type="date"
          id="date"
          [(ngModel)]="formData.date"
          name="date"
        />
      </div>

      <div class="form-section">
        <label for="due-date">Due Date</label>
        <input
          type="date"
          id="due-date"
          [(ngModel)]="formData.dueDate"
          name="due-date"
        />
      </div>

      <div class="form-section">
        <label for="immediate-action">Immediate Action</label>
        <textarea
          id="immediate-action"
          [(ngModel)]="formData.immediateAction"
          name="immediate-action"
          placeholder="Enter Immediate Action"
        ></textarea>
      </div>

      <div class="form-section">
        <label for="corrective-action">Corrective Action</label>
        <textarea
          id="corrective-action"
          [(ngModel)]="formData.correctiveAction"
          name="corrective-action"
          placeholder="Enter Corrective Action"
        ></textarea>
      </div>

      <div class="form-section">
        <label for="review-approval-date">Review/Approval Date</label>
        <input
          type="date"
          id="review-approval-date"
          [(ngModel)]="formData.reviewApprovalDate"
          name="review-approval-date"
        />
      </div>

      <div class="form-section">
        <label for="root-cause">Root Cause</label>
        <select
          id="root-cause"
          name="root-cause"
          [(ngModel)]="formData.rootCause"
          class="form-input"
        >
          <option value="" disabled selected>Select Root Cause</option>
          <option *ngFor="let cause of rootCauseOptions" [value]="cause">{{ cause }}</option>
        </select>
      </div>

      <div class="form-section">
        <label for="review-approval-person">Review/Approval Person</label>
        <input
          type="text"
          id="review-approval-person"
          [(ngModel)]="formData.reviewApprovalPerson"
          name="review-approval-person"
          placeholder="Enter Review/Approval Person"
        />
      </div>

      <!-- File Upload -->
      <div class="form-section">
        <label for="file-upload">Upload File</label>
        <input
          type="file"
          id="file-upload"
          (change)="onFileSelected($event)"
          name="file-upload"
          accept="image/*"
        />
        <p *ngIf="selectedFile">Selected File: {{ selectedFile.name }}</p>
      </div>

      <!-- Signature -->
      <div class="form-section">
        <label for="signature">Signature</label>
        <canvas id="signatureCanvas" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature()">Clear Signature</button>
      </div>

      <!-- <div class="form-section">
        <label>施工單位人員簽名</label>
        <canvas id="signatureCanvas" class="signature-canvas"></canvas>
        <button type="button" class="clear-signature" (click)="clearSignature()">清除簽名</button>
      </div> -->

      <!-- Approval Process -->
      <div class="form-section">
        <h4>Approval Process</h4>
        <div *ngFor="let step of approvalProcess.steps; let i = index">
          <div class="step">
            <div>
              <strong>{{ step.stepName }}</strong>
              <!-- <span [ngClass]="getStatusClass(step.status)">{{ step.status }}</span> -->
            </div>

            <!-- Parallel Approval: List all approvers -->
            <div class="approvers">
              <div *ngFor="let approver of step.approvers" class="approver">
                <div>Approver: {{ approver.approver }}</div>
                <div *ngIf="approver.timestamp">Time: {{ approver.timestamp }}</div>
                <div *ngIf="approver.comment">Comment: {{ approver.comment }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
