import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';  // 引入 CommonModule
import { RouterModule } from '@angular/router';  // 引入 RouterModule
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout-full',
  standalone: true,  // 標明這是一個 standalone 組件
  templateUrl: './layout-full.component.html',
  styleUrl: './layout-full.component.scss',
  imports: [CommonModule,RouterModule]  // 只需導入模組，不需要導入服務
})
export class LayoutFullComponent {

    constructor(
    ) {}


  ngOnInit(): void {


  }

}
