export const environment = {
  production: true,
  title: 'Tpic',
  favicon: 'assets/images/tpic/icons/favicon.ico',
  apiUrl: 'https://tpic-examcenter-api.azurewebsites.net', //http://localhost:8080  https://tpic-examcenter-api.azurewebsites.net
  companyname: 'Tpic',
  name: 'Tpic',
  copyright: 'Tpic',
  logoUrl: 'assets/images/Tpic/tpic_logo.png',
  Login_title:'',
  Login_logoUrl: 'assets/images/Tpic/tpic_login_logo.png',
  test_account: 'tpic',
  assistantid: 'asst_Eoq4YShI4IJex9m6Ni8XUkM6',
  vector_store_id: 'vs_7DWBUWYQuzCAL5jFMoQc9f13',
  blobUrl:'',
  blobsasToken:'',
  version: '1.1.0',
};
