import { AuthenticationStrategy } from './authentication-strategy.interface';
import { TpicAuthenticationStrategy } from './tpic-authentication.strategy';
import { MockAuthenticationStrategy } from './mock-authentication.strategy';
import { DBAuthenticationStrategy } from './db_authentication.strategy';
import { DatabaseService } from '../database.service';

export class AuthenticationStrategyFactory {
  static getStrategy(clientId: string, databaseService: DatabaseService): AuthenticationStrategy {
    switch (clientId) {
      case 'Tpic':
        return new TpicAuthenticationStrategy();
      default:
        return new DBAuthenticationStrategy(databaseService);
    }
  }
}
